import { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { HStack, Image as ChakraImage, Text, BoxProps, TextProps } from '@chakra-ui/react';

type WarningMessageProps = PropsWithChildren<
  BoxProps & {
    textProps?: TextProps;
    darkVariant?: boolean;
  }
>;

export const WarningMessage: FunctionComponent<WarningMessageProps> = ({
  darkVariant,
  children,
  textProps,
  ...props
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const handleResize = () => {
        setTextHeight(textRef.current!.clientHeight);
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(textRef.current);

      handleResize();

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [children, textProps]);

  return (
    <HStack alignItems={textHeight >= 24 ? 'flex-start' : 'center'} spacing={1} {...props}>
      <ChakraImage
        width="20px"
        height="20px"
        mt={textHeight >= 24 ? '-2px' : 0}
        alt={children.toString()}
        src={`/icons/warning-diamond${darkVariant ? '-dark' : ''}-icon.svg`}
      />
      <Text
        fontSize="xs"
        color={darkVariant ? 'yellow.200' : 'yellow.800'}
        fontWeight="medium"
        lineHeight="120%"
        ref={textRef}
        {...textProps}
      >
        {children}
      </Text>
    </HStack>
  );
};
