import { SU_ACCOUNT_NAME } from 'composable/helpers/constants';
import { IMPERSONATOR_USER_HEADER, SEPARATOR_ACCOUNT_NAME } from '../constants';

export const getImpersonateUserIdAndUserName = (combinedString: string) => {
  const [impersonatedUserId = '', userName = ''] = combinedString.split(SEPARATOR_ACCOUNT_NAME);
  return {
    impersonatedUserId,
    userName,
  };
};

export const impersonatorIdFromStorage = () => {
  const combinedString = JSON.parse(localStorage.getItem(SU_ACCOUNT_NAME)) || '';
  const { impersonatedUserId } = getImpersonateUserIdAndUserName(combinedString);
  return impersonatedUserId;
};

export const getHeadersForImpersonation = () => {
  const combinedUserNameString = JSON.parse(localStorage.getItem(SU_ACCOUNT_NAME)) || '';
  const { impersonatedUserId } = getImpersonateUserIdAndUserName(combinedUserNameString);
  return impersonatedUserId ? { [IMPERSONATOR_USER_HEADER]: impersonatedUserId } : {};
};
