import { useEffect, useState } from 'react';
import { Cart } from '@Types/cart/Cart';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGlobal } from 'components/globalProvider';
import { getPlanByStoreKey } from 'composable/helpers/hooks/useGetDeliveryDates';
import { useProductExtraData } from 'helpers/hooks/useProductExtraData';
import { filterProductDeliveryDays } from '.';

const checkProductData = (extraProductData: ExtraProductData[], selectedBusinessUnit) => {
  if (extraProductData.length <= 1) {
    return false; // Exit early if there are not enough line items
  }

  for (const product of extraProductData) {
    const deliveryCutoffs = product?.companyInfo?.data?.cutoffs?.daysOfWeek;
    const plans = getPlanByStoreKey(selectedBusinessUnit);

    const filteredDeliveryCutoffs =
      plans?.length && deliveryCutoffs?.length ? filterProductDeliveryDays(plans, deliveryCutoffs) : [];

    const isSplit = filteredDeliveryCutoffs.length > 0;

    if (isSplit) {
      return true; // Exit the function if isSplit is true
    }
  }
  return false; // Default return if no split is found
};

export const useGetSplitOrder = (lineItems: Cart['lineItems']) => {
  const cartLineItems = lineItems ? lineItems : [];
  const { extraProductData } = useProductExtraData([...cartLineItems?.map((item) => item?.variant?.sku)]);
  const { activeAccount } = useGlobal().useUserGlobal.state;
  const [isSplitOrder, setIsSplitOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsSplitOrder(false); // Reset isSplitOrder
    setIsLoading(true); // Set isLoading to true when starting the data fetch

    if (extraProductData.length > 0 && activeAccount) {
      const isSplitOrder = checkProductData(extraProductData, activeAccount);

      setIsSplitOrder(isSplitOrder);
      setIsLoading(false);
    }
  }, [extraProductData, activeAccount, cartLineItems]);

  return { isSplitOrder, isLoading };
};
