import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGlobal } from 'components/globalProvider';
import routes from 'helpers/constants/routes';
import { CAN_SUBMIT_ORDERS } from 'helpers/constants/userPermissions';

export const useProtectSuccessPage = () => {
  const { shamrockUser, loading: isLoadingUserData } = useGlobal().useUserGlobal.state;
  const permissions = shamrockUser?.user?.permissions?.data ?? [];
  const router = useRouter();
  const canSubmitOrders = permissions.includes(CAN_SUBMIT_ORDERS);

  useEffect(() => {
    if (!canSubmitOrders && !isLoadingUserData) {
      router.push(routes.CHECKOUT);
    }
  }, [canSubmitOrders, isLoadingUserData]);
};
