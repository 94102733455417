import { FunctionComponent, PropsWithChildren } from 'react';
import { BoxProps, HStack, Icon, IconProps, Text, TextProps } from '@chakra-ui/react';
import { Warning } from 'phosphor-react';

export const ErrorMessage: FunctionComponent<
  PropsWithChildren<BoxProps & { iconProps?: IconProps; textProps?: TextProps; darkVariant?: boolean }>
> = ({ children, iconProps, textProps, darkVariant, ...props }) => {
  return (
    <HStack align="flex-start" spacing={1} {...props}>
      <Icon
        as={Warning}
        boxSize={5}
        color={darkVariant ? 'red.400' : 'red.700'}
        alignItems="flex-start"
        position="relative"
        {...iconProps}
      />
      <Text
        className="product-card-error-message"
        fontSize="xs"
        color={darkVariant ? 'red.200' : 'red.800'}
        fontWeight="medium"
        lineHeight="120%"
        alignItems="flex-end"
        {...textProps}
      >
        {children}
      </Text>
    </HStack>
  );
};
