import { useToast } from '@chakra-ui/react';
import { GENERIC_TOAST_ERROR_ID, UPDATE_LINE_ITEM_ERROR_ID } from 'composable/components/general/constants';
import { APP_FAILURE_TOAST_ID } from 'composable/components/order-details/order-details-page';
import { useEffect } from 'react';
import delay from 'lodash/delay';

const TOAST_IDS_TO_CHECK = [GENERIC_TOAST_ERROR_ID, UPDATE_LINE_ITEM_ERROR_ID, APP_FAILURE_TOAST_ID];
const SIX_SECONDS = 6000;

export const useCloseToast = () => {
  const toast = useToast();
  const isSomeIdActive = TOAST_IDS_TO_CHECK.some((id) => toast.isActive(id));
  useEffect(() => {
    if (isSomeIdActive) {
      TOAST_IDS_TO_CHECK.forEach((id) => {
        toast.isActive(id) && delay(() => toast.close(id), SIX_SECONDS);
      });
    }
  }, [isSomeIdActive]);
};
