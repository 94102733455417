import { useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Icon,
  Text,
  VStack,
  Image as ChakraImage,
  Flex,
  useMediaQuery,
  Button,
} from '@chakra-ui/react';
import { LineItem } from '@Types/cart/LineItem';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import * as ProductCardV2 from 'composable/components/product-card-v2';
import { getProductPrice } from 'composable/components/product-card-v2/helpers';
import { OrderSummaryVariant } from 'composable/components/product-card-v2/order-summary-variant';
import { WarningMessage } from 'composable/components/product-card-v2/parts';
import { ProductAttributeKeys } from 'composable/components/types';
import { getAttribute } from 'composable/helpers/utils/attribute-utils';
import { getPluralizedText } from 'composable/helpers/utils/get-pluralized-text';
import { isDeliveredComplete } from 'composable/helpers/utils/order-status';
import {
  isCustomerDirectDeliveryType,
  isInvoiceReissueDeliveryType,
  isNonRefundable,
} from 'composable/helpers/utils/order-utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocaleCode } from 'helpers/hooks/useLocaleCode';
import { useProductExtraData } from 'helpers/hooks/useProductExtraData';
import { Minus, Plus } from 'phosphor-react';
import calculateCasePriceFromUnitPrice from '../utils/calculateCasePriceFromUnitPrice';

interface OrderSummaryProps {
  isAlwaysOpen?: boolean;
  orderTotal?: number;
  items?: number;
  cases?: number;
  lineItems?: LineItem[];
  isOrderPage?: boolean;
  orderLineItems?: OrderDetail['lineItems'];
  orderStatus?: OrderDetail['orderStatus'];
  isLoading?: boolean;
  products?: any[];
  variants?: Map<any, any>;
  hasIssue?: boolean;
  alertFlaggedForCasesOrdered?: boolean;
  isEditCheckout?: boolean;
  openOnLoad?: boolean;
  isSplitOrder?: boolean;
}

export const formatOrderTotal = (total: number) => {
  const formattedNumber = (total / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return '$' + formattedNumber;
};

export const OrderSummary = ({
  isAlwaysOpen = false,
  openOnLoad = false,
  orderTotal,
  items,
  cases,
  lineItems,
  isOrderPage = false,
  orderLineItems,
  orderStatus,
  isLoading,
  products,
  variants,
  hasIssue,
  alertFlaggedForCasesOrdered,
  isEditCheckout,
  isSplitOrder,
}: OrderSummaryProps) => {
  const locale = useLocaleCode();
  const { formatMessage } = useFormat({ name: 'common' });

  const itemsTotal = useMemo(() => getPluralizedText(items, 'items', formatMessage), [items]);
  const casesTotal = useMemo(() => getPluralizedText(cases, 'cases', formatMessage), [cases]);

  const cartLineItems = useMemo(() => {
    if (!lineItems && !products) {
      return [];
    }
    return lineItems.length > 0 ? lineItems : products ?? [];
  }, [lineItems, products]);

  const skus = useMemo(() => {
    return !!cartLineItems
      ? cartLineItems.map((item) => (lineItems.length > 0 ? item?.variant?.sku || item?.sku : item?.productId))
      : [];
  }, [cartLineItems, lineItems.length]);

  const { extraProductData } = useProductExtraData(skus);

  const [isLargerThan422] = useMediaQuery('(min-width: 422px)', {
    ssr: true,
    fallback: false,
  });

  // EDIT CHECKOUT ITEMS
  let oldOrderItems = [];
  let newOrderItems = [];
  let oldItemText = '';
  let newItemText = '';
  let hasItemsSeparator = false;

  if (isEditCheckout) {
    // Old Items
    const oldOrderItemsFilter = orderLineItems?.filter((lineItem) => lineItem?.lineNumber !== undefined);
    oldOrderItems = oldOrderItemsFilter?.sort(
      (orderItemA, orderItemB) => orderItemA?.lineNumber - orderItemB?.lineNumber,
    );
    oldItemText =
      oldOrderItems?.length === 1 ? formatMessage({ id: 'editOrder.item' }) : formatMessage({ id: 'editOrder.items' });

    // New Items
    newOrderItems = orderLineItems?.filter((lineItem) => lineItem?.lineNumber === undefined);
    newItemText =
      newOrderItems?.length === 1 ? formatMessage({ id: 'editOrder.item' }) : formatMessage({ id: 'editOrder.items' });
    hasItemsSeparator = oldOrderItems?.length > 0 && newOrderItems.length > 0;
  }

  const cbRenderProductCards = useCallback(() => {
    return renderProductCards({
      isEditCheckout,
      isLoading,
      orderLineItems,
      extraProductData,
      variants,
      products,
      locale,
      orderStatus,
    });
  }, [isEditCheckout, isLoading, orderLineItems, extraProductData, variants, products, locale, orderStatus]);

  return (
    <Accordion allowToggle defaultIndex={isAlwaysOpen || openOnLoad ? [0] : undefined}>
      <AccordionItem bg="white" border={0} borderRadius="lg">
        {({ isExpanded }) => (
          <>
            <VStack
              align="unset"
              bg="neutral.900"
              border="1px"
              borderRadius="lg"
              spacing={0}
              {...(isExpanded
                ? { borderColor: 'neutral.700', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.20)' }
                : { borderColor: 'neutral.700' })}
            >
              <HStack
                align="flex-end"
                bg="neutral.800"
                borderTopRadius="lg"
                borderBottomWidth={1}
                borderBottomColor="neutral.700"
                justify="space-between"
                p={{ base: 3, xl: 4 }}
              >
                <Text color="white" fontSize={{ base: 'sm', xl: 'lg' }} fontWeight="bold" lineHeight="120%">
                  {formatMessage({
                    id: 'checkout.success.order',
                    values: { orderNumber: isSplitOrder ? '1' : '' },
                  })}
                </Text>
                <AccordionButton
                  p="unset"
                  w="unset"
                  as={Button}
                  size="ds-sm"
                  fontSize="xs"
                  alignSelf="flex-end"
                  variant="ds-icon-text-link-dark"
                  _hover={{ bg: 'transparent' }}
                  aria-label={formatMessage({ id: isExpanded ? 'action.hide.items' : 'action.show.items' })}
                >
                  {!isAlwaysOpen && (
                    <>
                      {formatMessage({ id: isExpanded ? 'action.hide.items' : 'action.show.items' })}
                      <Icon
                        as={isExpanded ? Minus : Plus}
                        boxSize={3}
                        sx={{ svg: { width: '0.75rem', height: '0.75rem' } }}
                      />
                    </>
                  )}
                </AccordionButton>
              </HStack>
              <VStack align="unset" p={{ base: 6, xl: 8 }} spacing={4}>
                <HStack spacing={2}>
                  {[itemsTotal, '|', casesTotal].map((item) => (
                    <Text color="white" fontSize={{ base: 'xs', xl: 'sm' }} key={item} lineHeight="150%">
                      {item}
                    </Text>
                  ))}
                </HStack>
                <Divider borderColor="neutral.700" />
                <HStack align="center" justify="space-between">
                  <Text color="white" fontSize={{ base: 'xs', xl: 'base' }} fontWeight="bold" lineHeight="120%">
                    {formatMessage({
                      id:
                        isCustomerDirectDeliveryType(orderStatus) || isInvoiceReissueDeliveryType(orderStatus)
                          ? 'cart.orderSummary.orderTotal'
                          : 'cart.summary.estimatedTotal',
                    })}
                  </Text>
                  <Text color="white" fontSize={{ base: 'sm', xl: 'base' }} fontWeight="bold" lineHeight="120%">
                    {orderTotal ? formatOrderTotal(orderTotal) : '$0.00'}
                  </Text>
                </HStack>
                {isOrderPage &&
                  !isCustomerDirectDeliveryType(orderStatus) &&
                  !isInvoiceReissueDeliveryType(orderStatus) && (
                    <Text
                      color="neutral.200"
                      fontWeight={'normal'}
                      fontSize={{ base: 'xs', lg: 'sm' }}
                      lineHeight="tight"
                    >
                      {formatMessage({ id: 'editOrder.estimatedTotal.disclaimer' })}
                    </Text>
                  )}
              </VStack>
            </VStack>

            <AccordionPanel border={0} p={4}>
              {/* Mapping for Checkout Page */}
              <VStack align="unset" spacing={3}>
                {!isOrderPage && !isEditCheckout ? (
                  // Default checkout pages
                  cartLineItems.map((item, idx) => {
                    const brand = getAttribute(
                      item?.variant?.attributes['shamrock-standard_brand_long_description'],
                      locale,
                    );
                    // TODO: Remove fall back values
                    const { currentPrice, discountedPrice, productPrice } = getProductPrice({
                      attributes: item?.variant?.attributes,
                      extraProductData: extraProductData?.find(
                        (product) => product?.productNumber === item.variant.sku,
                      ),
                    });
                    return (
                      <ProductCardV2.CheckoutVariant
                        index={idx}
                        key={item.lineItemId || idx}
                        brand={brand}
                        id={item?.variant ? item.variant.sku : '12123'}
                        name={item?.name ? item.name : '[Orm] Nutrition Table Sample'}
                        packSize={
                          item?.variant?.attributes
                            ? item?.variant?.attributes['shamrock-standard_pack_size']
                            : '24/16.9/OZ'
                        }
                        sku={item?.variant ? item.variant.sku : '12123'}
                        quantityFromOrder={item ? item.count : 0}
                        currentPrice={currentPrice}
                        discountedPrice={discountedPrice}
                        productPrice={productPrice}
                        extraProductData={extraProductData?.find(
                          (product) => product?.productNumber === item.variant.sku,
                        )}
                      />
                    );
                  })
                ) : (
                  <VStack align="unset" spacing={4}>
                    {/* Mapping for Order Details Page */}

                    {/* hasIssue text */}
                    {hasIssue && (
                      <Box>
                        <WarningMessage>{formatMessage({ id: 'productCard.issueDetected' })}</WarningMessage>
                      </Box>
                    )}

                    {/* Inventory not guaranteed text */}

                    {orderStatus &&
                      !isCustomerDirectDeliveryType(orderStatus) &&
                      !isDeliveredComplete(orderStatus) &&
                      alertFlaggedForCasesOrdered && (
                        <Flex alignItems={isLargerThan422 ? 'center' : 'flex-start'}>
                          <ChakraImage mr={1} width="20px" height="20px" src="/icons/info-icon-2.svg" />
                          <Text fontSize="sm" color="violet.800" lineHeight="16.8px">
                            {formatMessage({ id: 'productCard.inventoryNotGuaranteed' })}
                          </Text>
                        </Flex>
                      )}

                    {
                      /* ORDER LISTING VARIANT */
                      cbRenderProductCards()
                    }

                    {/* EDIT CHECKOUT NEW ITEMS */}
                    {isEditCheckout && !isLoading && hasItemsSeparator && newOrderItems.length && (
                      <Box pt={4}>
                        <Text color="black" fontSize={{ base: 'md', lg: 'lg' }} fontWeight="bold" lineHeight="120%">
                          {formatMessage({ id: 'editOrder.new' })} {newItemText} ({newOrderItems.length}{' '}
                          {newItemText.toLocaleLowerCase()})
                        </Text>
                      </Box>
                    )}

                    {isEditCheckout &&
                      !isLoading &&
                      newOrderItems &&
                      newOrderItems.map((lineItem: OrderDetailLineItem, idx: number) => {
                        // Edit order new items
                        const variant = variants?.get(lineItem.productNumber);

                        const brand = getAttribute(
                          variant?.attributes[ProductAttributeKeys.BrandLongDescription],
                          locale,
                        );
                        const packSize = getAttribute(variant?.attributes[ProductAttributeKeys.PackSize], locale);

                        return (
                          <ProductCardV2.CheckoutVariant
                            key={lineItem?.productNumber}
                            brand={brand}
                            id={lineItem?.productNumber}
                            name={
                              products.find((product) => product?.productId === lineItem?.productNumber)?.name || ''
                            }
                            packSize={packSize}
                            sku={lineItem?.productNumber}
                            quantityFromOrder={lineItem?.orderedQuantity}
                            productPrice={lineItem?.originalPrice}
                            extraProductData={extraProductData.find(
                              (extraProduct) => extraProduct.productNumber === lineItem.productNumber,
                            )}
                            index={idx}
                            currentPrice={lineItem.originalPrice}
                            discountedPrice={lineItem.discountedPrice}
                          />
                        );
                      })}

                    {/* EDIT CHECKOUT PREVIOUS ITEMS */}
                    {isEditCheckout && !isLoading && hasItemsSeparator && oldOrderItems.length && (
                      <Box pt={3}>
                        <Text color="black" fontSize={{ base: 'md', lg: 'lg' }} fontWeight="bold" lineHeight="120%">
                          {formatMessage({ id: 'editOrder.previous' })} {oldItemText} ({oldOrderItems.length}{' '}
                          {oldItemText.toLocaleLowerCase()})
                        </Text>
                      </Box>
                    )}

                    {isEditCheckout &&
                      !isLoading &&
                      oldOrderItems &&
                      oldOrderItems.map((lineItem, idx) => {
                        // Edit order previous items
                        const variant = variants?.get(lineItem.productNumber);

                        const brand = getAttribute(
                          variant?.attributes[ProductAttributeKeys.BrandLongDescription],
                          locale,
                        );
                        const packSize = getAttribute(variant?.attributes[ProductAttributeKeys.PackSize], locale);

                        return (
                          <ProductCardV2.CheckoutVariant
                            key={lineItem?.productNumber}
                            brand={brand}
                            index={idx}
                            id={lineItem?.productNumber}
                            name={
                              products.find((product) => product?.productId === lineItem?.productNumber)?.name || ''
                            }
                            packSize={packSize}
                            sku={lineItem?.productNumber}
                            quantityFromOrder={lineItem?.orderedQuantity}
                            productPrice={lineItem?.originalPrice}
                            extraProductData={extraProductData.find(
                              (extraProduct) => extraProduct.productNumber === lineItem.productNumber,
                            )}
                            {...calculateCasePriceFromUnitPrice(lineItem, variant?.attributes)}
                          />
                        );
                      })}
                  </VStack>
                )}
                {!isAlwaysOpen && !isOrderPage && (
                  <AccordionButton
                    p="unset"
                    w="unset"
                    as={Button}
                    size="ds-sm"
                    fontSize="xs"
                    alignSelf="flex-end"
                    variant="ds-icon-text-link-light"
                    _hover={{ bg: 'transparent' }}
                    aria-label={
                      formatMessage({ id: isExpanded ? 'action.hide.items' : 'action.show.items' }) +
                      formatMessage({ id: 'label.bottom' })
                    }
                  >
                    <>
                      {formatMessage({ id: 'action.hide.items' })}
                      <Icon as={Minus} boxSize={3} sx={{ svg: { width: '0.75rem', height: '0.75rem' } }} />
                    </>
                  </AccordionButton>
                )}
              </VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

type renderProductCardsProps = {
  isEditCheckout: boolean;
  isLoading: boolean;
  orderLineItems: OrderDetailLineItem[];
  extraProductData: ExtraProductData[];
  variants: Map<any, any>;
  products: any[];
  locale: any;
  orderStatus: string;
};

function renderProductCards({
  isEditCheckout,
  isLoading,
  orderLineItems,
  extraProductData,
  variants,
  products,
  locale,
  orderStatus,
}: renderProductCardsProps) {
  if (isEditCheckout || isLoading) {
    return null;
  }
  return orderLineItems.map((lineItem) => {
    if (!lineItem) {
      return null;
    }

    const variant = variants?.get(lineItem.productNumber);
    const cardData = {
      brand: getAttribute(
        variants?.get(lineItem.productNumber)?.attributes[ProductAttributeKeys.BrandLongDescription],
        locale,
      ),
      name: products.find((product) => product.productId === lineItem.productNumber)?.name || '',
      id: lineItem.productNumber,
      packSize: getAttribute(variant?.attributes[ProductAttributeKeys.PackSize], locale),
      setSplitOrder: () => {},
      splitOrder: false,
      extraProductData: extraProductData.find((extraProduct) => extraProduct.productNumber === lineItem.productNumber),
      nonRefundable: isNonRefundable(lineItem?.justInTimeEditCutoff),
      perishable:
        variant?.attributes[ProductAttributeKeys.RefrigeratedIndicator] ||
        variant?.attributes[ProductAttributeKeys.FrozenIndicator],
      alertFlaggedForCasesOrdered: lineItem.allocatedQuantity !== lineItem.orderedQuantity,
      dueDate: lineItem.nextDeliveryDueDates?.data?.[0]?.dateTime
        ? new Date(lineItem.nextDeliveryDueDates?.data?.[0]?.dateTime)
        : undefined,
      casesOrdered: lineItem.orderedQuantity,
      allocated: lineItem.allocatedQuantity,
      invoiced: lineItem.invoicedQuantity,
      received: lineItem.receivedQuantity,
      quantity: lineItem.nextDeliveryDueDates?.data?.[0]?.quantity,
      hasCreditIssued: lineItem.hasCreditIssued,
      orderStatus: orderStatus,
      isEditCheckout: isEditCheckout,
      ...calculateCasePriceFromUnitPrice(lineItem, variant?.attributes),
    };
    return <OrderSummaryVariant key={lineItem.productNumber} {...cardData} />;
  });
}
