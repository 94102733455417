import { Address } from '@Types/account/Address';
import { getTimeZone } from 'composable/components/plp/helpers';
import { RemainingDays } from 'composable/helpers/hooks/useGetDeliveryDates';
import { DeliveryWindow, FormattedDeliveryOption } from 'helpers/services/shamrock';
import { SEPARATOR_TIME_WINDOWS } from './fetchers';
import { addDays } from 'date-fns/addDays';
import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { differenceInHours } from 'date-fns/differenceInHours';

const HARDCODED_WAREHOUSE_ADDRESS: Partial<Address> = {
  firstName: 'ABC Warehouse',
  lastName: 'Fname Lname',
  streetName: '1234 Fake Street',
  city: 'Chicago',
  state: 'IL',
  postalCode: '60606',
  phone: '800-444-5566',
  country: 'US',
};

export const mapShamrockResponseToDeliveryOptions = ({
  availableDates,
  numberCases,
}: {
  availableDates: DeliveryWindow.DeliveryOption[];
  numberCases: number;
}): FormattedDeliveryOption[] => {
  return availableDates.map(({ deliveryDate, deliveryWindows, deliveryMinimums, deliveryType }) => {
    return {
      deliveryDate,
      deliveryDateWithTime: `${deliveryDate}T00:00:00`,
      deliveryWindows: deliveryWindows.map((timeWindow) => {
        const formattedStartTimeLocal = convertUTCDateTimeToLocal(
          `${deliveryDate}T${timeWindow.deliveryWindowStartTime}`,
        );
        const formattedEndTimeLocal = convertUTCDateTimeToLocal(`${deliveryDate}T${timeWindow.deliveryWindowEndTime}`);
        const { pickupAddress, isAvailable, maxCasesPerOrder } = timeWindow;
        const isUnavailableByCases = numberCases > maxCasesPerOrder && maxCasesPerOrder !== null;
        return {
          isUnavailableByCases,
          maxCasesPerOrder,
          isAvailable: isAvailable && !isUnavailableByCases,
          deliveryWindowStartTime: timeWindow.deliveryWindowStartTime,
          deliveryWindowEndTime: timeWindow.deliveryWindowEndTime,
          formattedStartTimeLocal,
          formattedEndTimeLocal,
          radioKey: `${timeWindow.deliveryWindowStartTime}${SEPARATOR_TIME_WINDOWS}${timeWindow.deliveryWindowEndTime}${SEPARATOR_TIME_WINDOWS}${deliveryDate}`,
          geocode: timeWindow.geocode,
          warehouseAddress: pickupAddress
            ? {
                firstName: pickupAddress.name,
                streetName: pickupAddress.address1,
                city: pickupAddress.city,
                postalCode: pickupAddress.postalCode,
                phone: pickupAddress.primaryPhoneNumber,
                state: pickupAddress.state,
              }
            : HARDCODED_WAREHOUSE_ADDRESS,
          remainingDays: timeWindow.cutOffTime
            ? calculateRemainingTime(deliveryDate, timeWindow.cutOffTime, timeWindow.leadDays)
            : null,
        };
      }),
      deliveryType,
      deliveryMinimums,
    };
  });
};

export const formatRadioDeliveryWindowKey = (deliveryDate: Date, radioKey: string): string => {
  const onlyDay = deliveryDate.toISOString().split('T')[0];
  const [startTime, endTime] = radioKey.split(SEPARATOR_TIME_WINDOWS);
  const formattedStartTimeLocal = convertUTCDateTimeToLocal(`${onlyDay}T${startTime}`);
  const formattedEndTimeLocal = convertUTCDateTimeToLocal(`${onlyDay}T${endTime}`);
  return `${formattedStartTimeLocal} - ${formattedEndTimeLocal} ${getTimeZone()}`;
};

//An example of the time should be '2024-03-05T08:00:00'
export const convertUTCDateTimeToLocal = (utcDateTimeStr) => {
  // Get the user's local time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a Date object from the UTC string
  const utcDate = new Date(utcDateTimeStr + 'Z');

  // Convert to the user's local time zone
  const localTime = utcDate.toLocaleTimeString('en-US', {
    timeZone: timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return localTime;
};

export const calculateRemainingTime = (date: string, cutOffTime: string, leadDays: number): RemainingDays => {
  const dateTime = new Date(`${date}T${cutOffTime}Z`);

  // Adjust the date based on leadDays. If leadDays is 0, subtract 1 day.
  // According to the Shamrock team, the valid values for leadDays are currently 0 and 1.
  // However, we account for potential future changes where leadDays could be greater than 1.
  const adjustedDate = leadDays === 0 ? addDays(dateTime, -1) : dateTime;

  const now = new Date();

  const days = differenceInDays(adjustedDate, now);
  const hours = differenceInHours(adjustedDate, now);
  const minutes = differenceInMinutes(adjustedDate, now);

  return {
    days: Math.floor(days),
    hours: Math.floor(hours % 24),
    minutes: Math.floor(minutes % 60),
    orderByDate: adjustedDate,
  };
};
