import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { getHeadersForImpersonation } from 'composable/components/super-user/helpers/utils';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';

export namespace CreditRequest {
  export interface Props {
    accessToken: string;
    customerNumber: string;
    orderNumber: string;
    warehouseNumber: string;
    businessUnitName: string;
    businessSegmentName: string;
    payload: CreditRequest.Payload;
    toast: ReturnType<typeof useToast>;
    toastMessage: string;
  }

  export interface Payload {
    productNumber: string;
    quantity: number;
    creditReason: string;
    issueWithProductReason: string;
    comments: string;
    attachments: CreditRequest.Attachment[];
  }

  export interface Attachment {
    fileTextBase64: string;
    extension: string;
    fileName: string;
  }
  export interface Response {
    success: boolean;
    message?: string;
  }
}

export const postCreditRequest = async ({
  customerNumber,
  orderNumber,
  warehouseNumber,
  businessUnitName,
  businessSegmentName,
  accessToken,
  payload,
}: CreditRequest.Props) => {
  const impersonatedHeaders = getHeadersForImpersonation();
  return (
    await axios<CreditRequest.Response>({
      method: 'post',
      url: `${SHAMROCK_API_URL}/cases/credit-request`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        ...impersonatedHeaders,
      },
      params: {
        'customer-number': customerNumber,
        'order-number': orderNumber,
        'warehouse-number': warehouseNumber,
        'business-unit-name': businessUnitName,
        'business-segment-name': businessSegmentName,
      },
      data: payload,
    })
  ).data.success;
};
