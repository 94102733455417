import { useGlobal } from 'components/globalProvider';
import { useCartHelpers } from 'helpers/hooks/useCartHelpers';
import { UpdateCartType } from 'helpers/types/cart-types';
import { ActionCart, generateIdForQueue } from 'hooks/global/use_privateCartGlobal/utils';

type UseLineItemUpdaterProps = {
  sku: string;
};

type UpdateLineItemQuantityProps = {
  price: number;
  productName: string;
  quantity: number;
};

export const useOrderLineItemUpdater = ({ sku }: UseLineItemUpdaterProps) => {
  const { updateEditedOrder, isEditingOrder, editCart } = useGlobal().useEditOrderGlobal;
  // CART HANDLERS
  // -------------
  const { cart, removeActionFromQueue, shouldAddToQueue } = useGlobal().useCartGlobal;

  const { getCartQuantityForItem, addOrUpdateLineItemToCart } = useCartHelpers({});

  const getLineItemQuantityInCart = () => {
    return getCartQuantityForItem(cart, sku);
  };

  const updateLineItemQuantityInCart = async ({
    price,
    productName,
    quantity,
  }: Pick<UpdateLineItemQuantityProps, 'price' | 'productName' | 'quantity'>): Promise<UpdateCartType> => {
    if (quantity === 0 && shouldAddToQueue) {
      const wasRemoved = removeActionFromQueue(generateIdForQueue(ActionCart.ADD_ITEM, sku));
      if (wasRemoved) {
        return;
      }
    }
    const variant = {
      sku: sku,
      quantity,
      externalPrice: price,
    };

    const priceValues = {
      centAmount: price || 0,
      currencyCode: 'USD', // TODO => where will this come from?
    };

    // Check if item is already in cart > update qty if present or add it to cart if it does not exist
    return await addOrUpdateLineItemToCart(variant, quantity, priceValues, productName);
  };
  // -------------
  // CART HANDLERS

  // EDIT MODE HANDLERS
  // -------------
  const getLineItemQuantityInEditMode = () => {
    const quantity = editCart?.lineItems?.find((item) => item.productNumber === sku)?.orderedQuantity || 0;

    return quantity;
  };

  const updateLineItemQuantityInEditMode = ({
    price,
    quantity,
  }: Pick<UpdateLineItemQuantityProps, 'price' | 'quantity'>) => {
    const lineItemIndex = editCart?.lineItems?.findIndex((item) => item.productNumber === sku);

    const nextDraftOrder = {
      ...editCart,
    };

    if (lineItemIndex > -1) {
      if (quantity) {
        // UPDATE LINE ITEM
        nextDraftOrder.lineItems[lineItemIndex].orderedQuantity = quantity;
      } else {
        // REMOVE LINE ITEM
        nextDraftOrder.lineItems.splice(lineItemIndex, 1);
      }
    } else {
      // ADD NEW LINE ITEM
      nextDraftOrder.lineItems.push({
        productNumber: sku,
        originalPrice: price,
        orderedQuantity: quantity,
        discountedPrice: nextDraftOrder.lineItems[lineItemIndex]?.discountedPrice,
      });
    }

    updateEditedOrder(nextDraftOrder);
  };
  // -------------
  // EDIT MODE HANDLERS

  const getLineItemQuantity = () => (isEditingOrder ? getLineItemQuantityInEditMode() : getLineItemQuantityInCart());

  const updateLineItemQuantity = async ({ price, productName, quantity }: UpdateLineItemQuantityProps) =>
    isEditingOrder
      ? updateLineItemQuantityInEditMode({ price, quantity })
      : updateLineItemQuantityInCart({ price, productName, quantity });

  return { getLineItemQuantity, updateLineItemQuantity };
};
