import { Dispatch } from 'react';
import { OrderGuideMultipleCustomers } from 'composable/components/order-guide-management';

export type OrderGuideListState = {
  ogList: OrderGuideMultipleCustomers[];
  draftOgList: OrderGuideMultipleCustomers[];
  loading: boolean;
  hasUpdatedOgList: boolean;
};

export enum OrderGuideListGlobalStateActions {
  SET_LOADING = 'SET_LOADING',
  SET_OG_LIST = 'SET_OG_LIST',
  SET_DRAFT_OG_LIST = 'SET_DRAFT_OG_LIST',
  SET_EMPTY_OG_LIST = 'SET_EMPTY_OG_LIST',
}

export type OrderGuideListGlobalAction = {
  type: OrderGuideListGlobalStateActions;
  payload?: Partial<OrderGuideListState>;
};

export type UseOrderGuideListGlobalResponse = {
  state: OrderGuideListState;
  dispatch: Dispatch<OrderGuideListGlobalAction>;
  listOrderGuides: (includeDrafts?: Boolean) => Promise<void>;
  responseTime?: number;
};
