import { CustomModal } from 'composable/components/custom-modal';
import { useFormat } from 'helpers/hooks/useFormat';

interface UnsavedCartModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinueWithoutSaving?: () => void;
}

export const UnsavedCartModal = ({ isOpen, onClose, onContinueWithoutSaving }: UnsavedCartModalProps) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const handleCloseModal = () => {
    onClose();
  };

  const handleContinue = () => {
    onClose();
    onContinueWithoutSaving();
  };

  return (
    <CustomModal
      title={formatMessage({ id: 'offline.unsaved.cart.modal.title' })}
      subtitle={formatMessage({ id: 'offline.unsaved.cart.modal.body' })}
      textUnfilledButton={formatMessage({ id: 'confirm.no' })}
      textFilledButton={formatMessage({ id: 'confirm.yes' })}
      isOpen={isOpen}
      onClose={handleCloseModal}
      onClickUnfilledButton={handleCloseModal}
      onClickFilledButton={handleContinue}
      isLoading={false}
    />
  );
};
