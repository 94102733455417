import { CutoffsGlobalActions, CutoffsGlobalStateActions, CutoffsState } from './types';

export const cutoffsInitialState: CutoffsState = {
  softCutoffs: null,
  extendedCutoff: null,
  loading: true,
};

export function reduceCutoffs(state: CutoffsState, action: CutoffsGlobalActions): CutoffsState {
  switch (action.type) {
    case CutoffsGlobalStateActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case CutoffsGlobalStateActions.SET_SOFT_CUTOFFS:
      return {
        ...state,
        softCutoffs: action.payload.softCutoffs,
        loading: action.payload.loading || false,
      };
    case CutoffsGlobalStateActions.SET_EXTENDED_CUTOFFS:
      return {
        ...state,
        extendedCutoff: action.payload.extendedCutoff,
        loading: action.payload.loading || false,
      };
    default:
      return state;
  }
}
