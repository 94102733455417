import { FunctionComponent } from 'react';
import { Text, Image as ChakraImage, HStack } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';
import { useFormat } from 'helpers/hooks/useFormat';;

type PerishableItemProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
};

export const PerishableItem: FunctionComponent<PerishableItemProps> = ({ breakpointTokenToDesktop = 'md' }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <HStack spacing={{ base: 1, [breakpointTokenToDesktop]: 2 }}>
      <ChakraImage width="20px" height="20px" src="/icons/flag-banner-icon.svg" />
      <Text fontSize={{ base: 'xs', [breakpointTokenToDesktop]: 'sm' }} color="violet.800" lineHeight="120%">
        {formatMessage({
          id: 'productCard.perishableItem',
        })}
      </Text>
    </HStack>
  );
};
