import { Dispatch } from 'react';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { useGetDeliveryWindows } from 'helpers/hooks/useGetDeliveryWindows';
import {
  DeliveryOptionsEligibility,
  DeliveryWindow,
  FormattedDeliveryOption,
  SoftCutoffsResponse,
} from 'helpers/services/shamrock';
import { DeliveryOptionsEligibilityMap, EligibleOptionsByType } from 'helpers/utils/fetchers';
import { Tastic } from 'frontastic';
import { CartDetails } from 'frontastic/actions/cart';
import { MarketingMessage } from 'frontastic/tastics/composable/checkout';
import { CartGlobalAction } from '../use_privateCartGlobal/types';
import { use_privateEditOrderGlobal } from '../use_privateEditOrderGlobal';
import { use_privateUserGlobal } from '../use_privateUserGlobal';
import { UserState } from '../use_privateUserGlobal/types';

export type CheckoutState = {
  comments?: string;
  deliveryDate?: string;
  poNumber?: string;
  shippingMethod?: string;
  deliveryWindow?: string;
  warehouseAddress?: Partial<Address>;
  geocode?: string;
  secondaryDate?: string;
  specialCuisines?: Tastic;
};

type EligibilityType = {
  data: {
    currentUserEligibleOptions: {} | DeliveryOptionsEligibility[];
    eligibleOptionsByCustomers: {} | DeliveryOptionsEligibilityMap;
    deliveryOptionsEligibilities: {} | EligibleOptionsByType;
  } | null;
  isLoading: boolean;
};

export type DeliveryWindows = {
  geocodeCutoffDate: string;
  geocodeCutoffTime: string;
  deliveryWindows: {
    [KEY_A_LA_CARTE]: FormattedDeliveryOption[];
    [KEY_PICK_UP]: FormattedDeliveryOption[];
  };
  deliveryMinimums: DeliveryWindow.DeliveryMinimum[];
};

type WindowsType = {
  data: DeliveryWindows[] | null;
  isLoading: {
    [KEY_A_LA_CARTE]: boolean;
    [KEY_PICK_UP]: boolean;
  };
};

export type CheckoutData = {
  orderPlaced?: boolean;
  isPlacingOrder?: boolean;
  isValidDeliveryDate?: boolean;
  isAcknowledged?: boolean;
  isAcknowledgedEditMode?: boolean;
  isCheckboxError?: boolean;
  isCheckboxErrorEditMode?: boolean;
  shoppingCategoriesOpen?: ShoppingCategoriesOpenProps | null;
  deliveryMinimums?: DeliveryWindow.DeliveryMinimum[];
  marketingMessage?: MarketingMessage | null;
  marketingMessageEdit?: MarketingMessage | null;
} & CheckoutState;

export type CheckoutGlobalType = {
  eligibility: EligibilityType | null;
  windows: WindowsType;
  checkoutData: CheckoutData;
};

export enum UseCheckoutGlobalReducerActions {
  SET_ELIGIBILITY,
  SET_WINDOWS,
  SET_CHECKOUT_DATA,
}

export type CheckoutGlobalActions =
  | {
      type: UseCheckoutGlobalReducerActions.SET_ELIGIBILITY;
      payload: Partial<EligibilityType>;
    }
  | {
      type: UseCheckoutGlobalReducerActions.SET_WINDOWS;
      payload: Partial<WindowsType>;
    }
  | {
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA;
      payload: Partial<CheckoutData>;
    };

export type ProductsForDeliveryWindow = {
  productNumber: string;
  quantity: number;
};

export type PersistedOrder = {
  order: Order;
  selectedWindowDelivery?: string | null;
  warehouseAddress?: Partial<Address> | null;
};

export enum ShoppingCategoriesOpenProps {
  DRAWER = 'drawer',
  LEFT_SIDE = 'leftside',
}

export type PersistedDeliveryWindows = {
  deliveryWindows: ReturnType<typeof useGetDeliveryWindows>['deliveryWindows'];
  isLoadingDeliveryWindows: ReturnType<typeof useGetDeliveryWindows>['isLoading'];
};

type CartActionsType = {
  removeItem: (lineItem: Partial<LineItem>, cartId?: string) => Promise<Cart>;
  updateCartDetails: (details: CartDetails) => Promise<Cart>;
  cart: Cart;
  dispatchCart: Dispatch<CartGlobalAction>;
  fetchAllCarts: (cartId?: string) => Promise<Cart[]>;
  setDraftCart: () => void;
};

export type UsePrivateCheckoutGlobalType = {
  userData: ReturnType<typeof use_privateUserGlobal>;
  isPrintBack: boolean;
  softCutoffs: SoftCutoffsResponse;
  cartActions: CartActionsType;
  editOrder: ReturnType<typeof use_privateEditOrderGlobal>;
};

export type UserDataType = {
  state: UserState;
  logout: () => Promise<void>;
};
