import { useCallback, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import {
  algoliaInsightPurchasedObjectIDs,
  algoliaInsightPurchasedObjectIDsAfterSearch,
} from 'composable/analytics/algolia/algolia-tracking';
import { AlgoliaPurchasedObjectEventProps } from 'composable/analytics/algolia/types';
import { useFormat } from 'helpers/hooks/useFormat';
import { getProductExtraData } from 'helpers/utils/eventTracking';
import {
  getEventForPurchasedObjectsIds,
  getEventsForPurchasedObjectsAfterSearch,
  splitCartLineItems,
} from 'helpers/utils/formatCartLineItems';
import { UserState } from 'hooks/global/use_privateUserGlobal/types';
import chunk from 'lodash/chunk';
import { useSWRConfig } from 'swr';
import { useLocalStorageAddedProducts } from './useLocalStorageAddedProducts';

// For more information of this value, please check
//https://www.algolia.com/doc/api-reference/api-methods/purchased-object-ids/
const MAXIMUM_OBJECTS_PER_EVENT = 20;

const OTHER_LINE_ITEMS_INDEX = 0;

export const usePurchaseAlgoliaTracking = (
  user: UserState,
  logout: (hardLogout?: boolean, message?: string) => Promise<void>,
  cart: Cart,
) => {
  const { cache } = useSWRConfig();
  const { addedProducts, clearProducts } = useLocalStorageAddedProducts();
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const customer = user?.ctUser?.customer;

  const eventBase = useMemo<Pick<AlgoliaPurchasedObjectEventProps, 'user' | 'activeAccount'>>(() => {
    return {
      user: customer,
      activeAccount: user.activeAccount,
      totalValue: cart?.sum?.centAmount / 100,
    };
  }, [user?.activeAccount, customer, cart?.sum]);

  const trackPurchase = useCallback(async () => {
    if (!user || !cart) {
      return;
    }
    const skus = cart?.lineItems?.map((lineItem) => lineItem.variant.sku) || [];
    const objectProductExtraData = await getProductExtraData(
      skus,
      user,
      toast,
      formatMessage({ id: 'app.generic.error' }),
      logout,
      cache,
    ); //We use the method to avoid multiple requests everytime that the cart is updated
    const extraProductData = objectProductExtraData.map((product) => product.productExtraData);

    const splitCart = splitCartLineItems(addedProducts, cart?.lineItems);

    Object.values(splitCart).forEach((lineItems, indexSplitCart) => {
      chunk(lineItems, MAXIMUM_OBJECTS_PER_EVENT).forEach((chunkItems) => {
        if (indexSplitCart === OTHER_LINE_ITEMS_INDEX) {
          const event = {
            ...eventBase,
            ...getEventForPurchasedObjectsIds(chunkItems, extraProductData),
          };
          algoliaInsightPurchasedObjectIDs(event);
          return;
        }

        const eventsAfterSearch = getEventsForPurchasedObjectsAfterSearch(addedProducts, chunkItems, extraProductData);
        eventsAfterSearch.forEach((event) => algoliaInsightPurchasedObjectIDsAfterSearch({ ...eventBase, ...event }));
      });
    });
    clearProducts();
  }, [eventBase, cart?.lineItems, addedProducts]);

  return trackPurchase;
};
