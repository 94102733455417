import { NextRouter } from 'next/router';

export const getOrderDetailsFromRoute = (router: NextRouter) => {
  const { asPath } = router;

  const pathSegments = asPath.split('/').filter((segment) => segment);

  const customerNumber = pathSegments[1];
  const orderNumber = pathSegments[2];

  return {
    customerNumber,
    orderNumber,
  };
};
