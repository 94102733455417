import { FunctionComponent } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { lineItemModifications } from '@Types/shamrockApi/Order';
import { useFormat } from 'helpers/hooks/useFormat';;

type ModificationLineItemProps = {
  lineItem: lineItemModifications;
  products: any[];
};

export const ModificationLineItem: FunctionComponent<ModificationLineItemProps> = ({ lineItem, products }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const modificationType = (oldQuantity, newQuantity, newStatus) => {
    if (['CN', 'DL'].includes(newStatus)) {
      return formatMessage({ id: 'orderDetails.modifications.removed' });
    }

    if (oldQuantity == 0 && newQuantity > 0) {
      return formatMessage({ id: 'orderDetails.modifications.added' });
    }

    return formatMessage({ id: 'orderDetails.modifications.changed' });
  };

  return (
    <HStack
      alignItems="flex-start"
      w="100%"
      borderBottom="1px solid"
      borderColor="neutral.100"
      p={'8px 0'}
      gap="8px"
      sx={{
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
      justifyContent="space-between"
    >
      <VStack alignItems="flex-start" w="163px" gap={0}>
        <Text color="neutral.800" fontSize="sm" lineHeight="120%">
          {products?.find((product) => product?.productId === lineItem?.productNumber)?.name}
        </Text>
        <Text color="neutral.600" fontSize="sm" lineHeight="150%">
          {lineItem?.productNumber} |{' '}
          {lineItem?.newQuantity == 1
            ? formatMessage({
                id: 'cart.orderSummary.totalCase',
                values: { count: lineItem?.newQuantity ?? 0 },
              })
            : formatMessage({
                id: 'cart.orderSummary.totalCases',
                values: { count: lineItem?.newQuantity ?? 0 },
              })}
        </Text>
      </VStack>
      <Box w="60px">
        <Text fontSize="sm" lineHeight="120%" color={'violet.800'} w="100%" textAlign="right">
          {modificationType(lineItem?.oldQuantity, lineItem?.newQuantity, lineItem?.newStatus)}
        </Text>
      </Box>
    </HStack>
  );
};
