import { Stack, VStack, Skeleton } from '@chakra-ui/react';

export const OrderDetailsSkeleton = () => {
  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 5 }}>
      {/* Order Details Side */}
      <VStack w={{ base: 'full', md: '295px' }}>
        <Skeleton height={{ base: '337px', md: '390px' }} width="100%" borderRadius="12px" />

        <Skeleton
          height={{ base: '150px', md: '390px' }}
          width="100%"
          borderRadius="12px"
          display={{ base: 'block', md: 'none' }}
        />

        <Skeleton height="56px" width="100%" borderRadius="12px" />
        <Skeleton height={{ base: '226px', md: '245px' }} width="100%" borderRadius="12px" />
        <Skeleton height={{ base: '256px', md: '256px' }} width="100%" borderRadius="12px" />
      </VStack>

      {/* Order Summary Side */}
      <VStack flex={1} gap={2} display={{ base: 'none', md: 'block' }}>
        <Skeleton width="100%" height={{ base: '400px', md: '100%' }} borderRadius="12px" />
      </VStack>
    </Stack>
  );
};
