import { useGlobal } from 'components/globalProvider';
import { isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { impersonatorIdFromStorage } from './utils';
import { UserGlobalStateActions } from 'hooks/global/use_privateUserGlobal/types';
import routes from 'helpers/constants/routes';
import delay from 'lodash/delay';

export const useSuperUserGuard = () => {
  const {
    state: { loggedAsSuperUser, isSuperUser },
    dispatch,
  } = useGlobal().useUserGlobal;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      if (loggedAsSuperUser && url === routes.HOME) {
        delay(() => dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } }), 1000);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [loggedAsSuperUser, router.events, dispatch]);

  useEffect(() => {
    const accountNumber = impersonatorIdFromStorage();
    const isSuperUserPage = isSuperUserPageCb(router.asPath);
    if (loggedAsSuperUser && !accountNumber && !isSuperUserPage) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
      router.reload();
    }

    if (isSuperUser && !loggedAsSuperUser && isSuperUserPage) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }

    const notAuthorized = !isSuperUser && !loggedAsSuperUser && isSuperUserPage;
    const alreadySelectedAccount = isSuperUserPage && loggedAsSuperUser && accountNumber;
    const shouldPushHome = notAuthorized || alreadySelectedAccount;
    if (shouldPushHome) {
      router.push(routes.HOME);
      return;
    }
  }, [loggedAsSuperUser, router.asPath, isSuperUser]);
};
