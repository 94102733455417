import { FunctionComponent } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { headerModifications } from '@Types/shamrockApi/Order';
import { getDeliveryTypeCopyKey } from 'composable/helpers/utils/order-utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';

type ModificationHeaderProps = {
  header: headerModifications;
};

export const ModificationHeader: FunctionComponent<ModificationHeaderProps> = ({ header }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const modificationValues = (header) => {
    if (header.oldOrderType != '' && header.newOrderType != '') {
      const typeKey = getDeliveryTypeCopyKey(header.newOrderType);

      return {
        title: formatMessage({ id: 'orders.table.columns.deliveryType' }),
        value: typeKey ? formatMessage({ id: typeKey }) : header.newOrderType,
      };
    }

    if (header.newRequestDateTime != '' && header.oldRequestDateTime != '0') {
      let day = '';
      if (!!header?.newRequestDateTime) {
        const newDate = new Date(header.newRequestDateTime);
        day = newDate && isValid(new Date(newDate)) ? format(new Date(newDate), 'MM/dd/yyyy') : '';
      }

      return {
        title: formatMessage({ id: 'orderDetails.deliveryDate' }),
        value: day,
      };
    }
  };

  return (
    <HStack
      alignItems="flex-start"
      w="100%"
      borderBottom="1px solid"
      borderColor="neutral.100"
      p={'8px 0'}
      gap="8px"
      sx={{
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
      justifyContent="space-between"
    >
      <VStack alignItems="flex-start" w="163px" gap={0}>
        <Text color="neutral.800" fontSize="sm" lineHeight="120%">
          {modificationValues(header)?.title}:
        </Text>
        <Text color="neutral.800" fontSize="sm" lineHeight="120%">
          {modificationValues(header)?.value}
        </Text>
      </VStack>
      <Box w="60px">
        <Text fontSize="sm" lineHeight="120%" color={'violet.800'} w="100%" textAlign="right">
          {formatMessage({ id: 'orderDetails.modifications.changed' })}
        </Text>
      </Box>
    </HStack>
  );
};
