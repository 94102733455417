import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import calculateCasePriceFromUnitPrice from 'composable/components/checkout/utils/calculateCasePriceFromUnitPrice';
import { isCancelled, isProcessing, isSubmitted } from 'composable/helpers/utils/order-status';

export const calculateCartCount = (itemsList) => {
  return itemsList?.reduce((acc, item) => acc + item.count, 0);
};

export const calculateCartTotal = (itemsList) => {
  return itemsList?.reduce((acc, item) => acc + item.totalPrice?.centAmount, 0);
};

export const getOrderQuantity = (lineItems: OrderDetail['lineItems']) => {
  return lineItems?.reduce((acc, item) => acc + item.orderedQuantity, 0);
};

export const getOrderTotal = (lineItems: OrderDetail['lineItems']) => {
  const orderTotal = lineItems?.reduce((acc, item) => {
    const priceToUse = item.discountedPrice ? item.discountedPrice : item.originalPrice;
    return acc + priceToUse * item.orderedQuantity;
  }, 0);

  return orderTotal;
};

export const getOrderTotalCentValue = (lineItems: OrderDetail['lineItems']) => {
  const orderTotal = lineItems?.reduce((acc, item) => {
    const priceToUse = item.discountedPrice ? item.discountedPrice : item.originalPrice;
    return acc + priceToUse * item.orderedQuantity;
  }, 0);

  return orderTotal;
};

export const getOrderTotalCentValueNew = (lineItems: OrderDetailLineItem[], variantMap: Map<string, Variant>) => {
  if (!lineItems || !variantMap) {
    return 0;
  }
  const orderTotal = lineItems?.reduce((acc, item) => {
    const isNew = item?.lineNumber == null;
    const attributes = variantMap.get(item.productNumber)?.attributes;
    if (!attributes) {
      return acc;
    }
    // For new items we receive the case price already
    let discountedPrice = item.discountedPrice;
    let currentPrice = item.originalPrice;
    if (!isNew) {
      // For items that were already in the order we only receive price per pound for ctw items
      const calculatedPrices = calculateCasePriceFromUnitPrice(item, attributes);
      discountedPrice = calculatedPrices.discountedPrice;
      currentPrice = calculatedPrices.currentPrice;
    }
    const priceToUse = discountedPrice ? discountedPrice : currentPrice;
    return acc + priceToUse * item.orderedQuantity;
  }, 0);

  return orderTotal;
};

export const getOrderInvoiceQuantity = (lineItems: OrderDetail['lineItems']) => {
  return lineItems?.reduce((acc, item) => acc + item.invoicedQuantity, 0);
};

// Uses orderStatus to determine which function runs to get the correct order quantity
export const getOrderQuantityFromStatus = (orderStatus: string, lineItems: OrderDetail['lineItems']) => {
  const casesTotal =
    isCancelled(orderStatus) || isSubmitted(orderStatus) || isProcessing(orderStatus)
      ? getOrderQuantity(lineItems)
      : getOrderInvoiceQuantity(lineItems);

  return casesTotal;
};
