import { FunctionComponent, PropsWithChildren } from 'react';
import { VStack, BoxProps, StackProps } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';

type InfoProps = StackProps & {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
};

export const Info: FunctionComponent<PropsWithChildren<InfoProps>> = ({
  children,
  breakpointTokenToDesktop = 'md',
  ...props
}) => {
  return (
    <VStack alignItems="flex-start" flex={1} p={{ base: 3, [breakpointTokenToDesktop]: 4 }} spacing={2} {...props}>
      {children}
    </VStack>
  );
};
