import { useEffect, useMemo, useState } from 'react';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { useGetCtProducts } from 'composable/helpers/hooks';
import { getLineItemsSkus } from 'composable/helpers/utils/order-utils';

type UseProductVariants = (
  data: { lineItems: OrderDetailLineItem[]; skus?: never } | { lineItems?: never; skus: string[] },
) => {
  products: Product[];
  isLoading: boolean;
  variants: Map<string, Variant>;
  skus: string[];
};

export const useProductVariants: UseProductVariants = ({ lineItems, skus }) => {
  const uniqueSkus = useMemo(() => {
    if (!lineItems?.length && !skus?.length) return [];

    const sourceSkus = skus || getLineItemsSkus(lineItems || []);

    return [...new Set(sourceSkus)];
  }, [lineItems, skus]);
  const [variants, setVariants] = useState<Map<string, Variant>>(new Map());

  const { products, isLoading } = useGetCtProducts(uniqueSkus);

  useEffect(() => {
    const map = new Map<string, Variant>();

    products?.forEach((product) => {
      if (product?.variants?.[0]) {
        map.set(product.productId, product.variants[0]);
      }
    });

    setVariants(map);
  }, [products, isLoading]);

  return {
    products: products || [],
    isLoading,
    variants,
    skus: uniqueSkus,
  };
};
