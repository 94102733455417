import { ERROR_PREFIX } from 'utils/orderList/constants';

export const isDeliveredComplete = (orderStatus: string): boolean => {
  return ['delivered'].includes(orderStatus.toLowerCase());
};

export const isOutForDelivery = (orderStatus: string): boolean => {
  return ['outfordelivery'].includes(orderStatus.toLowerCase());
};

export const isReadyForPickup = (orderStatus: string): boolean => {
  return ['readyforpickup'].includes(orderStatus.toLowerCase());
};

export const isProcessing = (orderStatus: string): boolean => {
  return ['releasedtowarehouse'].includes(orderStatus.toLowerCase());
};

export const isSubmitted = (orderStatus: string): boolean => {
  return [
    'processing',
    'pending',
    'openorder',
    'inprogress',
    'locked',
    'creditbeingprocessed',
    'created',
    'submitted',
  ].includes(orderStatus.toLowerCase());
};

export const isCancelled = (orderStatus: string): boolean => {
  return ['cancelled'].includes(orderStatus.toLowerCase());
};

export const isCompleted = (orderStatus: string): boolean => {
  return ['creditcomplete'].includes(orderStatus.toLowerCase());
};

export const isError = (orderStatus: string, orderNumber?: string): boolean => {
  return orderStatus.toLowerCase() === 'error' || orderNumber?.includes(ERROR_PREFIX);
};
