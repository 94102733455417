import {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { ReturnRow, Order } from '@Types/shamrockApi/Order';
import { useGetMultipleOrders, useGetOrderReturns, useOrders } from 'composable/helpers/hooks';
import {
  isCancelled,
  isDeliveredComplete,
  isError,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';
import {
  getDeliveryTimeOutput,
  isALaCartDeliveryType,
  isCustomerDirectDeliveryType,
  isInvoiceReissueDeliveryType,
  isPickupDeliveryType,
  isStandardDeliveryType,
  sortReturns,
} from 'composable/helpers/utils/order-utils';
import { sortOrders } from 'composable/helpers/utils/sort-orders';
import { differenceInSeconds } from 'date-fns';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import routes from 'helpers/constants/routes';
import { SortOption, TypeOption } from 'types/orderList';
import { getOrdersSortedWithErrors } from 'frontastic/tastics/composable/orders/helpers';
import { useSearch } from 'frontastic/tastics/composable/orders/useSearch';
import { useGlobal } from './globalProvider';

type OrderListContextValues = {
  changePeriod: (selectedPeriod: number) => void;
  changeFilter: (selectedFilter: SortOption) => void;
  changeAccount: (selectedAccount: string) => void;
  changeType: (selectedType: TypeOption) => void;
  isLoadingOrderReturns: boolean;
  returnBulkResponseTime: number;
  isLoadingMultipleOrders: boolean;
  isLoadingOrders: boolean;
  isAllReturns: boolean;
  returnsSearch: ReturnType<typeof useSearch<ReturnRow>>;
  ordersSearch: ReturnType<typeof useSearch<Order>>;
  changeAllReturnsSort;
  noResults: boolean;
  ready: boolean;
  allReturnsFiltered: ReturnRow[];
  ordersFiltered: Order[];
  allReturns: ReturnRow[];
  totalItems: number;
  showLoadNextButton: boolean;
  setPage: (page: number) => void;
  page: number;
  period: number;
  filter: SortOption;
  customerNumbers: string[];
  type: TypeOption;
  mutateOrders: () => void;
  orderGuideLoadingState: React.MutableRefObject<boolean>;
  loadTimeEndpoint?: MutableRefObject<number>;
  feWork?: MutableRefObject<number>;
  addProductActionWasCalled: string[];
  setAddProductActionWasCalled: React.Dispatch<React.SetStateAction<string[]>>;
};

const ITEMS_PER_PAGE = 25;

const OrderListContext = createContext<OrderListContextValues>(null!);

console.warn('Deprecated provider OrderListProvider');
export const useOrderListPage = () => {
  const context = useContext(OrderListContext);

  if (!context) {
    throw new Error('useOrderListPage must be used within a OrderListProvider');
  }

  return context;
};

export const OrderListProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [period, setPeriod] = useState(7);
  const [ordersFiltered, setOrdersFiltered] = useState<Order[]>([]);
  const [filter, setFilter] = useState<SortOption>(SortOption.All);
  const [type, setType] = useState<TypeOption>(TypeOption.All);
  const [allReturnsSort, setAllReturnsSort] = useState('dateCreated');
  const { loading: isLoadingUserData, accountList } = useGlobal().useUserGlobal.state;
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const orderGuideLoadingState = useRef(true);
  const [customerNumbers, setCustomerNumbers] = useState(null);
  const [allReturns, setAllReturns] = useState<ReturnRow[]>([]);
  const startTime = useRef(0);
  const loadTimeEndpoint = useRef(0);
  const feWork = useRef(0);
  const SECONDS_TO_HIDE_ERROR = 10;

  const [addProductActionWasCalled, setAddProductActionWasCalled] = useState<string[]>([]);
  const businessUnitKeys = accountList.map((businessUnit) => businessUnit.key);

  const isAllReturns = filter === SortOption.AllReturns;

  useEffect(() => {
    if (!customerNumbers) {
      setCustomerNumbers(businessUnitKeys);
    }
  }, [businessUnitKeys, customerNumbers]);

  const router = useRouter();

  const enableFetch = useMemo(() => router.asPath === routes.ORDER_LIST_PAGE, [router.asPath]);

  // Fetch orders for single account
  const { orders = [], isLoadingOrders } = useOrders({ period, enable: enableFetch });

  // Fetch orders for multiple accounts
  const {
    multipleOrders = [],
    isLoadingMultipleOrders,
    multipleOrdersMutation,
    returnBulkResponseTime,
  } = useGetMultipleOrders({
    period,
    isAllReturns,
    customerNumbers,
    enable: enableFetch,
  });

  const allOrders = useMemo(() => {
    if (!isAllReturns) {
      return multipleOrders ?? [];
    }

    return orders ?? [];
  }, [isAllReturns, multipleOrders, orders]);

  useEffect(() => {
    if (isLoadingMultipleOrders && multipleOrders.length === 0) {
      startTime.current = Date.now();
    } else if (!isLoadingMultipleOrders && multipleOrders.length > 0) {
      window.LogRocket?.log(`Order list received, count: ${multipleOrders.length}`);
      const currentTime = Date.now();
      loadTimeEndpoint.current = currentTime - startTime.current;
      feWork.current = Date.now();
    }
  }, [isLoadingMultipleOrders, multipleOrders]);

  useEffect(() => {
    if (!isAllReturns && enableFetch) {
      if (!isLoadingUserData && !isLoadingMultipleOrders && !isLoadingOrders) {
        setReady(true);

        if (allOrders) {
          // filtering the orders based upon the Type filter
          const ordersByType = allOrders.filter((order) => {
            if (order.lineItemsQuantity > 0) {
              switch (type) {
                case TypeOption.Standard:
                  return isStandardDeliveryType(order.type);
                case TypeOption.ALaCarte:
                  return isALaCartDeliveryType(order.type);
                case TypeOption.Pickup:
                  return isPickupDeliveryType(order.type);
                case TypeOption.CustomerDirect:
                  return isCustomerDirectDeliveryType(order.type);
                case TypeOption.InvoiceReissue:
                  return isInvoiceReissueDeliveryType(order.type);
              }
            }

            return order.lineItemsQuantity > 0;
          });

          const itemsToLoad = ITEMS_PER_PAGE * page;
          const transformedData = ordersByType.map((order) => ({
            ...order,
            date: getDeliveryTimeOutput(order.status, order.deliveryDate, order.scheduledDeliveryWindow),
          }));
          const sortedOrders = sortOrders(transformedData);
          const ordersWithErrors = getOrdersSortedWithErrors(sortedOrders);

          const filteredOrders = ordersWithErrors.filter((item) => {
            // Error orders only display if submittedDateTime is greater than SECONDS_TO_HIDE_ERROR
            if (isError(item.status, item.orderNumber) && item.submittedDateTime) {
              const submittedDateTime = new Date(item.submittedDateTime);
              const currentTime = new Date();
              const diff = Math.abs(differenceInSeconds(currentTime, submittedDateTime));
              return diff > SECONDS_TO_HIDE_ERROR;
            }

            if (item.lineItemsQuantity > 0) {
              switch (filter) {
                case SortOption.Submitted:
                  return isSubmitted(item.status);
                case SortOption.Processing:
                  return isProcessing(item.status);
                case SortOption.OutForDelivery:
                  return isOutForDelivery(item.status);
                case SortOption.ReadyForPickup:
                  return isReadyForPickup(item.status);
                case SortOption.Delivered:
                  return isDeliveredComplete(item.status);
                case SortOption.Cancelled:
                  return isCancelled(item.status);
              }
            }

            return item.lineItemsQuantity > 0 || isError(item.status, item.orderNumber);
          });
          setOrdersFiltered(filteredOrders.slice(0, itemsToLoad));
          setOriginalOrders(filteredOrders);
          setTotalItems(filteredOrders.length);
        }
      } else {
        setReady(false);
      }
    }
  }, [
    isLoadingUserData,
    period,
    filter,
    page,
    allOrders,
    isLoadingMultipleOrders,
    isLoadingOrders,
    isAllReturns,
    type,
    enableFetch,
  ]);

  const changePeriod = (selectedPeriod: number) => {
    setPeriod(selectedPeriod);
    setPage(1);
    // Set ready state to false render the skeleton loader
    setReady(false);
  };

  const changeAllReturnsSort = (selectedSort) => {
    setAllReturnsSort(selectedSort);
    setPage(1);
  };

  const changeAccount = (selectedAccount: string) => {
    if (selectedAccount === 'all') {
      setCustomerNumbers(businessUnitKeys);
    } else {
      setCustomerNumbers([selectedAccount]);
    }
    setPage(1);
    // Set ready state to false render the skeleton loader
    setReady(false);
  };

  const changeType = (selectedType: TypeOption) => {
    setType(selectedType);
    setPage(1);
    // Set ready state to false render the skeleton loader
    setReady(false);
  };

  // Search Functionality
  const [originalOrders, setOriginalOrders] = useState<Order[]>([]);

  const ordersSearch = useSearch({
    itemsPerPage: ITEMS_PER_PAGE,
    originalItems: originalOrders,
    setItemsFiltered: setOrdersFiltered,
    SEARCH_FIELDS: ['orderNumber', 'deliveryDate', 'invoiceNumber', 'purchaseOrderNumber'],
    mapItemToFilter: (order) => ({
      ...order,
      deliveryDate: order.deliveryDate ? format(parseISO(order.deliveryDate), 'MM/dd/yyyy') : order.deliveryDate,
    }),
  });

  const { orderReturns, isLoadingOrderReturns } = useGetOrderReturns({
    customerNumbers,
    isAllReturns,
    period,
  });

  const returnsSearch = useSearch({
    itemsPerPage: ITEMS_PER_PAGE,
    originalItems: orderReturns,
    setItemsFiltered: setAllReturns,
    SEARCH_FIELDS: ['orderNumber', 'returnNumber'],
  });

  const changeFilter = (selectedFilter: SortOption) => {
    setFilter(selectedFilter);
    setPage(1);
    returnsSearch.handleResetSearchFilter();
    ordersSearch.handleResetSearchFilter();
  };

  useEffect(() => {
    if (isAllReturns) {
      if (!isLoadingOrderReturns && isAllReturns) {
        setReady(true);
        setAllReturns(orderReturns);
      } else {
        setReady(false);
        setAllReturns([]);
      }
    }
  }, [isAllReturns, isLoadingOrderReturns, orderReturns]);

  const noResults = useMemo(() => {
    if (isAllReturns) {
      return ready && !allReturns.length;
    }

    return ready && !ordersFiltered.length;
  }, [allReturns.length, isAllReturns, ordersFiltered?.length, ready]);

  const allReturnsFiltered = useMemo(() => {
    const itemsToLoad = ITEMS_PER_PAGE * page;

    const sortedReturns = sortReturns(allReturns ?? [], allReturnsSort);

    return sortedReturns.slice(0, itemsToLoad);
  }, [allReturns, allReturnsSort, page]);

  const showLoadNextButton = useMemo(() => {
    if (isAllReturns) {
      return allReturns.length > allReturnsFiltered.length;
    }

    return totalItems > ordersFiltered.length;
  }, [allReturns.length, allReturnsFiltered.length, isAllReturns, ordersFiltered.length, totalItems]);

  const [shouldMutateOrders, setShouldMutateOrders] = useState(false);

  const mutateOrders = () => setShouldMutateOrders(true);

  /**
   * the mutateOrders method is called after updating an order
   * so that the order list is updated with the latest data
   *
   * in the SWR documentation it says that the mutate method will not update
   * the cache or trigger revalidation unless there is a mounted SWR hook using the same key.
   *
   * as the keys used in useOrders and useGetMultipleOrders hooks sometimes are null ... we need to use this state approach
   * to trigger the revalidation
   *
   * so the revalidation will be called only when the user is on the order list page and shouldMutateOrders is true
   */
  useEffect(() => {
    if (enableFetch && shouldMutateOrders) {
      multipleOrdersMutation();
      setShouldMutateOrders(false);
    }
  }, [shouldMutateOrders, enableFetch]);

  useEffect(() => {
    if (enableFetch) {
      returnsSearch.handleSearch();
      ordersSearch.handleSearch();
    }
  }, [enableFetch]);

  return (
    <OrderListContext.Provider
      value={{
        changePeriod,
        changeFilter,
        changeAccount,
        changeType,
        isLoadingOrderReturns,
        returnBulkResponseTime,
        isLoadingMultipleOrders,
        isLoadingOrders,
        isAllReturns,
        returnsSearch,
        ordersSearch,
        changeAllReturnsSort,
        noResults,
        ready,
        allReturnsFiltered,
        ordersFiltered,
        allReturns,
        totalItems,
        showLoadNextButton,
        setPage,
        page,
        period,
        filter,
        customerNumbers,
        type,
        mutateOrders,
        orderGuideLoadingState,
        loadTimeEndpoint,
        feWork,
        addProductActionWasCalled,
        setAddProductActionWasCalled,
      }}
    >
      {children}
    </OrderListContext.Provider>
  );
};
