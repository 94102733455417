import { FunctionComponent, useEffect } from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import ExclamationIcon from '@heroicons/react/solid/ExclamationIcon';
import { useGlobal } from 'components/globalProvider';
import { useOrderListPage } from 'components/orderListProvider';
import { useFormat } from 'helpers/hooks/useFormat';
import { Z_INDEX } from './global-search/constants';
import { OFFLINE_BANNER_ID } from './offline-detector/constants';

export const OfflineDetector: FunctionComponent = () => {
  const { addProductActionWasCalled, setAddProductActionWasCalled } = useOrderListPage();
  const { formatMessage } = useFormat({ name: 'common' });

  const { isOffline } = useGlobal();

  useEffect(() => {
    if (!isOffline && addProductActionWasCalled.length > 0) {
      setAddProductActionWasCalled([]);
    }
  }, [isOffline, addProductActionWasCalled.length]);

  return isOffline ? (
    <HStack
      id={OFFLINE_BANNER_ID}
      position="sticky"
      zIndex={Z_INDEX.HEADER}
      top={0}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      bg="yellow.400"
      justifyContent="center"
      px={5}
      py={2}
      spacing={2}
      data-testid={OFFLINE_BANNER_ID}
    >
      <Icon as={ExclamationIcon} boxSize={6} color="neutral.900" />
      <Text fontSize={{ base: 'xs', lg: 'sm' }} color="neutral.900">
        {formatMessage({ id: 'offlineDetector.offline' })}
      </Text>
    </HStack>
  ) : (
    <></>
  );
};
