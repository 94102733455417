import { PropsWithChildren, forwardRef } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(({ children, ...props }, ref) => {
  return (
    <Box
      as="section"
      bg="white"
      borderColor="neutral.100"
      borderRadius="lg"
      borderWidth={1}
      display="flex"
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
});

Card.displayName = 'Card';
