import { AsAssociateBusinessUnitResult, DeliverySchedule } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { useGlobal } from 'components/globalProvider';
import { SideMenuItem } from 'composable/types';

export const hasCreditOnHoldInfo = (selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  try {
    const sfcBusinessUnitStores = JSON.parse(selectedBusinessUnit?.custom?.fields?.sfc_business_unit_stores || '[]');
    return sfcBusinessUnitStores[0]?.OnHold || false;
  } catch (error) {
    console.error('Failed to parse OnHold value:', error);
    return false;
  }
};

export type DeliveryDetail = {
  DayOfWeek?: number;
  DeliveryEndTime?: string;
  DeliveryStartTime?: string;
  IsOvernight?: boolean;
};

export const getDeliveryInfo = (selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>): DeliverySchedule => {
  try {
    const sfcBusinessUnitDeliveryPlans = JSON.parse(
      selectedBusinessUnit?.custom?.fields?.sfc_business_unit_delivery_plans || '[]',
    );
    return sfcBusinessUnitDeliveryPlans[0]?.DeliverySchedules?.[0] || ({} as DeliverySchedule);
  } catch (error) {
    console.error('Failed to parse DeliverySchedules values:', error);
    return {} as DeliverySchedule;
  }
};

export const getStoreKey = (selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  try {
    const sfcBusinessUnitStores = JSON.parse(selectedBusinessUnit?.custom?.fields?.sfc_business_unit_stores || '[]');
    return sfcBusinessUnitStores[0]?.StoreKey || false;
  } catch (error) {
    console.error('Failed to parse StoreKey value:', error);
    return false;
  }
};

export const getAlgoliaKey = (
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  customer: Partial<Customer>,
): string | boolean => {
  try {
    const visibilityKey = JSON.parse(customer.custom?.fields?.sfc_customer_visibility_key || '[]');
    const storeKey = getStoreKey(selectedBusinessUnit);
    const visibilityObject = visibilityKey.find((item) => {
      return item[selectedBusinessUnit.key];
    });
    const key = visibilityObject?.[selectedBusinessUnit?.key]?.find((item) => item?.[storeKey] ?? false);
    const algoliaKey = key?.[storeKey] || false;

    if (algoliaKey === false) {
      console.log("User businessUnit or StoreKey doesn't match the search key.");
    }

    return algoliaKey;
  } catch (error) {
    console.error('Failed to parse AlgoliaKey value:', error);
    return '';
  }
};

export const useFilterAllowedLinks = (links: SideMenuItem[]) => {
  const canViewProductCatalog = useGlobal().useUserGlobal.canViewProductCatalog();
  const permissions = useGlobal().useUserGlobal.state.shamrockUser?.user?.permissions?.data ?? [];

  const checkCatalogPermission = (item: SideMenuItem) => {
    if (item?.requiredPermission?.trim() != '' && !permissions.includes(item.requiredPermission)) {
      return false;
    }

    if (item.label.toLowerCase().trim() === 'catalog') {
      return canViewProductCatalog;
    }

    return true;
  };

  return links.filter(checkCatalogPermission);
};
