import { LineItem } from '@Types/cart/LineItem';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { createObjectId } from 'composable/analytics/algolia/createObjectId';
import { AlgoliaObjectData, AlgoliaPurchasedObjectEventProps } from 'composable/analytics/algolia/types';
import { getProductPrice } from 'composable/components/product-card-v2/helpers';
import { ProductAttributeKeys } from 'composable/components/types';
import { AddedProductsFromsPLP, CustomObjectData, LineItemsByQueryID } from 'composable/types';

export const formatCartLineItemsToTrack = (
  lineItems: LineItem[],
  extraData: ExtraProductData[],
):
  | {
      objectData: AlgoliaObjectData[];
      customObjectData: CustomObjectData[];
      objectIDs: string[];
    }
  | undefined => {
  if (lineItems?.length === 0 || extraData.length === 0) {
    return;
  }

  const data = lineItems.map((item) => {
    const sku = item.variant.sku;
    const { currentPrice, discountedPrice, pricePerUnit } = getProductPrice({
      attributes: item?.variant?.attributes,
      extraProductData: extraData.find((product) => product?.productNumber === sku),
    });

    return {
      objectData: {
        price: discountedPrice / 100,
        discount: (currentPrice - discountedPrice) / 100,
        quantity: item.count,
      },
      customObjectData: {
        productNumber: sku,
        brand: item?.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription],
        unitPrice: pricePerUnit,
      },
    };
  });

  const objectIDs = lineItems.map((item) => createObjectId(item, true));
  return {
    objectData: data.map((item) => item.objectData),
    customObjectData: data.map((item) => item.customObjectData),
    objectIDs,
  };
};

export const getEventForPurchasedObjectsIds = (
  lineItems: LineItem[],
  extraData: ExtraProductData[],
): Partial<AlgoliaPurchasedObjectEventProps> & { objectIDs: string[] } => {
  const { objectIDs, objectData } = formatCartLineItemsToTrack(lineItems, extraData);
  return {
    objectData,
    objectIDs,
    customData: {
      position: null,
      appliedFilters: null,
      displayedFilters: null,
    },
  };
};

export const getEventsForPurchasedObjectsAfterSearch = (
  addedProducts: AddedProductsFromsPLP[],
  lineItems: LineItem[],
  extraData: ExtraProductData[],
): (Partial<AlgoliaPurchasedObjectEventProps> & { objectIDs: string[] })[] => {
  const objectQueryIds = getLineItemsByQueryID(addedProducts, lineItems);
  const keys = Object.keys(objectQueryIds);
  if (keys.length === 0) {
    return [];
  }
  return keys.reduce((acc, queryID) => {
    if (!objectQueryIds[queryID][0]) {
      return acc;
    }
    const { appliedFilters, displayedFilters, indexName } = objectQueryIds[queryID][0]; //Since the query is the same for all the products, we can get the filters from the first product
    const objectIDs = objectQueryIds[queryID].map((objectQueryId) => objectQueryId.objectIDs[0]);
    const lineItems = objectQueryIds[queryID].map((objectQueryId) => objectQueryId.lineItem);
    const { objectData, customObjectData } = formatCartLineItemsToTrack(lineItems, extraData);

    acc.push({
      objectIDs,
      queryID,
      objectData,
      indexName,
      customData: {
        position: null,
        appliedFilters,
        displayedFilters,
        customObjectData,
      },
    });

    return acc;
  }, []);
};

export const splitCartLineItems = (addedProductsFromPLP: AddedProductsFromsPLP[], cartLineItems: LineItem[]) => {
  const addedProductsIds = addedProductsFromPLP.map((product) => product.objectIDs[0]);
  const addedProductsLineItems = cartLineItems.filter((lineItem) =>
    addedProductsIds.some((product) => product.includes(lineItem.productId)),
  );
  const otherProductsLineItems = cartLineItems.filter(
    (lineItem) => !addedProductsIds.some((product) => product.includes(lineItem.productId)),
  );

  return {
    otherProductsLineItems,
    addedProductsLineItems,
  };
};

export const getLineItemsByQueryID = (
  addedProductsFromPLP: AddedProductsFromsPLP[],
  cartLineItems: LineItem[],
): LineItemsByQueryID => {
  const objectQueryIds = addedProductsFromPLP.reduce((acc, cur) => {
    const { queryID } = cur;
    if (!acc[queryID]) {
      acc[queryID] = [];
    }
    const product = cartLineItems.find((lineItem) => cur.objectIDs[0].includes(lineItem.productId));
    if (product) {
      acc[queryID].push({
        lineItem: product,
        ...cur,
      });
    }
    return acc;
  }, {});
  return objectQueryIds;
};
