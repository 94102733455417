import { FormatMessageParams } from 'helpers/hooks/useFormat';

export const checkForPlural = (
  quantity: number,
  key: string,
  formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
) => {
  const singularLabel = formatMessage({ id: `orderSuccess.${key}`, values: { count: quantity } });
  const pluralLabel = formatMessage({ id: `orderSuccess.${key}_plural`, values: { count: quantity } });

  return quantity == 1 ? singularLabel : pluralLabel;
};
