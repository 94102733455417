import { useMemo, useRef } from 'react';
import { Order } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { getPayloadByWarehouses } from 'helpers/components/accountsOverviewHelper';
import useSWR from 'swr';
import { allAccountOrders } from 'frontastic/tastics/composable/orders/utils/fetchers';

type useGetMultipleOrdersProps = {
  period: number;
  isAllReturns: boolean;
  customerNumbers: string[] | null;
  enable?: boolean;
};

export const useGetMultipleOrders = ({
  period = 7,
  isAllReturns = false,
  customerNumbers,
  enable = true,
}: useGetMultipleOrdersProps) => {
  const {
    state: { accountList, accessToken, activeAccount },
    logout,
  } = useGlobal().useUserGlobal;

  const payload = useMemo(
    () =>
      getPayloadByWarehouses(
        customerNumbers && customerNumbers.length
          ? accountList.filter((bu) => customerNumbers.includes(bu.key))
          : accountList,
      ),
    [customerNumbers, accountList, activeAccount?.key],
  );
  const responseTimeRef = useRef<number | null>(null);

  // TODO: This fetcher should be moved to a its own file
  const fetcher = async () => {
    const startTime = Date.now();
    if (!isAllReturns) {
      try {
        const data = await allAccountOrders([payload, period, { accessToken, logout }]);
        responseTimeRef.current = Date.now() - startTime;
        return (data ?? []) as Order[];
      } catch (error) {
        console.error('Error API call: allAccountOrders', error);
      }
    }
    responseTimeRef.current = Date.now() - startTime;
  };

  const swrKey = ['useGetMultipleOrders', period, payload, activeAccount?.key];

  const {
    data: multipleOrders,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR(enable && !isAllReturns && payload ? swrKey : null, fetcher, {
    ...SWR_OPTIONS,
    errorRetryCount: 2,
  });

  const isLoadingMultipleOrders = isValidating || isLoading;

  return {
    multipleOrders,
    isLoadingMultipleOrders,
    error,
    multipleOrdersMutation: mutate,
    returnBulkResponseTime: responseTimeRef.current,
  };
};
