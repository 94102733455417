import { Text, VStack } from '@chakra-ui/react';
import { useUserHelpers } from 'composable/helpers/hooks/useUserHelpers';
import { DeliveryToProps } from '../types';

export const DeliveryTo = ({ warehouseAddress, stackProps, lineHeightFirstText = '120%' }: DeliveryToProps) => {
  const { getShippingAddress, getCustomerName, getCompanyNameShipping } = useUserHelpers();

  const shipping = getShippingAddress();
  const companyName = getCompanyNameShipping();

  return (
    <VStack align="unset" spacing={1} color="neutral.600" {...stackProps}>
      {warehouseAddress ? (
        <VStack align="unset" gap={1} fontWeight="normal" fontSize="sm">
          <Text
            fontSize={stackProps?.fontSize ? stackProps.fontSize : 'base'}
            color={stackProps?.color ? stackProps.color : 'neutral.900'}
            fontWeight={stackProps?.fontWeight ? stackProps?.fontWeight : 'bold'}
            lineHeight={lineHeightFirstText}
          >
            {warehouseAddress.firstName}
          </Text>
          <Text lineHeight="150%">{warehouseAddress.streetName}</Text>
          <Text lineHeight="150%">
            {warehouseAddress.city}, {warehouseAddress.state} {warehouseAddress.postalCode}
          </Text>
          <Text lineHeight="150%">{warehouseAddress.phone}</Text>
        </VStack>
      ) : (
        <VStack align="unset" gap={0.5}>
          <Text
            color={stackProps?.color ? stackProps.color : 'neutral.900'}
            fontWeight={stackProps?.fontWeight ? stackProps?.fontWeight : 'bold'}
            lineHeight={lineHeightFirstText}
          >
            {companyName}
          </Text>

          {shipping && (
            <>
              <Text as="p" lineHeight="150%" textTransform="capitalize">
                {shipping?.streetName?.toLowerCase()}
              </Text>
              <Text as="p" lineHeight="150%" textTransform="capitalize">
                {shipping?.city?.toLowerCase()}, {shipping?.state} {shipping?.postalCode}
              </Text>
              {shipping?.phone && (
                <Text as="p" lineHeight="150%">
                  {shipping?.phone}
                </Text>
              )}
            </>
          )}
        </VStack>
      )}
    </VStack>
  );
};

DeliveryTo.displayName = 'DeliveryTo';
