import { useMemo } from 'react';
import { useGlobal } from 'components/globalProvider';

const SPLIT_TOKEN = '_';
const INDEX_WAREHOUSE = 2;

export const useWarehouseByCustomerNumber = (customerNumber: string) => {
  const user = useGlobal().useUserGlobal.state;
  const { accountList } = user;
  const customerWarehouseHumber = useMemo(() => {
    const warehouse = accountList.find((bu) => bu.key === customerNumber)?.stores[0]?.key;
    return warehouse ? warehouse.split(SPLIT_TOKEN)[INDEX_WAREHOUSE] : '';
  }, [customerNumber, accountList]);

  return customerWarehouseHumber;
};
