import { checkForPlural } from 'composable/helpers/utils/check-for-plural';
import { FormatMessageParams } from 'helpers/hooks/useFormat';

export const getPluralizedText = (
  count: number,
  term: string,
  formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
) => {
  if (count) {
    return checkForPlural(count, term, formatMessage);
  }

  return formatMessage({ id: `orderSuccess.${term}_plural`, values: { count: 0 } });
};
