import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { TOAST_ICON } from 'composable/components/general';
import { useLocalStorageOrderGuide } from 'composable/components/order-guide/helpers';
import { WENT_OFFLINE } from 'composable/helpers/constants';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { logQueue } from 'helpers/utils/logQueue';
import { ActionCart, OFFLINE_NEW_CART_QUEUE_ID, SEPARATOR_FOR_QUEUE } from './global/use_privateCartGlobal/utils';

type QueueAction = {
  id: string;
  action: () => Promise<any>;
};

// leaving this here so we have a way to control if queue should be used or not.
const shouldAddToQueue = true;

export const useQueue = (isOffline: boolean) => {
  const isProcessingQueueRef = useRef(false);
  const queueRef = useRef<QueueAction[]>([]);
  const [queueState, setQueueState] = useState<QueueAction[]>([]);
  const router = useRouter();
  const [showUsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const isOnline = useMemo(() => !isOffline, [isOffline]);
  const wentOffline = useRef<boolean>(false);

  const { ogLocalStorageValues, setValue } = useLocalStorageOrderGuide();

  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  useEffect(() => {
    if (isOffline) {
      wentOffline.current = true;
    } else {
      setShowUnsavedChanges(false);
    }
  }, [isOffline]);

  useEffect(() => {
    if (queueState.length > 0 && isOnline) {
      processQueue();
    }
  }, [queueState, isOnline]);

  const syncQueueStateWithRef = () => {
    setQueueState([...queueRef.current]);
  };

  const addActionToQueue = useCallback(
    ({ id, action }: QueueAction) => {
      const [newAction, sku] = id.split(SEPARATOR_FOR_QUEUE);
      const existingActionToItem = queueRef.current.findIndex((item) => item.id.includes(sku));

      if (newAction === ActionCart.CREATE_CART) {
        queueRef.current.push({ id, action });
        syncQueueStateWithRef();
        return;
      }
      //In this way we handle the use case that the user tries to add the same product but with different quantity
      if (existingActionToItem !== -1) {
        const { id: existingId } = queueRef.current[existingActionToItem];
        const [existingOldAction] = existingId.split(SEPARATOR_FOR_QUEUE);
        logQueue(`Updating queue action from ${existingOldAction} to ${newAction} for product ${sku} `);
        queueRef.current[existingActionToItem] = { id, action };
      } else {
        logQueue(`Adding action ${newAction} to queue for product ${sku}`);
        queueRef.current.push({ id, action });
      }
      syncQueueStateWithRef();
    },
    [queueRef.current],
  );
  const processQueue = useCallback(async () => {
    if (isProcessingQueueRef.current || queueRef.current.length === 0) {
      return;
    }
    logQueue('Processing queue');

    isProcessingQueueRef.current = true;

    while (queueRef.current.length > 0) {
      const nextAction = queueRef.current.shift();
      try {
        logQueue(`Processing action ${nextAction.id}`);
        await nextAction.action();
      } catch (error) {
        logQueue(`Error processing action ${nextAction.id}`);
        break;
      }
    }

    if (isOnline && ogLocalStorageValues.wentOffline) {
      setValue(WENT_OFFLINE, false);
    }
    isProcessingQueueRef.current = false;

    const shouldShowToast =
      !toast.isActive('orderGuide.cart.updated') && router.asPath === routes.ORDER_GUIDE && wentOffline.current;

    if (queueRef.current.length === 0) {
      localStorage.removeItem(OFFLINE_NEW_CART_QUEUE_ID);
      if (shouldShowToast) {
        toast({
          duration: 5000,
          status: 'success',
          title: formatMessage({ id: 'orderGuide.cart.updated' }),
          icon: TOAST_ICON.success,
          id: 'orderGuide.cart.updated',
        });
        wentOffline.current = false;
      }
    }
    syncQueueStateWithRef();
  }, [queueRef.current]);

  const removeActionFromQueue = useCallback(
    (id: string) => {
      logQueue(`Removing action ${id} from queue`);
      const initialLength = queueRef.current.length;
      queueRef.current = queueRef.current.filter((item) => item.id !== id);
      syncQueueStateWithRef();
      return queueRef.current.length < initialLength;
    },
    [queueRef.current],
  );

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (shouldAddToQueue && queueRef.current.length > 0) {
        logQueue(`Existing actions in queue, ${JSON.stringify(queueRef.current)}`);
        setShowUnsavedChanges(true);
        setRedirect(url);
        router?.events.emit('routeChangeError');
        throw 'Route change aborted due to unsaved changes';
      }
    };

    router?.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router?.events.off('routeChangeStart', handleRouteChange);
    };
  }, [shouldAddToQueue, queueRef.current]);

  const redirectWithoutSaving = () => {
    if (redirect) {
      window.location.replace(redirect);
      setRedirect('');
    }
  };

  return {
    addActionToQueue,
    shouldAddToQueue,
    removeActionFromQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
  };
};
