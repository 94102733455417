import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { LOGROCKET_ID } from './constants';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LogRocket: any;
  }
}

export const logrocktIdentifyUser = (
  customerObj: Partial<Customer>,
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  superUserEmail?: string,
) => {
  const sfcBusinessUnitStores = JSON.parse(selectedBusinessUnit?.custom?.fields?.sfc_business_unit_stores || '[]');
  const ctCustomer = customerObj;
  const { shipping, billing } = getShippingAndBillingAddresses(selectedBusinessUnit);
  // Construct the identification data object
  const identificationData = {
    userId: ctCustomer.email,
    firstName: ctCustomer.firstName,
    accountName: selectedBusinessUnit?.name,
    warehouse: sfcBusinessUnitStores[0]?.StoreKey,
    createdAt: ctCustomer.createdAt,
    userShamrockId: ctCustomer.key,
    creditHold: sfcBusinessUnitStores[0]?.OnHold || false,
    shippingAddress: shipping,
    billingAddress: billing,
    accountId: selectedBusinessUnit.key,
  };

  //Make Logrocket identify call - email will be the user id
  try {
    window.LogRocket.init(LOGROCKET_ID);

    if (superUserEmail) {
      // Log superuser session
      window.LogRocket.identify(superUserEmail, {
        role: 'superuser',
        impersonating: ctCustomer.email,
      });

      // Log an event for tracking
      window.LogRocket.log('Superuser impersonating a client', {
        superUserEmail,
        clientEmail: ctCustomer.email,
      });
    }
    // Log customer session
    window.LogRocket.identify(ctCustomer.email, identificationData);
  } catch (e) {
    console.error('Logrocket identify failed', e);
  }
};

//get the shipping and billing address from the business unit
const getShippingAndBillingAddresses = (businessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  if (!businessUnit) {
    return { shipping: null, billing: null };
  }

  const shippingAddressId = businessUnit?.defaultShippingAddressId;
  const billingAddressId = businessUnit?.defaultBillingAddressId;

  const shippingAddress = businessUnit?.addresses?.find((address) => address.id === shippingAddressId);
  const billingAddress = businessUnit?.addresses?.find((address) => address.id === billingAddressId);

  if (shippingAddress && billingAddress) {
    const { company, id, title, key, ...shipping } = shippingAddress;
    const { id: billingId, title: billingTitle, key: billingKey, ...billing } = billingAddress;

    return { shipping, billing };
  } else {
    return { shipping: null, billing: null };
  }
};
