export type CalculateCasePriceFromUnitPriceType = {
  discountedPrice: number;
  currentPrice: number;
  showOriginal?: boolean;
};

export default function calculateCasePriceFromUnitPrice(pricingData, ctData): CalculateCasePriceFromUnitPriceType {
  let currentPrice = pricingData.originalPrice;
  let discountedPrice = pricingData.discountedPrice;
  if (pricingData.invoicedPrice) {
    discountedPrice = pricingData.invoicedPrice;
  }
  const isCwt = ctData?.['shamrock-standard_cwt_indicator'];
  if (isCwt) {
    const netWeight = ctData?.['shamrock-standard_net_weight'];
    discountedPrice *= netWeight;
    currentPrice *= netWeight;
  }
  return {
    discountedPrice,
    currentPrice,
  };
}
