import { useToast } from '@chakra-ui/react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { getHeadersForImpersonation } from 'composable/components/super-user/helpers/utils';
import { WarehouseProps } from 'hooks/global/use_privateUserGlobal/types';
import { format } from 'date-fns/format';
import { subDays } from 'date-fns/subDays';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { downloadFilesFromAPI } from 'helpers/downloadFilesFromAPI';
import { DocumentFormat, TypeOfDocument } from 'helpers/services/shamrock';

type queryParams = {
  downloadDocumentFormat: DocumentFormat;
  documentNumber?: string; // can be document-number (get-file-pdf), order-number (order-confirmation), order-guide-number (order-guide-inventory-sheet, order-guide-price-list, order-guide-order-sheet)
  numOfDays?: number;
  productNumber?: string;
};
type FetchAndDownloadFilesProps = {
  accessToken: string;
  storeIds: WarehouseProps;
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>;
  typeOfDocumentToDownload: TypeOfDocument;
  queryParams: queryParams;
  toast: ReturnType<typeof useToast>;
  toastMessage: string;
};

const getUrlPath = (
  selectedBuName: string,
  storeIds: WarehouseProps,
  customerNumber: string,
  typeOfDocument: string,
  queryParams: queryParams,
) => {
  let urlPathValue = '';
  let params = {
    'customer-number': customerNumber,
    'warehouse-number': storeIds.warehouseNumber,
    'business-unit-name': storeIds.businessUnit,
    'business-segment-name': storeIds.businessSegment,
  };
  let fileName = `${selectedBuName.toLowerCase().replace(' ', '-')}-`;
  switch (typeOfDocument) {
    case TypeOfDocument.GET_FILE_PDF:
      urlPathValue = 'get-file-pdf';
      params['document-type'] = queryParams.downloadDocumentFormat;
      params['document-number'] = queryParams.documentNumber;
      fileName = fileName + `get-file-pdf-${queryParams.documentNumber}`;
      break;
    case TypeOfDocument.PURCHASE_HISTORY:
      urlPathValue = 'purchase-history';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['start-date'] = format(subDays(new Date(), queryParams.numOfDays), 'yyyy-MM-dd');
      fileName = fileName + `purchase-history-past-${queryParams.numOfDays}`;
      break;
    case TypeOfDocument.ORDER_CONFIRMATION:
      urlPathValue = 'order-confirmation';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['order-number'] = queryParams.documentNumber;
      fileName = fileName + `order-confirmation-${queryParams.documentNumber}`;
      break;
    case TypeOfDocument.ORDER_GUIDE_INVENTORY_SHEET:
      urlPathValue = 'order-guide-inventory-sheet';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['order-guide-number'] = queryParams.documentNumber;
      fileName = fileName + `order-guide-inventory-sheet-${queryParams.documentNumber}`;
      break;
    case TypeOfDocument.ORDER_GUIDE_PRICE_LIST:
      urlPathValue = 'order-guide-price-list';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['order-guide-number'] = queryParams.documentNumber;
      fileName = fileName + `order-guide-price-list-${queryParams.documentNumber}`;
      break;
    case TypeOfDocument.ORDER_GUIDE_ORDER_SHEET:
      urlPathValue = 'order-guide-order-sheet';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['order-guide-number'] = queryParams.documentNumber;
      fileName = fileName + `order-guide-order-sheet-${queryParams.documentNumber}`;
      break;
    case TypeOfDocument.ACCOUNT_FINANCIAL_OVERVIEW:
      urlPathValue = 'account-financial-overview';
      params['document-format'] = queryParams.downloadDocumentFormat;
      fileName = fileName + `account-financial-overview-${customerNumber}`;
      break;
    case TypeOfDocument.PRODUCT_DATA_SHEET:
      urlPathValue = 'product-data-sheet';
      params['document-format'] = queryParams.downloadDocumentFormat;
      params['product-number'] = queryParams.productNumber;
      delete params['customer-number'];
      fileName = fileName + `product-data-sheet-${queryParams.productNumber}`;
      break;
    default:
      break;
  }

  const queryParamsToString = new URLSearchParams(params);
  return { urlPathValue, queryParamsToString, fileName };
};

export const fetchAndDownloadFiles = async ({
  accessToken,
  storeIds,
  selectedBusinessUnit,
  typeOfDocumentToDownload,
  queryParams,
  toast,
  toastMessage,
}: FetchAndDownloadFilesProps) => {
  try {
    const impersonatedHeaders = getHeadersForImpersonation();
    const { urlPathValue, queryParamsToString, fileName } = getUrlPath(
      selectedBusinessUnit.name,
      storeIds,
      selectedBusinessUnit.key,
      typeOfDocumentToDownload,
      queryParams,
    );

    // fetch the file's content from Shamrock
    const fetchResult = await fetch(`${SHAMROCK_API_URL}/documents/${urlPathValue}?${queryParamsToString}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Response-Type': 'blob',
        ...impersonatedHeaders,
      },
    });

    if (fetchResult.status !== 200 || !fetchResult.ok) {
      throw new Error('Error fetching file');
    }

    const blobResponse = await fetchResult.blob();

    // use the file content received and dowload the file in customers browser
    await downloadFilesFromAPI(blobResponse, fileName, queryParams.downloadDocumentFormat);
  } catch (error) {
    console.error('Error API call: fetchAndDownloadFiles', error);
    toast({
      title: toastMessage,
      status: 'error',
      duration: 5000,
      id: GENERIC_TOAST_ERROR_ID,
      icon: TOAST_ICON.error,
    });
    throw error;
  }
};

// export data sheet from product details page
export const fetchFilesData = async ({
  accessToken,
  storeIds,
  selectedBusinessUnit,
  typeOfDocumentToDownload,
  queryParams,
  toast,
  toastMessage,
}: FetchAndDownloadFilesProps) => {
  try {
    const impersonatedHeaders = getHeadersForImpersonation();
    const { urlPathValue, queryParamsToString } = getUrlPath(
      selectedBusinessUnit.name,
      storeIds,
      selectedBusinessUnit.key,
      typeOfDocumentToDownload,
      queryParams,
    );

    // fetch the file's content from Shamrock
    const fetchResult = await fetch(`${SHAMROCK_API_URL}/documents/${urlPathValue}?${queryParamsToString}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        ...impersonatedHeaders,
      },
    });

    if (fetchResult.status !== 200 || !fetchResult.ok) {
      throw new Error('Error fetching file');
    }

    return fetchResult;
  } catch (error) {
    console.error('Error API call: FetchFilesData', error);
    toast({
      title: toastMessage,
      status: 'error',
      duration: 5000,
      id: GENERIC_TOAST_ERROR_ID,
      icon: TOAST_ICON.error,
    });
    throw error;
  }
};
