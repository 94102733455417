import { setMinutes } from 'date-fns/setMinutes';
import { format as dateFnsTzFormat, formatInTimeZone } from 'date-fns-tz';
import {
  CurrentRefinementsConnectorParamsItem,
  CurrentRefinementsConnectorParamsRefinement,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import { HierarchicalMenuItem } from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
import { ALGOLIA_FILTERS, AlgoliaAttributes, CATEGORY_SEPARATOR, URL_ALIAS_CATEGORY } from '../constants';
import { CatalogTrackTimeType } from '../types';

const termsToIgnore = [
  ...ALGOLIA_FILTERS.filter((filter) => filter.attribute !== AlgoliaAttributes.Categories).map(
    (filter) => filter.urlAlias,
  ),
  'sort',
];

export function extractFirstParameterFromUrl(url: string) {
  const queryString = url.split('?')[1]; // Get the query string part

  if (queryString) {
    const params = new URLSearchParams(queryString);
    const firstParam = params.keys().next().value; // Get the first parameter name
    if (firstParam === URL_ALIAS_CATEGORY) {
      const paramUrl = params.get(firstParam);
      const categories = paramUrl.split(CATEGORY_SEPARATOR.trim());
      return (categories[categories.length - 1] || paramUrl).toLocaleLowerCase();
    }

    if (termsToIgnore.includes(firstParam)) {
      return -1;
    }

    const query = params.get(firstParam).replaceAll('|', ', '); // Get the value of the first parameter

    return query.length ? query.toLocaleLowerCase() : -1; // Query can also be query=""
  } else {
    return -1; // No parameters found
  }
}

export function checkForSearchQueryAndReturnText(searchQuery: string | number) {
  if (searchQuery === -1) {
    return 'category.search.resultsAll'; // if no search query is found, return all results copy
  } else {
    return 'category.search.resultsFor';
  }
}

// Formats the provided date to include the full day of the week, time, and short time zone abbreviation.
// Example output: "Tuesday 6:00 PM EDT"
export function formatDateWTimeZone(date: Date) {
  const minutes = roundDownMinutes(date.getMinutes());
  date.setMinutes(minutes);

  const updatedDate = setMinutes(date, minutes);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const tzAbbreviation = getTimezoneAbbreviation(updatedDate, timeZone);
  const formattedDate = formatInTimeZone(updatedDate, timeZone, 'EEEE h:mm a');

  return `${formattedDate} ${tzAbbreviation}`;
}

/**
 * Take the capital letters to display the abbreviation if javascript display
 * short timezone as GMT-3 or GMT+2
 * @param date Date to get the timezone abbreviation
 * @param timezone timezone to abreviate
 * @returns string Abbreviated timezone
 */
export function getTimezoneAbbreviation(date: Date, timezone: string) {
  const timezoneFullName = formatInTimeZone(date, timezone, 'zzzz');
  const jsDefaultAbbreviation = formatInTimeZone(date, timezone, 'zzz');

  return jsDefaultAbbreviation.includes('GMT') ? timezoneFullName.replace(/[^A-Z]/gs, '') : jsDefaultAbbreviation;
}

// Formats the provided date to include only the time and short time zone abbreviation (no day of the week).
// Example output: "6:00 PM EDT"
export function formatDateWTimeZoneWithoutDay(date: Date) {
  const minutes = roundDownMinutes(date.getMinutes());
  const updatedDate = setMinutes(date, minutes);
  const formattedDate = dateFnsTzFormat(updatedDate, 'h:mm a zzz', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return formattedDate;
}

export function roundDownMinutes(minutes: number) {
  if (minutes < 15) {
    return 0;
  }
  if (minutes < 30) {
    return 15;
  }
  if (minutes < 45) {
    return 30;
  }
  if (minutes <= 59) {
    return 45;
  }

  return minutes;
}

export function getTimeZone() {
  return dateFnsTzFormat(new Date(), 'zzz');
}

export const getNestedRefinedData = (items: HierarchicalMenuItem[]) => {
  for (let item of items) {
    if (item.isRefined || item.data) {
      if (item.data) {
        return getNestedRefinedData(item.data);
      } else {
        return items;
      }
    }
  }
  return items;
};

export const formatToCorrectRootPath = (query: string) => {
  return query
    .split(/([\s>]+)/)
    .map((part) =>
      part
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '),
    )
    .join('');
};

export const getRefinementsForCategory = (
  categoryRefinements: CurrentRefinementsConnectorParamsItem,
  rootPath: string,
) => {
  const generalCategoryRefinement = categoryRefinements.refinements[0].value as string;
  const parts = generalCategoryRefinement.split(CATEGORY_SEPARATOR);
  let currentValue = '';

  return parts.map((part, index) => {
    currentValue = index === 0 ? part : currentValue + CATEGORY_SEPARATOR + part;

    return {
      value: rootPath !== '' ? `${rootPath} > ${currentValue}` : currentValue,
      label: part,
      attribute: AlgoliaAttributes.Categories,
    };
  }) as CurrentRefinementsConnectorParamsRefinement[];
};

export function getCatalogLoadTime(isSearchPage: boolean): CatalogTrackTimeType {
  const key = isSearchPage ? 'timedSearch' : 'trackCatalog';
  return JSON.parse(localStorage.getItem(key) || '{}');
}

export function registerCatalogLoadTime(values: Partial<CatalogTrackTimeType>, isSearchPage: boolean) {
  const loadTime = getCatalogLoadTime(isSearchPage);
  const key = isSearchPage ? 'timedSearch' : 'trackCatalog';

  localStorage.setItem(key, JSON.stringify({ ...loadTime, ...values }));
}
