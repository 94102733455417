import routes from 'helpers/constants/routes';

export const PUBLIC_PREFIX = '/public';
export const SUPER_USER_PREFIX = '/su';
export const isPublicSlug = (slug: string) => slug.includes(PUBLIC_PREFIX);
export const isSuperUserPage = (slug: string) => slug.startsWith(SUPER_USER_PREFIX);

export const isSlugValid = (slug: string) => {
  slug = slug.toLowerCase();
  const deniedSlugs = [
    routes.HOME,
    routes.NOT_FOUND,
    routes.SUPER_USER_ACCOUNTS,
    routes.CHECKOUT_SUCCESS,
    routes.EDIT_ORDER_CHECKOUT,
  ];

  // we can't check against values inside routes object because of the marketing
  // urls like /markon that are defined by user in cofe studio.
  return !deniedSlugs.includes(slug) && !isPublicSlug(slug);
};
