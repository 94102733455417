import { useMemo } from 'react';
import { Order } from '@Types/cart/Order';
import { KEY_NAME_PICK_UP } from 'composable/components/checkout/utils/constants';
import getShippingMethodTranslationKey from 'composable/components/checkout/utils/getShippingMethodTranslationKey';
import { useFormat } from 'helpers/hooks/useFormat';
import { getPluralizedText } from '../utils/get-pluralized-text';
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils';

export const useGetCommonOrderInformation = (order: Order) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const itemsTotal = useMemo(() => {
    const items = order?.lineItems?.length;
    return getPluralizedText(items, 'items', formatMessage);
  }, [order?.lineItems?.length]);

  const casesTotal = useMemo(() => {
    const items = order?.lineItems?.reduce((acc, item) => acc + item.count, 0) ?? 0;
    return getPluralizedText(items, 'cases', formatMessage);
  }, [order?.lineItems]);

  const orderNumber = order?.orderNumber;
  const purchaseOrderNumber = order?.purchaseOrderNumber;
  const shippingMethodName = formatMessage({
    id:
      getShippingMethodTranslationKey(order?.shippingInfo?.shippingMethodName) ||
      order?.shippingInfo?.shippingMethodName,
  });
  const { total } = getCartSummaryAmounts(order);
  const isPickup = order?.shippingInfo?.shippingMethodName === KEY_NAME_PICK_UP;

  return {
    itemsTotal,
    casesTotal,
    total,
    orderNumber,
    purchaseOrderNumber,
    shippingMethodName,
    isPickup,
  };
};
