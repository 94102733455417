import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { OrderStatus } from '@Types/shamrockApi/Order';
import { getStatusBadgeColors, getStatusBadgeCopyKey } from 'composable/helpers/utils/order-utils';
import { useFormat } from 'helpers/hooks/useFormat';

export type StatusBadgeProps = {
  status: OrderStatus;
  isReturns?: boolean;
  orderNumber?: string;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, isReturns, orderNumber }: StatusBadgeProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const colors = getStatusBadgeColors(status);
  const copyKey = getStatusBadgeCopyKey({ orderStatus: status, isReturns, orderNumber });

  // if err or some other status that shouldn't display the badge
  if (!copyKey) {
    return;
  }

  return status != 'Dropship' && status != '' ? (
    <Box
      height="20px"
      width="max-content"
      padding="2px 8px"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      alignSelf="stretch"
      borderRadius="10px"
      border="1px solid"
      borderColor={colors?.border}
      backgroundColor={colors?.background}
      display="flex"
    >
      <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" textColor={colors.text}>
        {formatMessage({ id: copyKey })}
      </Text>
    </Box>
  ) : (
    <Box
      height="20px"
      width="max-content"
      padding="2px 8px"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      alignSelf="stretch"
      display="flex"
    >
      <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" textColor="neutral.700">
        -
      </Text>
    </Box>
  );
};

export default StatusBadge;
