/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { Money } from '@Types/product/Money';
import {
  analyticsTrackAddToCart,
  analyticsTrackRemoveFromCart,
  analyticsTrackUpdateCart,
} from 'composable/analytics/analytics-event-tracking';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { DRAFT_CART_ID, DraftCartValues } from 'composable/components/mini-cart/helpers';
import { OG_QTY_PERMANENT_STORAGE, useLocalStorageOrderGuide } from 'composable/components/order-guide/helpers';
import { useLocalStorage } from 'composable/helpers/hooks';
import { useFormat } from 'helpers/hooks';
import { useSubmittedCartHandler } from 'helpers/hooks/useSubmittedCartHandler';
import { useQueue } from 'hooks/useQueue';
import { CartDetails, createCart, deleteCart, getCartById, getCartsList } from 'frontastic/actions/cart';
import { cartInitialState, reduceCart } from './reduce-cart';
import { AddItemParams, CartGlobalStateActions, UseCartGlobalParams } from './types';
import {
  actionAddToCart,
  actionRemoveItem,
  actionUpdateCart,
  actionGetShippingMethods,
  actionUpdateItem,
  generateIdForQueue,
  ActionCart,
  sortCartsList,
  localStorageCartId,
  DRAFT_CART,
  actionGetCart,
  OFFLINE_NEW_CART_QUEUE_ID,
} from './utils';

export const use_privateCartGlobal = ({
  user = null,
  isPublicPage = false,
  fetchAllAccountsWithCarts,
  isOffline,
}: UseCartGlobalParams) => {
  const [state, dispatch] = useReducer(reduceCart, cartInitialState);
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const toastMessage = formatMessage({ id: 'app.generic.error' });
  const [mdCartResponseTime, setMdCartResponseTime] = useState<number>(0);
  const { ogLocalStorageValues } = useLocalStorageOrderGuide();

  const [persistedDraftCart, setpersistedDraftCart, removePersistedDraftCart] = useLocalStorage(DRAFT_CART_ID);

  const draftCart = JSON.parse(persistedDraftCart);

  const { getSelectedCartId, setSelectedCartId, removeSelectedCartId } = localStorageCartId;

  const fireGenericToastError = () => {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  };

  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const isUserLoading = user?.loading;

  const {
    addActionToQueue,
    removeActionFromQueue,
    shouldAddToQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
  } = useQueue(isOffline);

  /** @deprecated */
  const fetchCart = useCallback(
    async (cartId?: string): Promise<Cart> => {
      if (
        isUserLoading ||
        isPublicPage ||
        !user.activeAccount?.key ||
        state.cart?.cartVersion === DraftCartValues.CART_VERSION ||
        state.cart?.cartId === DraftCartValues.CART_ID
      ) {
        return;
      }

      if (draftCart) {
        await fetchAllCarts();
        dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: draftCart.cart } });
        return;
      }

      try {
        const startTime = performance.now();
        const response = await actionGetCart(
          { shamrockUserId: user?.shamrockUser?.user?.userId },
          cartId ?? getSelectedCartId() ?? undefined,
        );

        const endTime = performance.now();
        const responseTime = endTime - startTime;
        setMdCartResponseTime(responseTime);
        await fetchAllCarts(response.cartId);
        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: fetchCart', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, user?.activeAccount?.key, draftCart, state.cart?.cartId, state.cart?.cartVersion],
  );

  const getCartOrCreateDraft = useCallback(async () => {
    const cartIdFromAccountSwitch = JSON.parse(localStorage.getItem('swapCartAndAccount'));
    try {
      if (cartIdFromAccountSwitch && cartIdFromAccountSwitch.cartId) {
        localStorage.removeItem('swapCartAndAccount');
        const response = await getCartById(cartIdFromAccountSwitch.cartId);
        dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        await fetchAllCarts(response.cartId);
        return;
      }
      const cartList = await fetchAllCarts();

      const storedCartId = getSelectedCartId();
      const currentUserCart = cartList.find((cart) => {
        const userId = cart.customData?.fields?.userId ?? cart.customData?.fields?.createUserId;
        return userId === user?.shamrockUser?.user?.userId;
      });

      // If the cart stored on localSotrage is in the list, then set that cart
      if (storedCartId && cartList.find((cart) => cart.cartId === storedCartId)) {
        const cart = await getCartById(storedCartId);
        dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: cart } });
        removeSelectedCartId();
        const sortedCarts = sortCartsList(cartList, cart.cartId);
        dispatch({ type: CartGlobalStateActions.SET_CARTS_LIST, payload: { cartsList: sortedCarts } });
        return;
      }
      // If not, get a cart created by a current user
      if (currentUserCart) {
        dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: currentUserCart } });
        const sortedCarts = sortCartsList(cartList, currentUserCart.cartId);
        dispatch({ type: CartGlobalStateActions.SET_CARTS_LIST, payload: { cartsList: sortedCarts } });
        return;
      }
      // If there's no cart create by current user create a draft one
      setDraftCart();
    } catch (error) {
      console.error(error);
    }
  }, [user?.activeAccount?.key]);

  const createNewCart = useCallback(async (): Promise<Cart> => {
    try {
      const response = await createCart({
        businessUnitKey: user?.activeAccount?.key,
        shamrockUserId: user?.shamrockUser?.user?.userId,
        storeId: user.activeWarehouse?.id,
        associateId: user.ctUser?.customer?.id,
      });
      dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
      return response;
    } catch (error) {
      console.error(error);
    }
  }, [user?.shamrockUser?.user?.userId, user?.activeAccount?.key, user.activeWarehouse?.id, user.ctUser?.customer?.id]);

  const swapCarts = async (cartId: string): Promise<Cart> => {
    dispatch({ type: CartGlobalStateActions.SET_LOADING, payload: { loading: true } });
    localStorage.removeItem(OG_QTY_PERMANENT_STORAGE);
    try {
      const response = await getCartById(cartId);
      setSelectedCartId(cartId);
      dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
      await fetchAllCarts(cartId);
      removePersistedDraftCart();
      toast({
        duration: 5000,
        status: 'success',
        title: formatMessage({ id: 'cart.switcher.cart.has.been.changed' }),
        icon: TOAST_ICON.success,
      });
      return response;
    } catch (error) {
      console.error(error);
    }
    dispatch({ type: CartGlobalStateActions.SET_LOADING, payload: { loading: false } });
  };

  const setDraftCart = (): void => {
    const intialData = {
      ...DRAFT_CART,
      custom: {
        fields: { userId: user.shamrockUser.user.userId },
        type: {
          key: 'cartcustomtype',
          typeId: 'type',
        },
      },
    };
    setpersistedDraftCart(JSON.stringify({ previousCart: state.cart?.cartId, cart: intialData }));
    dispatch({
      type: CartGlobalStateActions.UPDATE,
      payload: {
        cart: intialData,
      },
    });
  };

  const fetchAllCarts = useCallback(
    async (cartIdToSort?: string): Promise<Cart[]> => {
      try {
        const response = await getCartsList({
          businessUnitKey: user.activeAccount?.key,
          associateId: user.ctUser?.customer?.id,
        });

        const sortedList = sortCartsList(response, cartIdToSort ?? getSelectedCartId() ?? state.cart?.cartId);
        dispatch({ type: CartGlobalStateActions.SET_CARTS_LIST, payload: { cartsList: sortedList } });
        return sortedList;
      } catch (error) {
        console.error('GLOBAL Cart error: fetchAllCarts', error);
      }
    },
    [isUserLoading, user?.activeAccount?.key],
  );

  const { resetSubmittedCart } = useSubmittedCartHandler(state.cart, setDraftCart, fetchAllCarts, user.activeAccount);

  const addItem = useCallback(
    async (items: AddItemParams[], cartId?: string): Promise<Cart> => {
      const payload = items.map((item) => ({
        variant: {
          sku: item.variant.sku,
          quantity: item.quantity,
          externalPrice: item.price,
          value: item.price,
        },
      }));

      const executeDraftCartAction = async () => {
        const newCart = await createNewCart();
        localStorage.setItem(OFFLINE_NEW_CART_QUEUE_ID, newCart?.cartId);
        const atcResponse = await actionAddToCart(payload, newCart?.cartId);
        dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: atcResponse } });
        await fetchAllCarts(atcResponse?.cartId);
        await fetchAllAccountsWithCarts();
        return newCart;
      };
      const executeAction = async () => {
        const isNewOfflineCart = localStorage.getItem(OFFLINE_NEW_CART_QUEUE_ID);
        const response = await actionAddToCart(payload, isNewOfflineCart ? isNewOfflineCart : cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });

        payload.forEach((item) => {
          analyticsTrackAddToCart({
            sku: item.variant.sku,
            quantity: item.variant.quantity,
            nextCart: response,
            fromPAR: ogLocalStorageValues.isParEnabled,
          });
        });

        await fetchAllCarts(response.cartId);
        return response;
      };

      try {
        if (shouldAddToQueue) {
          const skus = items.map((item) => item.variant.sku).join('');
          const idAction = generateIdForQueue(ActionCart.ADD_ITEM, skus);

          if (draftCart) {
            const newCartIdAction = generateIdForQueue(ActionCart.CREATE_CART);
            removePersistedDraftCart();
            addActionToQueue({
              id: newCartIdAction,
              action: () => executeDraftCartAction(),
            });
          } else {
            addActionToQueue({
              id: idAction,
              action: () => executeAction(),
            });
          }
          return;
        }

        const response = draftCart ? await executeDraftCartAction() : await executeAction();
        await fetchAllCarts(response.cartId);
        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: addItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue, draftCart, state.cart, state.cart?.cartId, ogLocalStorageValues.isParEnabled],
  );

  const updateCartDetails = useCallback(
    async (cartDetails: CartDetails): Promise<Cart> => {
      try {
        const payload = { ...cartDetails };
        const response = await actionUpdateCart(payload, state.cart?.cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: updateCartDetails', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading],
  );

  const removeItem = useCallback(
    async (lineItem: Partial<LineItem>, cartId?: string): Promise<Cart> => {
      const executeAction = async () => {
        const payload = {
          lineItem: { ...lineItem, id: lineItem.lineItemId },
        };

        const response = await actionRemoveItem(payload, cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        analyticsTrackRemoveFromCart({
          lineItemId: lineItem.lineItemId,
          prevCart: state.cart,
          nextCart: response,
        });

        const isEmptyCart = response.lineItems.length === 0;

        // If the user emptied the cart, we delete it and create a new Draft Cart
        if (isEmptyCart) {
          await deleteCart({ cartId: response.cartId, version: +response.cartVersion });
          setDraftCart();
          await fetchAllAccountsWithCarts();
        } else {
          dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        }

        await fetchAllCarts(isEmptyCart ? undefined : response.cartId);
        return response;
      };

      try {
        if (shouldAddToQueue) {
          const idAction = generateIdForQueue(ActionCart.REMOVE_ITEM, lineItem.lineItemId);
          addActionToQueue({
            id: idAction,
            action: () => executeAction(),
          });
          return;
        }

        const response = await executeAction();
        await fetchAllCarts();

        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: removeItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue, state.cart],
  );

  const updateItem = useCallback(
    async (lineItem: Partial<LineItem>, quantity: number, price: Money, cartId?: string): Promise<Cart> => {
      const executeAction = async () => {
        const payload = {
          lineItem: {
            id: lineItem.lineItemId,
            count: quantity,
            externalPrice: price,
          },
        };

        const response = await actionUpdateItem(payload, cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }

        analyticsTrackUpdateCart({
          lineItemId: lineItem.lineItemId,
          quantity: quantity,
          prevCart: state.cart,
          nextCart: response,
          fromPAR: ogLocalStorageValues.isParEnabled,
        });

        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        await fetchAllCarts(response.cartId);
        return response;
      };

      try {
        if (shouldAddToQueue) {
          const idAction = generateIdForQueue(ActionCart.UPDATE_ITEM, lineItem.lineItemId);
          addActionToQueue({
            id: idAction,
            action: () => executeAction(),
          });
          return;
        }

        const response = await executeAction();
        await fetchAllCarts(response.cartId);

        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: updateItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue, state.cart, ogLocalStorageValues.isParEnabled],
  );

  const fetchShippingMethods = useCallback(async () => {
    if (isUserLoading || isPublicPage || !user.activeAccount?.key) {
      return;
    }
    try {
      const methods = await actionGetShippingMethods();
      setShippingMethods(methods ?? []);
    } catch (error) {
      console.error('GLOBAL Cart error: fetchShippingMethods', error);
      fireGenericToastError();
      dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
    }
  }, [isUserLoading, user.activeAccount?.key]);

  //get shipping methods
  useEffect(() => {
    if (isUserLoading || isPublicPage || !user.activeAccount?.key) {
      return;
    }
    getCartOrCreateDraft();
    fetchShippingMethods();
  }, [isUserLoading, user.activeAccount?.key]);

  return {
    ...state,
    shippingMethods,
    dispatch,
    addItem,
    updateCartDetails,
    removeItem,
    updateItem,
    shouldAddToQueue,
    removeActionFromQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
    mdCartResponseTime,
    fetchAllCarts,
    swapCarts,
    createNewCart,
    setDraftCart,
  };
};
