import { FEATURE_FLAGS } from 'composable/components/general';

const PREFIX_QUEUE = 'Log Queue: ';

export const logQueue = (message: string) => {
  if (!FEATURE_FLAGS.QUEUE_LOGS) {
    return;
  }
  console.log(PREFIX_QUEUE + message);
};
