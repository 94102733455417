import { CheckoutGlobalActions, CheckoutGlobalType, UseCheckoutGlobalReducerActions } from './types';

export const checkoutGlobalReducer = (state: CheckoutGlobalType, { type, payload }: CheckoutGlobalActions) => {
  switch (type) {
    case UseCheckoutGlobalReducerActions.SET_ELIGIBILITY:
      return { ...state, eligibility: { ...state.eligibility, ...payload } };
    case UseCheckoutGlobalReducerActions.SET_WINDOWS:
      return { ...state, windows: { ...state.windows, ...payload } };
    case UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA:
      return { ...state, checkoutData: { ...state.checkoutData, ...payload } };
    default:
      return state;
  }
};
