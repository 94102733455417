import { format, parseISO, subDays } from 'date-fns';

type DeliveryTimeSlot = {
  isOvernight?: boolean;
  deliveryWindowStartTime?: string;
  deliveryWindowEndTime?: string;
};

type OrderDetail = {
  estimatedDeliveryDate?: string;
  estimatedDeliveryTimeSlot?: DeliveryTimeSlot;
};

export const getEstimatedDeliveryDate = (orderDetail: OrderDetail): string | null => {
  if (!orderDetail.estimatedDeliveryDate) {
    return null;
  }

  const baseDate = parseISO(orderDetail.estimatedDeliveryDate);
  const adjustedDate = orderDetail.estimatedDeliveryTimeSlot?.isOvernight ? subDays(baseDate, 1) : baseDate;

  return format(adjustedDate, 'MM/dd/yyyy');
};
