import { Flex, FlexProps, Text } from '@chakra-ui/react';

interface TextBlockProps {
  label: string;
  value: string;
  isStack?: boolean;
  flexProps?: FlexProps;
}

export const TextBlock = ({ label, value, isStack = false, flexProps }: TextBlockProps) => {
  return (
    <Flex
      flexDir={isStack ? 'column' : 'row'}
      borderTopWidth="1px"
      borderColor="neutral.100"
      fontSize="sm"
      color="neutral.600"
      paddingBlock="3px"
      {...flexProps}
    >
      <Text as="p" fontWeight="bold">
        {label}
      </Text>
      <Text as="p" marginLeft={isStack ? '0' : 'auto'}>
        {value}
      </Text>
    </Flex>
  );
};
