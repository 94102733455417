import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { algoliaInitSearchInsights } from 'composable/analytics/algolia/algolia-tracking';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import { APPLICATION_URL, TOAST_ICON } from 'composable/components/general';
import { DRAFT_CART_ID } from 'composable/components/mini-cart/helpers';
import { impersonatorIdFromStorage } from 'composable/components/super-user/helpers/utils';
import { ACCESS_TOKEN, REFRESH_ATTEMPT, REFRESH_TOKEN, SELECTED_BUSINESS_UNIT_KEY } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { getAlgoliaKey } from 'composable/helpers/utils/user-utils';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import { permission } from 'helpers/constants/permissions';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { isSlugValid, isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import {
  getBrowserAccessToken,
  getCustomerObject,
  getExpTimeFromAccessToken,
  getServerShamrockRedirectUrl,
  GetServerShamrockRedirectUrlParams,
  refreshAccessToken,
} from 'hooks/global/use_privateUserGlobal/utils';
import { useTrackLoginPage } from 'hooks/useTrackingLoginPage';
import { useSWRConfig } from 'swr';
import { fetchApiHub } from 'frontastic';
import { deleteLocalStorageValuesWithoutBu, login, getMdLogin } from 'frontastic/actions/account';
import { getAllCartsPerAccount } from 'frontastic/actions/cart';
import { reduceUser, userInitialState } from './reduce-user';
import { UserGlobalStateActions } from './types';
import { getAdditionalAzureUrlParams, selectedAccountDetails, userLogout, validateAccessToken } from './utils';

export const ADDITIONAL_AZURE_URL_PARAMS = 'state';
export const MAX_REFRESH_ATTEMPT = 2;

export const use_privateUserGlobal = ({ isPublic }) => {
  /* eslint-disable */
  const [state, dispatch] = useReducer(reduceUser, userInitialState);
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const [localStorageRefreshToken] = useLocalStorage(REFRESH_TOKEN, '');
  const activeAccountKey = typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : '';
  const toast = useToast();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const azureRedirectUrl = useRef('');
  const { cache } = useSWRConfig();
  /* eslint-enable */

  if (state.ctUser && state.activeAccount) {
    algoliaInitSearchInsights({ user: state.ctUser.customer, activeAccount: state.activeAccount });
  }

  //Super User Guard
  //eslint-disable-next-line
  useEffect(() => {
    const isSuperUserPage = isSuperUserPageCb(router.asPath);
    if (state.isSuperUser && isSuperUserPage) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }
    if (isSuperUserPage && !state.isSuperUser) {
      router.push(routes.HOME);
    }
  }, [router.asPath, state.isSuperUser]);

  const setAzureUrls = async () => {
    // Additional params to be passed to the azure redirect url
    const asPath = router.asPath.split('?')?.[0] ?? routes.HOME;
    const params: GetServerShamrockRedirectUrlParams = { redirectTo: asPath };

    try {
      azureRedirectUrl.current = getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', params);
    } catch (error) {
      console.error('Error while getting azure urls', error);
    }
  };

  //eslint-disable-next-line
  const successCallback = async (accessToken: string, clientIp: string, state: any, trackLogin: boolean = true) => {
    if (trackLogin) {
      trackShamrockUserLogin({ accessToken, clientIp });
      analyticsTrackLogin({ method: LOGIN_METHOD, customerNumber: activeAccountKey ? activeAccountKey : '' });
    }

    // Call to logrocket if logrocketid is available to identify user
    if (LOGROCKET_ID) {
      const superUserEmail = state.isSuperUser ? impersonatorIdFromStorage() : null;
      logrocktIdentifyUser(state.ctUser.customer, state.activeAccount, superUserEmail);
    }

    const accessCallback = window.localStorage.getItem(ACCESS_TOKEN);

    if (accessCallback) {
      // Extract any additional parameters from the azure redirect url
      const additionalParams = getAdditionalAzureUrlParams(azureRedirectUrl.current);

      let { code, state: queryState, redirectTo } = router.query;

      // Parse the query state if present
      const queryStateParsed = !!queryState && queryState !== 'undefined' ? JSON.parse(queryState as string) : null;
      let queryRedirectTo = queryStateParsed?.redirectTo || redirectTo;

      if (!!queryRedirectTo && queryRedirectTo.includes(routes.PUBLIC_PDP)) {
        queryRedirectTo = (queryRedirectTo as string).replace(routes.PUBLIC_PDP, routes.PDP);
      }

      let redirectToValue = queryRedirectTo ? queryRedirectTo : additionalParams.redirectTo;
      redirectToValue = isSlugValid(redirectToValue) ? redirectToValue : routes.HOME;

      // Reconstruct URL to include query params if any
      const redirectUrl = new URL(redirectToValue, window.location.origin);
      if (router.query.query) {
        redirectUrl.searchParams.set('query', router.query.query as string);
      }
      if (router.query.brand) {
        redirectUrl.searchParams.set('brand', router.query.brand as string);
      }

      // Redirect only if we have the `code` param, indicating first login
      if (code) {
        router.replace(
          {
            pathname: redirectUrl.pathname,
            query: redirectUrl.searchParams.toString() ? redirectUrl.searchParams.toString() : undefined,
          },
          undefined,
          { shallow: redirectUrl.pathname === routes.HOME },
        );
      }

      setTimeout(() => {
        dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      }, 1000);

      // Delaying appcues call after login to avoid issues while resolving redirects.
      setTimeout(() => {
        // Call to appcues if appcuesid and customer information is available
        if (APPCUES_ID) {
          appcuesIdentifyAndGroupUser(
            state.ctUser.customer,
            state.activeAccount,
            state.shamrockUser.user.permissions.data,
          );
        }
      }, 3000);
    }
  };

  //eslint-disable-next-line
  const logout = useCallback(async (hardLogout: boolean = false, message?: string) => {
    dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });

    const originPath = router.asPath;

    userLogout(hardLogout, message, originPath);

    const localStorageAccessToken = window.localStorage.getItem(ACCESS_TOKEN);
    const localStorageRefreshToken = window.localStorage.getItem(REFRESH_TOKEN);
    localStorage.removeItem(DRAFT_CART_ID);
    const refreshAttempt = parseInt(window.localStorage.getItem(REFRESH_ATTEMPT) ?? '0');
    const shouldRefresh =
      [AuthErrorKeys.SessionExpired, AuthErrorKeys.Unauthorized].includes(message as AuthErrorKeys) &&
      refreshAttempt < MAX_REFRESH_ATTEMPT;

    // if logout did not remove the access token and refresh token
    // then it might have been refreshed in the userLogout function
    if (shouldRefresh && localStorageAccessToken && localStorageRefreshToken) {
      // we try to refresh due to unauthorized 2 times, avoiding user getting
      // stuck in the login if he is really unauthorized.

      window.localStorage.setItem(REFRESH_ATTEMPT, (refreshAttempt + 1).toString());

      await setSelectedAccount(localStorageAccessToken, state.activeAccount?.key, false, localStorageRefreshToken);

      return;
    }

    if (refreshAttempt >= MAX_REFRESH_ATTEMPT) {
      deleteLocalStorageValuesWithoutBu();
      const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
      window.location.href = errorPageUrl;
    }

    await dispatch({ type: UserGlobalStateActions.LOGOUT });
  }, []);

  //eslint-disable-next-line
  const setSelectedAccount = async (
    accessToken: string,
    accountKey: string,
    shouldTrackLogin: boolean = true,
    refreshToken?: string,
    impersonatedUserId?: string,
  ) => {
    try {
      const impersonatorId = impersonatorIdFromStorage();

      if (!accountKey) {
        accountKey =
          typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : activeAccountKey;
      }

      const loginResponse = await login(accessToken, accountKey, impersonatedUserId || impersonatorId);
      if (loginResponse?.isSuperUser) {
        //We use router instead window.href.locaiton to avoid a new login call
        router.push(routes.SUPER_USER_ACCOUNTS);
        dispatch({ type: UserGlobalStateActions.SET_IS_SUPER_USER, payload: { isSuperUser: true } });
        return;
      }
      if (loginResponse.statusCode || loginResponse.error_code) {
        throw loginResponse;
      }
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
      window.localStorage.setItem(SELECTED_BUSINESS_UNIT_KEY, loginResponse?.selectedBuKey || accountKey);

      const customerObject = getCustomerObject(accessToken, loginResponse);

      const actionPayload = {
        accessToken: accessToken,
        refreshToken: refreshToken || localStorageRefreshToken,
        expiresOn: getExpTimeFromAccessToken(accessToken),
        shamrockUser: loginResponse.shamrockUser,
        ctUser: customerObject.customer,
        activeAccount: selectedAccountDetails(loginResponse.selectedBuKey, loginResponse.businessUnits),
        activeWarehouse: { ...loginResponse.store.details, id: loginResponse.store.result.id },
        accountList: loginResponse.businessUnits,
      };
      dispatch({
        type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
        payload: { loggedAsSuperUser: loginResponse.loggedAsSuperUser },
      });
      dispatch({ type: UserGlobalStateActions.LOGIN, payload: actionPayload });
      successCallback(accessToken, loginResponse.clientIp, actionPayload, shouldTrackLogin);
    } catch (error) {
      // avoid user not found getting stuck in loading
      console.warn('##DLDebug error during login process', error);

      if (error?.message) {
        try {
          const parsedError = JSON.parse(error.message);

          if (Object.values(AuthErrorKeys).includes(parsedError?.error_code)) {
            console.warn('##DLDebug error code', parsedError?.error_code);
            await logout(false, parsedError?.error_code);
          } else {
            await logout(false, AuthErrorKeys.Unauthorized);
          }
          return;
        } catch (e) {
          console.error('Error parsing login message', e);
          await logout(false, AuthErrorKeys.Unauthorized);
          return;
        }
      }

      // on login error send to error page
      logout(false, AuthErrorKeys.Unauthorized);
      return;
    }
  };

  const impersonateUser = async (accessToken: string, accountKey: string, impersonatedUserId: string) => {
    console.log('##DLDebug impersonateUser', accountKey, impersonatedUserId);
    //The reason to handle the loading state here is because we need to wait for the user to be logged in
    //before redirecting to the home page, we se again the loader since by default the setSelectedAccount function sets it to false
    dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
    await setSelectedAccount(accessToken, accountKey, true, localStorageRefreshToken, impersonatedUserId);
    dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
    router.push(routes.HOME);
    dispatch({
      type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
      payload: { loggedAsSuperUser: true },
    });
  };

  //eslint-disable-next-line
  const switchAccount = useCallback(
    async (accountKey: string) => {
      if (!accountKey) {
        return;
      }

      for (const key of cache.keys()) {
        cache.delete(key);
      }
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
      localStorage.setItem('previousAccount', activeAccountKey);
      localStorage.removeItem(DRAFT_CART_ID);
      try {
        await fetchApiHub(
          '/action/account/switchAccount',
          { method: 'POST' },
          { accessToken: state.accessToken, selectedBuKey: accountKey },
        );

        await setSelectedAccount(state.accessToken, accountKey, true, '');

        await toast({
          status: 'success',
          title: formatMessage({ id: 'switchAccount.toast.success' }),
          duration: 5000,
          icon: TOAST_ICON.success,
        });
      } catch (error) {
        console.error('Error while switching account', error);
      }
    },
    [state.accessToken],
  );

  const fetchAllAccountsWithCarts = useCallback(async () => {
    try {
      const response = await getAllCartsPerAccount({
        accountList: state.accountList,
        associateId: state.ctUser?.customer?.id,
      });
      if (response) {
        dispatch({ type: UserGlobalStateActions.SET_ACCOUNT_LIST, payload: { accountList: response } });
      }
      return response;
    } catch (error) {
      console.error('GLOBAL Cart error: fetchAllAccountsCarts', error);
    }
  }, [state.accountList]);

  useEffect(() => {
    if (state.accountList.length > 1) {
      if (state.accountList[0]?.cartsQuantity === undefined) {
        fetchAllAccountsWithCarts();
      }
    }
  }, [state.accountList]);

  // Create a callback checkIfLoggedIn and export it
  //eslint-disable-next-line
  const checkIfLoggedIn = useCallback(async () => {
    const incrementalLog: any = {
      accessTokens: {
        state: state.accessToken,
        localStorage: window.localStorage?.getItem('access_token'),
        url: window.location.search,
      },
    };
    const url = router.asPath;
    const setAzureUrlsstartTime = performance.now();
    await setAzureUrls();

    if (isPublic && !localStorageAccessToken) {
      await dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }

    // handle users redirected to redirect-from-so route
    if (router?.pathname.toLowerCase().includes(routes.REDIRECT_FROM_SO.toLowerCase())) {
      incrementalLog.redirectPage = true;
      console.warn('##DLDebug Redirecting user to login because user landed on the redirection page', incrementalLog);
      await logout(false);
      return;
    }

    let loginAccessToken = '';
    let loginRefreshToken = localStorageRefreshToken;
    let hasValidToken = false;
    let shouldTrackLogin = false;

    // check state accessToken
    if (
      state.accessToken &&
      validateAccessToken(state.accessToken, state.expiresOn) &&
      validateAccessToken(state.refreshToken)
    ) {
      // check if accesstoken is valid
      loginAccessToken = state.accessToken;
      hasValidToken = validateAccessToken(loginAccessToken);
      incrementalLog.validStateToken = true;
    }

    const setTokenStartTime = performance.now();
    // check if we received a code from the query
    if (!hasValidToken) {
      const { accessToken, refreshToken, expiresOn } = await getBrowserAccessToken(window);
      incrementalLog.getToken = {
        accessToken,
        refreshToken,
        expiresOn,
      };

      if (accessToken && refreshToken && expiresOn) {
        window.localStorage.setItem(ACCESS_TOKEN, accessToken);
        window.localStorage.setItem(REFRESH_TOKEN, refreshToken);

        // as we're using state to set the localstorage values, they might not be
        // updated in the first run, so we should update local varibles too
        loginAccessToken = accessToken;
        loginRefreshToken = refreshToken;
        hasValidToken = validateAccessToken(loginAccessToken);
        shouldTrackLogin = true;
      }
    }

    // refresh token
    // try to refresh token if we have a refresh token in local storage
    // before trying to login, so we avoid the redirect to error page.
    if (!hasValidToken && !!localStorageRefreshToken) {
      await refreshAccessToken(localStorageRefreshToken, (getTokensResponse) => {
        if (validateAccessToken(getTokensResponse.access_token)) {
          window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
          dispatch({
            type: UserGlobalStateActions.SET_ACCESS_TOKEN,
            payload: {
              accessToken: getTokensResponse.access_token,
              refreshToken: getTokensResponse.refresh_token,
              expiresOn: getTokensResponse.expires_on,
            },
          });

          loginAccessToken = getTokensResponse.access_token;
          hasValidToken = true;
        }
      });
    }

    // check localStorage accessToken
    if (
      !hasValidToken &&
      validateAccessToken(localStorageAccessToken) &&
      validateAccessToken(localStorageRefreshToken)
    ) {
      loginAccessToken = localStorageAccessToken;
      hasValidToken = true;
      incrementalLog.validLSToken = true;
    }

    if (hasValidToken) {
      const accountKey =
        // sometimes it might render without the window object so we need to double check
        typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : activeAccountKey;
      await setSelectedAccount(loginAccessToken, accountKey, shouldTrackLogin, loginRefreshToken);
    }

    if (!hasValidToken) {
      console.warn('##DLDebug Unable to login user, redirecting to login page', incrementalLog);
      logout(false, AuthErrorKeys.UserNotFound);
    }

    const setTokenEndTime = performance.now();
    const setAzureUrlsendTime = performance.now();
    const mdGetAzureUrls = setAzureUrlsendTime - setAzureUrlsstartTime;
    const mdGetTokens = setTokenEndTime - setTokenStartTime;
    const mdLogin = getMdLogin();
    const code = new URLSearchParams(window.location.search).get('code');

    if (code && shouldTrackLogin) {
      useTrackLoginPage({
        mdGetAzureUrls: mdGetAzureUrls,
        mdLogin: mdLogin,
        mdGetTokens: mdGetTokens,
      });
    }
  }, [activeAccountKey, isPublic, localStorageAccessToken, localStorageRefreshToken]);

  //eslint-disable-next-line
  const canViewProductCatalog = useCallback(() => {
    // If user get error on algoliaKey treat as permission denied
    if (!state.loading && state?.activeAccount?.key) {
      const algoliaKey = getAlgoliaKey(state.activeAccount, state.ctUser?.customer);

      if (!algoliaKey) {
        return false;
      }
    }

    return state.shamrockUser?.user?.permissions?.data?.includes(permission.CanViewProductCatalog) || state.loading;
  }, [state.shamrockUser?.user, state.loading, state.activeAccount?.key]);

  // login once we hit here.
  //eslint-disable-next-line
  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  return {
    state,
    dispatch,
    checkIfLoggedIn,
    logout,
    switchAccount,
    canViewProductCatalog,
    impersonateUser,
    azureRedirectUrl: azureRedirectUrl.current,
    successCallback,
    fetchAllAccountsWithCarts,
  };
};
