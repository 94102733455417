import { useRouter } from 'next/router';
import { useGlobal } from 'components/globalProvider';
import routes from 'helpers/constants/routes';
import { useSuperUserGuard } from './super-user/helpers/useSuperUserGuard';

/**
 * Check on _app.tsx if user has access to the page. Right now only checking for
 * catalog pages
 * @returns <></>
 */
export const PageAccessCheck = () => {
  useSuperUserGuard();
  const router = useRouter();
  const canViewProductCatalog = useGlobal().useUserGlobal.canViewProductCatalog();
  const RESTRICTED_PAGES_FOR_CATALOG_ACCESS = [routes.CATALOG, routes.SEARCH_RESULTS];

  if (
    !canViewProductCatalog &&
    RESTRICTED_PAGES_FOR_CATALOG_ACCESS.find((page: string) => router.asPath.toLowerCase().includes(page))
  ) {
    router.push(routes.HOME);
    return;
  }

  return <></>;
};
