import { BusinessUnitAddresses } from '@Types/business-unit/BusinessUnit';
import { DeliveryWindow } from 'helpers/services/shamrock';

export const transformPickupAddressToBuAddress = (
  pickupAddress: DeliveryWindow.PickupAddress | null,
): Partial<BusinessUnitAddresses> | null => {
  if (!pickupAddress) {
    return null;
  }
  return {
    company: pickupAddress.name,
    streetName: pickupAddress.address1,
    city: pickupAddress.city,
    state: pickupAddress.state,
    postalCode: pickupAddress.postalCode,
    phone: pickupAddress.primaryPhoneNumber,
  };
};
