import { FunctionComponent } from 'react';
import { Text, Image as ChakraImage, VStack, HStack } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import { useFormat } from 'helpers/hooks/useFormat';

type AttributesProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
  alertFlaggedForCasesOrdered?: boolean;
  dueDate?: Date;
  casesOrdered: number;
  allocated: number;
  invoiced?: number;
  received?: number;
  quantity?: number;
  cases?: number;
  hasCreditIssued?: boolean;
  isEditCheckout?: boolean;
};

export const Attributes: FunctionComponent<AttributesProps> = ({
  breakpointTokenToDesktop = 'md',
  alertFlaggedForCasesOrdered,
  dueDate,
  casesOrdered,
  allocated,
  invoiced,
  received,
  quantity,
  cases,
  hasCreditIssued,
  isEditCheckout,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <VStack align="flex-start" spacing={1} w="full">
      {casesOrdered !== null && (
        <HStack justify="space-between" w="full">
          <HStack spacing={1}>
            <Text
              fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
              fontWeight="medium"
              color="neutral.900"
              lineHeight="120%"
            >
              {formatMessage({
                id: isEditCheckout ? 'productCard.cases' : 'productCard.casesOrdered',
              })}
            </Text>
            {alertFlaggedForCasesOrdered && <ChakraImage width="16px" height="16px" src="/icons/info-icon.svg" />}
          </HStack>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {casesOrdered}
          </Text>
        </HStack>
      )}
      {allocated !== null && (
        <HStack justify="space-between" w="full">
          <Text
            fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
            fontWeight="medium"
            color="neutral.900"
            lineHeight="120%"
          >
            {formatMessage({
              id: 'productCard.allocated',
            })}
          </Text>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {allocated}
          </Text>
        </HStack>
      )}
      {invoiced !== null && (
        <HStack justify="space-between" w="full">
          <Text
            fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
            fontWeight="medium"
            color="neutral.900"
            lineHeight="120%"
          >
            {formatMessage({
              id: 'productCard.invoiced',
            })}
          </Text>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {invoiced}
          </Text>
        </HStack>
      )}
      {received !== null && (
        <HStack justify="space-between" w="full">
          <HStack spacing={1}>
            <Text
              fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
              fontWeight="medium"
              color="neutral.900"
              lineHeight="120%"
            >
              {formatMessage({
                id: 'productCard.received',
              })}
            </Text>
            {hasCreditIssued && <ChakraImage width="16px" height="16px" src="/icons/warning-diamond-icon.svg" />}
          </HStack>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {received}
          </Text>
        </HStack>
      )}
      {quantity !== null && (
        <HStack justify="space-between" w="full">
          <Text
            fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
            fontWeight="medium"
            color="neutral.900"
            lineHeight="120%"
          >
            {formatMessage({
              id: 'productCard.quantity',
            })}
          </Text>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {quantity}
          </Text>
        </HStack>
      )}
      {cases !== null && (
        <HStack justify="space-between" w="full">
          <Text
            fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }}
            fontWeight="medium"
            color="neutral.900"
            lineHeight="120%"
          >
            {formatMessage({
              id: 'productCard.cases',
            })}
          </Text>
          <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.900" lineHeight="120%">
            {cases}
          </Text>
        </HStack>
      )}
      {!!dueDate && isValid(new Date(dueDate)) && (
        <Text fontSize={{ base: 'sm', [breakpointTokenToDesktop]: 'base' }} color="neutral.600" lineHeight="120%">
          {formatMessage({
            id: 'productCard.dueOn',
            values: { date: format(dueDate, 'MM/dd') },
          })}
        </Text>
      )}
    </VStack>
  );
};
