import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { Order } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { format } from 'date-fns/format';
import { subDays } from 'date-fns/subDays';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { useFormat } from 'helpers/hooks';
import useSWR from 'swr';
import { getCustomerOrders } from 'frontastic/actions/shamrockApi';

type UseOrdersProps = {
  period: number;
  enable?: boolean;
};

export const useOrders = ({ period, enable = true }: UseOrdersProps) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const {
    state: { activeWarehouse, activeAccount, accessToken },
    logout,
  } = useGlobal().useUserGlobal;

  const payload = useMemo(() => {
    return {
      customerNumber: activeAccount?.key,
      warehouseNumber: activeWarehouse?.warehouseNumber,
      businessUnit: activeWarehouse?.businessUnit,
      businessSegment: activeWarehouse?.businessSegment,
      startDate: format(subDays(new Date(), period), 'yyyy-MM-dd'),
    };
  }, [activeAccount, activeWarehouse, period]);

  //TODO This fetcher should be moved to its own file
  const fetcher = async () => {
    try {
      const result = await getCustomerOrders(
        { ...payload, accessToken },
        toast,
        formatMessage({ id: 'app.generic.error' }),
      );

      if (result?.status == 401 || result?.status == 403) {
        await logout(false, AuthErrorKeys.SessionExpired);
      }

      return (result ?? []) as Order[];
    } catch (error) {
      console.error('Error API call: getCustomerOrders', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
  };


  // Use SWR hook
  const {
    data: orders,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR(null, fetcher, { ...SWR_OPTIONS, errorRetryCount: 2 });

  const isLoadingOrders = isValidating || isLoading;

  return {
    orders: orders ?? [],
    isLoadingOrders,
    error,
    ordersMutation: mutate,
  };
};
