import { ExtraProductData } from '@Types/shamrockApi/Product';
import { shouldHandleCutOffDate } from 'composable/components/product-card-v2/helpers';

type PartitionProductsByCutoffProps = {
  productsWithoutCutoff: ExtraProductData[];
  productsWithCutoff: ExtraProductData[];
};

export function partitionProductsByCutoff(extraProductData: ExtraProductData[]) {
  const { productsWithoutCutoff, productsWithCutoff } =
    extraProductData?.reduce<PartitionProductsByCutoffProps>(
      (acc, product) => {
        const handleCutOffDate = shouldHandleCutOffDate(product);
        const hasCutoff = product.companyInfo?.data?.cutoffs?.daysOfWeek?.length > 0;
        hasCutoff && handleCutOffDate ? acc.productsWithCutoff.push(product) : acc.productsWithoutCutoff.push(product);
        return acc;
      },
      { productsWithoutCutoff: [], productsWithCutoff: [] },
    ) || {};
  return { productsWithoutCutoff, productsWithCutoff };
}
