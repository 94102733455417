import { parseISO, addMinutes } from 'date-fns';
import { getTimezoneOffset, formatInTimeZone } from 'date-fns-tz';

export const adjustDeliveryDateForTimezone = (deliveryDateFixed: string, timezone: string) => {
  const date = parseISO(`${deliveryDateFixed}T00:00:00Z`);

  const timezoneOffset = getTimezoneOffset(timezone, date);

  const adjustedDate = addMinutes(date, -timezoneOffset / 60000);

  return formatInTimeZone(adjustedDate, timezone, 'MM/dd/yyyy');
};
