import { LineItem } from '@Types/cart/LineItem';
import { Product } from '@Types/product/Product';

export const createObjectId = (product: Product | LineItem, isLineItem?: boolean) => {
  if (isLineItem) {
    const lineItemTyped = product as LineItem;
    return `${lineItemTyped?.productId}.${lineItemTyped?.variant?.id}`;
  }
  const typedProduct = product as Product;
  return `${typedProduct?.id}.${typedProduct?.variants?.[0]?.id}`;
};
