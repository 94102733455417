import { createContext, useContext, useState } from 'react';
import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { useRefreshToken } from 'hooks/useRefreshToken';
import { Tastic } from 'frontastic/lib/types';
import DarkModeProvider from 'frontastic/provider/dark-mode';
import { useCloseToast } from 'hooks/useCloseToast';

export interface ComposableContextInterface {
  cartDrawer: UseDisclosureReturn;
  megaDrawer: UseDisclosureReturn;
  additionalMenuItems: Tastic[] | null;
  setAdditionalMenuItems: (payload: Tastic[]) => void;
}

const ComposableContext = createContext<ComposableContextInterface | undefined>(undefined);

export type ComposableProviderProps = Partial<ComposableContextInterface> & {
  children: JSX.Element | JSX.Element[];
  isPublic?: boolean;
};

export const ComposableProvider = ({ children }: ComposableProviderProps) => {
  useRefreshToken();
  useCloseToast();
  const {
    logout,
    state: { loading: isLoadingUser },
  } = useGlobal().useUserGlobal;
  const useCart = useGlobal().useCartGlobal;

  const cartDrawer = useDisclosure();
  const megaDrawer = useDisclosure();

  const [additionalMenuItems, setAdditionalMenuItems] = useState<Tastic[] | null>(null);

  if (useCart?.error?.statusCode === 401 && !isLoadingUser) {
    logout(false, AuthErrorKeys.SessionExpired);
  }

  return (
    <DarkModeProvider>
      <ComposableContext.Provider
        value={{
          cartDrawer,
          megaDrawer,
          additionalMenuItems,
          setAdditionalMenuItems,
        }}
      >
        {children}
      </ComposableContext.Provider>
    </DarkModeProvider>
  );
};

export const useComposable = () => {
  const context = useContext(ComposableContext);
  checkContext(context);
  return context;
};

console.warn('Deprecated provider ComposableProvider');
const checkContext = (context: ComposableContextInterface) => {
  if (!context) {
    throw new Error('Expected to be wrapped in ComposableProvider');
  }
};
