import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';
import { palette } from 'composable/chakra/theme/foundations/colors';
import NextLink from 'next/link';
import { FunctionComponent, PropsWithChildren } from 'react';

type BrandProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
  clickable?: boolean;
  textProps?: TextProps;
  boxProps?: BoxProps;
};

export const Brand: FunctionComponent<PropsWithChildren<BrandProps>> = ({
  breakpointTokenToDesktop = 'md',
  children,
  clickable,
  textProps,
  boxProps,
}) => {
  return (
    <Text
      fontSize={{ base: 'xs', [breakpointTokenToDesktop]: 'sm' }}
      color="neutral.600"
      lineHeight={{ base: '150%', [breakpointTokenToDesktop]: '120%' }}
      {...(clickable && { _hover: { color: 'primary.500' } })}
      {...textProps}
    >
      {clickable ? (
        <NextLink href={`/search?brand=${children}`} passHref>
          <Box
            as="a"
            w="100%"
            _focusVisible={{
              outlineColor: 'violet.500',
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '1px',
              boxShadow: 'none',
              borderRadius: '4px',
            }}
            _focus={{
              outlineColor: 'violet.500',
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '1px',
              boxShadow: 'none',
              borderRadius: '4px',
            }}
            {...boxProps}
          >
            {children}
          </Box>
        </NextLink>
      ) : (
        children
      )}
    </Text>
  );
};
