import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalProvider } from 'components/globalProvider';
import { OrderListProvider } from 'components/orderListProvider';
import { ComposableProvider } from 'composable';
import { initializeRUM } from 'composable/analytics/datadog/datadog';
import { GoogleTagManagerScript } from 'composable/analytics/gtm';
import { theme } from 'composable/chakra';
import { NavigationHistoryProvider } from 'composable/components/navigation-history';
import { OfflineDetector } from 'composable/components/offline-detector';
import { PageAccessCheck } from 'composable/components/PageAccessCheck';
import { isPrintBackPage, isPublicPage } from 'composable/helpers/utils/is-public-page';
import { useRouterPopState } from 'hooks/useRouterPopState';
import { appWithTranslation } from 'next-i18next';
import { PageProps } from 'frontastic';
import { SuperUserBanner } from 'composable/components/super-user/super-user-banner';

function FrontasticStarter({ Component, pageProps }: AppProps<PageProps>) {
  const isPublic = isPublicPage(pageProps?.data);
  const isPrintBack = isPrintBackPage(pageProps?.data);
  const router = useRouter();
  useRouterPopState();

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (!shallow) {
        window?.Appcues?.page();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    initializeRUM();
  }, []);

  return (
    <>
      <GoogleTagManagerScript />
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            variant: 'subtle',
            position: 'top',
            duration: 2500,
            containerStyle: {
              pointerEvents: 'none',
            },
          },
        }}
      >
        <GlobalProvider pageProps={pageProps}>
          <NavigationHistoryProvider>
            <ComposableProvider isPublic={isPublic || isPrintBack}>
              <OrderListProvider>
                <OfflineDetector />
                <SuperUserBanner isPublic={isPublic} />
                <PageAccessCheck />
                <Component {...pageProps} />
              </OrderListProvider>
            </ComposableProvider>
          </NavigationHistoryProvider>
        </GlobalProvider>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(FrontasticStarter);
