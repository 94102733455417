import { useEffect } from 'react';
import { CancelOrderStatus } from 'composable/components/order-details/order-details-page';
import { useFormat } from 'helpers/hooks/useFormat';
import { CANCEL_ORDER_STATUS } from '../cancel-order-modal';
import { useToast } from '@chakra-ui/react';
import { TOAST_ICON } from 'composable/components/general';

const TOAST_DURATION = 5000;

export const useCancelOrderStatus = (cancelOrderStatus: CancelOrderStatus) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  useEffect(() => {
    if (cancelOrderStatus === CANCEL_ORDER_STATUS.SUCCESS) {
      toast({
        duration: TOAST_DURATION,
        status: 'success',
        title: formatMessage({ id: 'cancelOrder.toast.submitted' }),
        icon: TOAST_ICON.success,
      });
    }

    if (cancelOrderStatus === CANCEL_ORDER_STATUS.FAILURE) {
      toast({
        duration: TOAST_DURATION,
        status: 'error',
        title: formatMessage({ id: 'cancelOrder.toast.error' }),
        icon: TOAST_ICON.error,
      });
    }

    if (cancelOrderStatus === CANCEL_ORDER_STATUS.TIMEOUT) {
      toast({
        duration: TOAST_DURATION,
        status: 'success',
        title: formatMessage({ id: 'cancelOrder.toast.submitted' }),
        icon: TOAST_ICON.success,
      });
    }
  }, [cancelOrderStatus]);
};
