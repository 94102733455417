import { Button, HStack, Icon, useDisclosure } from '@chakra-ui/react';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { ArrowLeft, DownloadSimple } from 'phosphor-react';
import { ExportFileModal } from './export-file-modal';
import { useRouter } from 'next/router';

type HeaderProps = {
  orderNumber: string;
};

export const Header = ({ orderNumber }: HeaderProps) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack width="full" justify="space-between">
        <Button
          size="ds-md"
          variant="ds-icon-text-link-dark"
          onClick={() => router.push(routes.ORDER_LIST_PAGE)}
          leftIcon={<Icon as={ArrowLeft} />}
        >
          {formatMessage({ id: 'order.guide.management.edit.actions.back' })}
        </Button>
        <Button size="ds-md" variant="ds-icon-text-link-dark" onClick={onOpen} leftIcon={<Icon as={DownloadSimple} />}>
          {formatMessage({ id: 'order.guide.management.edit.actions.export' })}
        </Button>
      </HStack>
      <ExportFileModal isOpen={isOpen} onClose={onClose} orderNumber={orderNumber} />
    </>
  );
};
