import { useToast } from '@chakra-ui/react';
import { Account } from '@Types/account/Account';
import { Address } from '@Types/account/Address';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { StoreResult } from '@Types/stores/Result';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { REFRESH_ATTEMPT, SELECTED_BUSINESS_UNIT_KEY } from 'composable/helpers/constants/localStorageKeys';
import useSWR, { mutate, SWRResponse } from 'swr';
import { revalidateOptions } from 'frontastic';
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { ShamrockUser } from '../shamrockApi/types';
import { WarehouseProps } from 'hooks/global/use_privateUserGlobal/types';

export interface GetAccountResult extends Omit<SWRResponse<Account>, 'data' | 'mutate'> {
  loggedIn: boolean;
  account?: Account;
  error: ResponseError;
}

export interface UpdateAccount {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDay?: number;
}

export type SwitchAccount = {
  accessToken: string;
  userId: string;
  accountKey: string;
};

export interface RegisterAccount extends UpdateAccount {
  email: string;
  password: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export type LoginResponse = {
  statusCode?: number;
  error_code?: string;
  clientIp: string;
  shamrockUser: ShamrockUser;
  commercetoolsUser: Customer;
  businessUnits: AsAssociateBusinessUnitResult[];
  store: {
    result: StoreResult;
    details: WarehouseProps;
  };
  selectedBuKey: string;
  isSuperUser?: boolean;
  loggedAsSuperUser?: boolean;
};

type TrackLoginPayload = {
  accessToken: string;
  ipAddress: string;
  platform: string;
  appVersion: string;
  browser: string;
  browserVersion: string;
  isOnCompanyNetwork: boolean;
};

/**
 * TODO where this is used?
 */
export const getAccount = (): GetAccountResult => {
  //eslint-disable-next-line
  const result = useSWR<Account | GetAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions);
  const account = (result.data as GetAccountResult)?.account || (result.data as Account);

  if (account?.accountId && account?.confirmed)
    return {
      account,
      error: result.error,
      isLoading: result.isLoading,
      isValidating: result.isValidating,
      loggedIn: true,
    };

  return {
    account: undefined,
    error: result.error,
    isLoading: result.isLoading,
    isValidating: result.isValidating,
    loggedIn: false,
  };
};

let storedMdLogin = 0;

export const login = async (
  accessToken: string,
  selectedBuKey?: string,
  impersonatedUserId?: string,
): Promise<LoginResponse> => {
  try {
    const startTime = performance.now();
    const response = (await fetchApiHub(
      '/action/account/login',
      { method: 'POST' },
      { accessToken, selectedBuKey, impersonatedUserId },
    )) as LoginResponse;

    const endTime = performance.now();
    storedMdLogin = endTime - startTime;

    return {
      ...response,
    };
  } catch (error) {
    console.error('Error API call: login', error);
    throw error;
  }
};

export const getMdLogin = () => storedMdLogin;

export const deleteLocalStorageValues = () => {
  window.localStorage.clear();
};

/**
 * Clear local storage values but keep the selected business unit key
 * also keeps origin path so user is redirected back to the link he tried to access
 */
export const deleteLocalStorageValuesWithoutBu = () => {
  const selectedBusinessUnitKey = localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY);
  const refreshAttempt = localStorage.getItem(REFRESH_ATTEMPT);
  // token is either unauthorized or invalid
  //delete local storage
  deleteLocalStorageValues();
  localStorage.setItem(SELECTED_BUSINESS_UNIT_KEY, selectedBusinessUnitKey);
  localStorage.setItem(REFRESH_ATTEMPT, refreshAttempt);
};

export const switchAccount = async (
  accessToken: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  selectedBuKey?: string,
): Promise<LoginResponse> => {
  try {
    const res = await fetchApiHub('/action/account/switchAccount', { method: 'POST' }, { accessToken, selectedBuKey });

    return res;
  } catch (error) {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const trackLogin = async (payload: TrackLoginPayload): Promise<{ data: { success: boolean } }> => {
  try {
    const res = await fetchApiHub('/action/account/trackLogin', { method: 'POST' }, payload);

    return res;
  } catch (error) {
    console.error('Error API call: trackLogin', error);
  }
};

export const logout = async (toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    const editOrder = localStorage.getItem('editOrder');
    const selectedBusinessUnitKey = localStorage.getItem('selectedBusinessUnitKey');

    //delete the local storage values
    deleteLocalStorageValues();

    if (editOrder && editOrder.length > 2) {
      localStorage.setItem('selectedBusinessUnitKey', selectedBusinessUnitKey);
    }

    await fetchApiHub('/action/account/logout', { method: 'POST' });
    return;
  } catch (error) {
    console.error('Error API call: logout', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  }
};

export const register = async (
  account: RegisterAccount,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<Account> => {
  try {
    const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account);
    return response;
  } catch (error) {
    console.error('Error API call: register', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const confirm = async (
  token: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<Account> => {
  try {
    const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
    await mutate('/action/account/getAccount', res);
    return res;
  } catch (error) {
    console.error('Error API call: confirm', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

// export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
//   const payload = {
//     email,
//     password,
//   };
//   const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
//   return res;
// };

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword });
};

// export const requestPasswordReset = async (email: string): Promise<void> => {
//   const payload = {
//     email,
//   };

//   return await fetchApiHub('/action/account/requestReset', { method: 'POST' }, payload);
// };

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword });
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account);
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

// TODO can we delete this?
export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};
