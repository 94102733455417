import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { useFormat } from 'helpers/hooks';
import { getCustomerOrderDetail } from 'frontastic/actions/shamrockApi';

type orderLoadTime = {
  orderNumber: string;
  productCount: number;
  account: string;
  warehouse: string;
  orderStatus: string;
  orderType: string;
  mdOrderDetail: number;
  mdProductList: number;
  feWork: number;
  totalTime: number;
};

export const ORDER_DETAILS_LOADTIME_INITIAL_VALUES: orderLoadTime = {
  orderNumber: '',
  productCount: 0,
  account: '',
  warehouse: '',
  orderStatus: '',
  orderType: '',
  mdOrderDetail: 0,
  mdProductList: 0,
  feWork: 0,
  totalTime: 0,
};

export const useOrderDetail = ({
  orderNumber,
  disableOnStart,
  warehouseNumber,
}: {
  orderNumber: string;
  disableOnStart?: boolean;
  warehouseNumber?: string;
}) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const {
    state: { activeWarehouse, activeAccount, accessToken },
    logout,
  } = useGlobal().useUserGlobal;
  const [orderDetail, setOrderDetail] = useState<OrderDetail | null>(null);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [refetchOrder, setRefetchOrder] = useState(false);
  const [orderLoadTime, setOrderLoadTime] = useState<orderLoadTime>({
    ...ORDER_DETAILS_LOADTIME_INITIAL_VALUES,
    orderNumber,
    warehouse: warehouseNumber,
  });

  const payload = useMemo(() => {
    return {
      orderNumber,
      accessToken: accessToken,
      warehouseNumber: warehouseNumber || activeWarehouse.warehouseNumber,
      businessUnit: activeWarehouse.businessUnit,
      businessSegment: activeWarehouse.businessSegment,
      productsIncludeNextDueDeliveries: true,
    };
  }, [
    accessToken,
    orderNumber,
    activeWarehouse.businessSegment,
    activeWarehouse.businessUnit,
    activeWarehouse.warehouseNumber,
  ]);

  const fetchCustomerOrderDetailAsync = useCallback(
    () =>
      getCustomerOrderDetail({ ...payload }, toast, formatMessage({ id: 'app.generic.error' })) as Promise<{
        orderDetail?: OrderDetail;
        status?: number;
      }>,
    [payload],
  );

  const fetchCustomerOrderDetail = useCallback(async () => {
    // Start Tracking Load Time for LogRocket

    const startTime = Date.now();
    if (accessToken && activeWarehouse.warehouseNumber && activeAccount?.key) {
      setIsLoadingOrders(true);
      try {
        const result = await fetchCustomerOrderDetailAsync();
        if (result?.orderDetail) {
          const productCount = result.orderDetail?.lineItems?.length;
          window.LogRocket?.log(`Order details received, product_count: ${productCount}`);
          setOrderDetail(result.orderDetail);
          setOrderLoadTime((prev) => ({
            ...prev,
            account: activeAccount?.key,
            orderStatus: result.orderDetail?.orderStatus,
            orderType: result.orderDetail?.orderType,
            productCount,
            mdOrderDetail: Date.now() - startTime,
          }));
        } else if (result?.status == 401 || result?.status == 403) {
          // token is either unauthorized or invalid
          await logout(false, AuthErrorKeys.SessionExpired);
          return;
        } else {
          setOrderDetail(null);
        }
      } catch (e) {
        console.error('Error API call: getCustomerOrderDetail', e);
        if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
          console.warn('Error toast shown', { reason: 'Error API call: getCustomerOrderDetail' });
          toast({
            duration: 5000,
            status: 'error',
            title: formatMessage({ id: 'app.generic.error' }),
            id: GENERIC_TOAST_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        } else {
          console.warn('Error toast suppressed', { reason: 'Error API call: getCustomerOrderDetail' });
        }
      }

      setIsLoadingOrders(false);
    }
  }, [accessToken, fetchCustomerOrderDetailAsync, logout, activeAccount?.key, activeWarehouse.warehouseNumber]);

  useEffect(() => {
    if (!disableOnStart) {
      fetchCustomerOrderDetail();
    }
  }, [disableOnStart, fetchCustomerOrderDetail]);

  useEffect(() => {
    if (refetchOrder) {
      fetchCustomerOrderDetail();
      setRefetchOrder(false);
    }
  }, [fetchCustomerOrderDetail, refetchOrder]);

  const toggleRefetchOrder = () => {
    setRefetchOrder(true);
  };

  return {
    orderDetail,
    isLoadingOrders,
    toggleRefetchOrder,
    fetchCustomerOrderDetailAsync,
    orderLoadTime,
    setOrderLoadTime,
  };
};
