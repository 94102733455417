import { DocumentFormat, DocumentFormatFileExtension } from './services/shamrock';

export const downloadFilesFromAPI = async (data, filename = 'data.txt', selectedFileFormat: DocumentFormat) => {
  try {
    const blob = new Blob([data]);
    const blobUrl = URL.createObjectURL(blob);

    // Creating a temporary link to trigger the download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${filename}.${DocumentFormatFileExtension[selectedFileFormat]}`); // Set the file name and extension

    // Append to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error: downloadFilesFromAPI', error);
  }
};
