export function getProductsMaxLeadDays(extraProductData: any[], daysForCurrentDate: boolean = false) {
  const dayOfWeek = new Date().getUTCDay();
  const productsleadDays = extraProductData?.map((product) => {
    const cutoffs = product.companyInfo?.data?.cutoffs?.daysOfWeek ?? [];
    if (cutoffs.length > 0) {
      const cutoffLeadDays = daysForCurrentDate
        ? [cutoffs.find((cutoff) => cutoff?.daysOfWeek?.includes(dayOfWeek))?.leadDays || 0]
        : cutoffs.map((cutoff) => cutoff?.leadDays || 0);
      return Math.abs(Math.max(...cutoffLeadDays));
    }
    return 0;
  });

  if (productsleadDays?.length > 0) {
    return Math.max(...productsleadDays);
  }

  return 0;
}
