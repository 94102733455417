import { OrderGuideListGlobalAction, OrderGuideListGlobalStateActions, OrderGuideListState } from './types';

export const orderGuideListInitialState: OrderGuideListState = {
  ogList: [],
  draftOgList: [],
  loading: true,
  hasUpdatedOgList: false,
};

export function reduceOrderGuideList(
  state: OrderGuideListState,
  action: OrderGuideListGlobalAction,
): OrderGuideListState {
  switch (action.type) {
    case OrderGuideListGlobalStateActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case OrderGuideListGlobalStateActions.SET_OG_LIST:
      return {
        ...state,
        ogList: action.payload.ogList,
        hasUpdatedOgList: true,
      };
    case OrderGuideListGlobalStateActions.SET_DRAFT_OG_LIST:
      return {
        ...state,
        draftOgList: action.payload.draftOgList,
        hasUpdatedOgList: true,
      };
    case OrderGuideListGlobalStateActions.SET_EMPTY_OG_LIST:
      return {
        ...state,
        ogList: [],
        draftOgList: [],
        loading: false,
      };
    default:
      return state;
  }
}
