import { FunctionComponent, PropsWithChildren } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks/useFormat';

type BasicPanelProps = PropsWithChildren<{
  title: string;
}>;

export const BasicPanel: FunctionComponent<BasicPanelProps> = ({ children, title }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const isCheckoutExceptionPanel = title === formatMessage({ id: 'checkout.step.orderImportantInformation' });

  return (
    <VStack
      align="unset"
      bg={isCheckoutExceptionPanel ? 'neutral.50' : 'white'}
      border={isCheckoutExceptionPanel ? '1px solid' : 'none'}
      borderRadius="lg"
      borderColor={isCheckoutExceptionPanel ? 'neutral.300' : 'neutral.100'}
      borderWidth={1}
      display="flex"
      overflow="clip"
      spacing={0}
    >
      <Box
        alignItems="center"
        bg="neutral.50"
        display="flex"
        p={{ base: 3, xl: 4 }}
        borderBottom={isCheckoutExceptionPanel ? '1px solid' : 'none'}
        borderColor={isCheckoutExceptionPanel ? 'neutral.200' : 'none'}
      >
        <Text color="neutral.900" fontSize="lg" fontWeight="bold" lineHeight="120%">
          {title}
        </Text>
      </Box>
      <Box p={{ base: 6, xl: 8 }}>{children}</Box>
    </VStack>
  );
};
