import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  RadioGroup,
  VStack,
  Radio,
  useToast,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { useFormat } from 'helpers/hooks/useFormat';
import { TypeOfDocument } from 'helpers/services/shamrock';
import { fetchAndDownloadFiles } from 'helpers/utils/fetch-and-download-files';
import { exportOrderConfirmationOptions } from './constants';

export type ExportFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderNumber: string;
};

export const ExportFileModal = ({ isOpen, onClose, orderNumber }: ExportFileModalProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { accessToken, activeWarehouse, activeAccount } = useGlobal().useUserGlobal.state;
  const toast = useToast();

  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadFile = async () => {
    setIsLoading(true);
    try {
      await fetchAndDownloadFiles({
        accessToken: accessToken,
        storeIds: activeWarehouse,
        selectedBusinessUnit: activeAccount,
        typeOfDocumentToDownload: TypeOfDocument.ORDER_CONFIRMATION,
        queryParams: {
          downloadDocumentFormat: selectedOption.toLowerCase(),
          documentNumber: orderNumber,
        },
        toast,
        toastMessage: formatMessage({ id: 'app.generic.error' }),
      });
      onClose();
      setSelectedOption(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!isLoading) {
          setSelectedOption(null);
          onClose();
        }
      }}
      closeOnOverlayClick={false}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent marginInline="10px" padding={{ base: '20px', sm: '32px' }}>
        <ModalHeader p={0} fontSize={{ base: '20px', sm: '28px' }} fontWeight="bold" lineHeight="120%">
          {formatMessage({ id: 'exportModal.title' })}
        </ModalHeader>
        <ModalCloseButton isDisabled={isLoading} transform={'translateY(6px)'} />
        <ModalBody p={0}>
          {/* Text and Radio Buttons */}
          <Text as="p" fontWeight="bold" pt="10" pb="3">
            {formatMessage({ id: 'exportModal.text' })}
          </Text>
          <RadioGroup
            aria-label=""
            paddingBottom="10"
            onChange={(nextValue) => setSelectedOption(nextValue)}
            value={selectedOption}
          >
            <VStack align="unset" spacing="14px">
              {exportOrderConfirmationOptions.map((option, index) => (
                <Radio
                  key={index}
                  size="md"
                  value={option}
                  _focus={{
                    outline: '-webkit-focus-ring-color auto 1px',
                  }}
                >
                  <Text as="p" fontWeight="400">
                    {option}
                  </Text>
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        </ModalBody>

        {/* Export Button */}
        <ModalFooter p={0}>
          <Button
            isDisabled={!selectedOption || isLoading}
            onClick={handleDownloadFile}
            isLoading={isLoading}
            size="ds-lg"
            variant="ds-filled"
          >
            {formatMessage({ id: 'exportModal.button.export' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
