import { useEffect, useState } from 'react';

export const useFocusQuantityPicker = (componentId: string) => {
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const checkFocus = () => {
      const element = document.getElementById(componentId);
      element && document.activeElement === element ? setIsFocused(true) : setIsFocused(false);
    };

    const interval = setInterval(checkFocus, 100);

    document.addEventListener('focusin', checkFocus);
    document.addEventListener('focusout', checkFocus);

    checkFocus();

    return () => {
      clearInterval(interval);
      document.removeEventListener('focusin', checkFocus);
      document.removeEventListener('focusout', checkFocus);
    };
  }, [componentId]);
  return {
    isFocused,
  };
};
