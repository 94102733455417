import { AlgoliaFilterItem, CatalogTrackTimeType } from './types';

export enum AlgoliaAttributes {
  Categories = 'categories.en.lvl0',
  ShamrockExclusive = 'attributes.shamrock-standard_shamrock_brand',
  Brand = 'attributes.shamrock-standard_brand_long_description.en',
  Storage = 'attributes.shamrock-standard_storage.en',
  Features = 'attributes.shamrock-standard_features.en',
  PackSize = 'attributes.shamrock-standard_pack_size',
  CategoryKeys = 'categoryKeys.en',
}

export const DEFAULT_SORTBY = 'Default';
export const DEFAULT_INITIAL_REFINEMENTS_LIMIT = 20;
export const DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT = 50;
export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? '';
export const CATEGORY_KEY = AlgoliaAttributes.CategoryKeys; // Must match what is in Algolia
export const CATEGORY_NAME = 'categories.en.lvl';
export const ALL_CATEGORIES = 'all';
export const URL_ALIAS_CATEGORY = 'categories';
export const CATEGORY_SEPARATOR = ' > ';

export const ALGOLIA_FILTERS: AlgoliaFilterItem[] = [
  {
    type: 'list',
    attribute: AlgoliaAttributes.Categories,
    urlAlias: URL_ALIAS_CATEGORY,
    translationKey: 'category.refinements.categories',
  },
  {
    type: 'toggle',
    attribute: AlgoliaAttributes.ShamrockExclusive,
    urlAlias: 'shamrock_exclusive',
    translationKey: 'category.refinements.shamrockExclusive',
  },
  {
    type: 'list',
    attribute: AlgoliaAttributes.Brand,
    urlAlias: 'brand',
    translationKey: 'category.refinements.brand',
  },
  {
    type: 'list',
    attribute: AlgoliaAttributes.Storage,
    urlAlias: 'storage',
    translationKey: 'category.refinements.storage',
  },
  {
    type: 'list',
    attribute: AlgoliaAttributes.Features,
    urlAlias: 'features',
    translationKey: 'category.refinements.productfeatures',
  },
  {
    type: 'list',
    attribute: AlgoliaAttributes.PackSize,
    urlAlias: 'pack_size',
    translationKey: 'category.refinements.packSize',
    sortBy: ['count:desc', 'name:asc'],
  },
];

export const CATEGORY_SEARCH_FIELDS = ['categories'];

export const ALGOLIA_INDEX_LIST = [
  { label: 'Default', value: 'Default' },
  { label: 'Name: A to Z', value: 'name-ascending' },
  { label: 'Name: Z to A', value: 'name-descending' },
];

export const ALGOLIA_LOCALES = ['en', 'fr'];
export const DEFAULT_ALGOLIA_LOCALE = 'en';
export const CURRENT_ALGOLIA_LOCALE = 'en';

export const SELLER_FILTER_FIELD = 'attributes.normalized.seller';

export const DEFAULT_RANKING_OPTIONS = ['typo', 'geo', 'words', 'filters', 'proximity', 'attribute', 'exact'];

export const PRIMARY_INDEX_SETTINGS = {
  attributesForFaceting: [
    AlgoliaAttributes.Categories,
    AlgoliaAttributes.Brand,
    AlgoliaAttributes.Storage,
    AlgoliaAttributes.Features,
    AlgoliaAttributes.PackSize,
  ],
  searchableAttributes: [AlgoliaAttributes.Brand, 'name.en'],
  ranking: [...DEFAULT_RANKING_OPTIONS, 'desc(timestampCreated)'],
};

export const REPLICAS = [
  {
    name: 'name-ascending',
    ranking: 'asc(name)',
  },
  {
    name: 'name-descending',
    ranking: 'desc(name)',
  },
];

export const PROMOTED_FILTERS_COLORS = {
  'Fresh Produce': 'primary.700',
  'Meat, Poultry & Seafood': '#F0B323',
  'Dairy & Eggs': '#008648',
  Grocery: 'red.700',
  'Breads, Bakery & Desserts': 'linear-gradient(0deg, #7C0959 0%, #7C0959 100%), #5F0744',
  'Apps, Entrees, Soups, & Sides': '#BD4D1E',
  'Canned & Frozen Produce': 'purple.500',
  Beverages: '#9F5C2F',
  Disposables: 'linear-gradient(0deg, rgba(0, 51, 158, 0.50) 0%, rgba(0, 51, 158, 0.50) 100%), #002269',
  'Equipment & Supplies': 'neutral.600',
};

export const HIERARCHICAL_CATEGORIES = [
  'categories.en.lvl0',
  'categories.en.lvl1',
  'categories.en.lvl2',
  'categories.en.lvl3',
];

export const CATALOG_TRACK_TIME_INITIAL_VALUES: CatalogTrackTimeType = {
  account: '',
  warehouse: '',
  algoliaQuery: 0,
  mdExtraProductData: 0,
  feWork: 0,
  totalTime: 0,
  startTime: 0,
};
