/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { orderGuidesByCustomerNumbers } from 'composable/components/order-guide-edit/components/utils/fetchers';
import { OrderGuideMultipleCustomers } from 'composable/components/order-guide-management';
import {
  SORTS_KEYS_OGM,
  useLocalStorageOrderGuideManagement,
} from 'composable/components/order-guide-management/components/helpers';
import { KEY_ALL_ACCOUNTS } from 'composable/helpers/hooks';
import { getAccountOptions } from 'composable/helpers/utils/account-utils';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { useFormat } from 'helpers/hooks';
import every from 'lodash/every';
import values from 'lodash/values';
import { orderGuideListInitialState, reduceOrderGuideList } from './reduce-order-guide-list';
import { OrderGuideListGlobalStateActions, UseOrderGuideListGlobalResponse } from './types';
import { UseUserGlobalResponse } from '../use_privateUserGlobal/types';
import { useRouter } from 'next/router';
import routes from 'helpers/constants/routes';

const URL_ORDER_GUIDES_BY_CUSTOMER_NUMBERS = `${SHAMROCK_API_URL}/order-guides/by-customer-numbers`;

export const use_privateOrderGuideListGlobal = (
  useUserGlobal: UseUserGlobalResponse,
  avoidFetch: boolean, // we should not fetch OG data on public pages or printback page
): UseOrderGuideListGlobalResponse => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reduceOrderGuideList, orderGuideListInitialState);
  const { hasUpdatedOgList } = state;
  const { formatMessage } = useFormat({ name: 'common' });
  const {
    state: { loading: isLoadingUserData, activeWarehouse, accessToken, activeAccount, accountList },
    logout,
  } = useUserGlobal;
  const responseTimeRef = useRef<number | null>(null);

  const accountOptions = getAccountOptions(accountList, formatMessage);

  const {
    ogmLocalStorageValues: { selectedSortOptionOrderGuideManagement },
  } = useLocalStorageOrderGuideManagement();

  const allOrderGuidesInfo = (allOrderGuides: OrderGuideMultipleCustomers[], includeDrafts: boolean) => {
    if (avoidFetch || !allOrderGuides?.length) {
      dispatch({ type: OrderGuideListGlobalStateActions.SET_EMPTY_OG_LIST });
    }

    let ogList = allOrderGuides;

    const selectedSortOption = selectedSortOptionOrderGuideManagement.selectedSort;

    if ([SORTS_KEYS_OGM.asc, SORTS_KEYS_OGM.desc].includes(selectedSortOption) && allOrderGuides) {
      ogList = allOrderGuides.sort((a, b) => {
        if (selectedSortOption === SORTS_KEYS_OGM.asc) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    }

    if (
      [SORTS_KEYS_OGM.highestItemCount, SORTS_KEYS_OGM.lowestItemCount].includes(selectedSortOption) &&
      allOrderGuides
    ) {
      ogList = allOrderGuides.sort((a, b) => {
        if (selectedSortOption === SORTS_KEYS_OGM.highestItemCount) {
          return b?.productCount - a?.productCount;
        } else {
          return a?.productCount - b?.productCount;
        }
      });
    }

    if (includeDrafts) {
      dispatch({
        type: OrderGuideListGlobalStateActions.SET_DRAFT_OG_LIST,
        payload: { draftOgList: ogList ?? [], loading: true },
      });
    } else {
      dispatch({
        type: OrderGuideListGlobalStateActions.SET_OG_LIST,
        payload: { ogList: ogList ?? [], loading: true },
      });
    }
  };

  const listOrderGuides = useCallback(
    async (includeDrafts: boolean) => {
      let payload = {};

      if (
        avoidFetch ||
        isLoadingUserData ||
        !activeWarehouse ||
        !accessToken ||
        !activeAccount ||
        Object.keys(accountOptions).length === 0
      ) {
        payload = {};
      } else {
        payload = {
          warehouseNumber: activeWarehouse.warehouseNumber,
          businessUnitName: activeWarehouse.businessUnit,
          businessSegmentName: activeWarehouse.businessSegment,
          includeDrafts: includeDrafts,
          customerNumbers: Object.keys(accountOptions).filter((key) => key !== KEY_ALL_ACCOUNTS),
        };
      }

      const isValidPayload =
        Object.keys(payload).length > 0 &&
        every(values(payload), (val) => val !== '' && !(Array.isArray(val) && Array.from(val).length === 0));

      if (!isValidPayload) {
        dispatch({ type: OrderGuideListGlobalStateActions.SET_EMPTY_OG_LIST });
        return;
      }

      const startTime = Date.now();
      const allOrderGuides = await orderGuidesByCustomerNumbers([
        URL_ORDER_GUIDES_BY_CUSTOMER_NUMBERS,
        { payload, accessToken, logout },
      ]);
      const endTime = Date.now();
      responseTimeRef.current = endTime - startTime;

      if (allOrderGuides) {
        allOrderGuidesInfo(allOrderGuides, includeDrafts);
      }
    },
    [activeAccount, activeWarehouse, isLoadingUserData, avoidFetch, accessToken, accountOptions],
  );

  useEffect(() => {
    if (router.asPath === routes.ORDER_GUIDE_MANAGEMENT) {
      return;
    }

    if (!isLoadingUserData && activeAccount?.key) {
      listOrderGuides(false);
    }
  }, [isLoadingUserData, activeAccount?.key, activeWarehouse]);

  useEffect(() => {
    if (hasUpdatedOgList) {
      dispatch({
        type: OrderGuideListGlobalStateActions.SET_LOADING,
        payload: { loading: false },
      });
    }
  }, [hasUpdatedOgList]);

  return { state, dispatch, listOrderGuides, responseTime: responseTimeRef.current };
};
