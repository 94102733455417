import { Address } from '@Types/account/Address';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { CustomFields } from '@Types/cart/CustomFields';
import { useGlobal } from 'components/globalProvider';
import { KEY_SCHEDULED_DELIVERY } from 'composable/components/checkout/utils/constants';
import { UserDataType } from 'hooks/global/use_privateCheckoutGlobal/types';

export type UnitStore = {
  StoreKey: string;
  OnHold: boolean;
  Status: string;
  IsPrimaryForSfcBusinessUnit: boolean;
  PaymentTerms: string;
};

export const useUserHelpers = (user?: UserDataType) => {
  const {
    state: { activeAccount, activeWarehouse, ctUser },
  } = user ? user : useGlobal().useUserGlobal;

  // Get Custom fields
  const getCustomFields = ({
    deliveryDate,
    specialHandlingInstructions,
    selectedShippingMethod,
    geocode,
  }: {
    deliveryDate: string;
    specialHandlingInstructions?: string;
    selectedShippingMethod?: string;
    geocode?: string;
  }) => {
    const selectedDeliveryDate = deliveryDate;

    const custom: CustomFields = {
      userId: ctUser?.customer?.key || 'N/A',
      customerNumber: activeAccount?.key || 'N/A',
      businessUnitName: activeWarehouse?.businessUnit || 'N/A',
      businessSegmentName: activeWarehouse?.businessSegment || 'N/A',
      warehouseNumber: activeWarehouse?.warehouseNumber || 'N/A',
      requestDate: selectedDeliveryDate || 'N/A',
      geocode: selectedShippingMethod === KEY_SCHEDULED_DELIVERY ? '' : geocode,
      selectedDeliveryMethod: selectedShippingMethod,
      specialHandlingInstructions,
    };
    return custom;
  };

  // Find shipping address by id from business unit
  const getShippingAddress = (): Address => {
    if (!activeAccount || !activeAccount?.defaultShippingAddressId) return null;

    const shippingAddressId = activeAccount?.defaultShippingAddressId;

    const shippingAddress = activeAccount?.addresses.find((address) => address.id === shippingAddressId);

    const { company, id, title, key, ...shipping } = shippingAddress;

    return shipping;
  };

  // Find billing address by id from business unit
  const getBillingAddress = (): Address => {
    if (!activeAccount || !activeAccount?.defaultBillingAddressId) return null;

    const billingAddressId = activeAccount?.defaultBillingAddressId;

    const billingAddress = activeAccount?.addresses.find((address) => address.id === billingAddressId);

    const { id, title, key, ...billing } = billingAddress;

    return billing;
  };

  // Get Email from user
  const getCustomerEmail = () => {
    const customerEmail = ctUser?.customer?.email || null;

    return { email: customerEmail };
  };

  // Get Name from user
  const getCustomerName = () => {
    return {
      firstName: ctUser?.customer?.firstName || null,
      lastName: ctUser?.customer?.lastName || null,
    };
  };

  // Get Company name
  const getCompanyName = () => {
    if (!activeAccount || !activeAccount?.defaultBillingAddressId) return null;

    const billingAddressId = activeAccount?.defaultBillingAddressId;

    const billingAddress = activeAccount?.addresses.find((address) => address.id === billingAddressId);

    return billingAddress.company || null;
  };

  // Get Company name from shipping
  const getCompanyNameShipping = () => {
    if (!activeAccount || !activeAccount?.defaultShippingAddressId) return null;

    const shippingAddressId = activeAccount?.defaultShippingAddressId;

    const shippingAddress = activeAccount?.addresses.find((address) => address.id === shippingAddressId);

    const { company, id, title, key, ...shipping } = shippingAddress;

    return shippingAddress.company || null;
  };

  const getUnitStore = (): UnitStore | undefined => {
    try {
      const unitStores = JSON.parse(activeAccount?.custom?.fields?.sfc_business_unit_stores) as UnitStore[];

      const storeKey = `${activeWarehouse.businessUnit}_${activeWarehouse.businessSegment}_${activeWarehouse.warehouseNumber}`;

      return unitStores.find((unit) => unit.StoreKey.toLowerCase() === storeKey.toLowerCase());
    } catch (error) {}
  };

  const getUnitStoreFromBusinessUnit = (businessUnit: AsAssociateBusinessUnitResult): UnitStore | undefined => {
    try {
      const unitStores = JSON.parse(businessUnit?.custom?.fields?.sfc_business_unit_stores) as UnitStore[];

      const storeKey = `${activeWarehouse.businessUnit}_${activeWarehouse.businessSegment}_${activeWarehouse.warehouseNumber}`;

      return unitStores.find((unit) => unit.StoreKey.toLowerCase() === storeKey.toLowerCase());
    } catch (error) {}
  };

  return {
    getShippingAddress,
    getBillingAddress,
    getCustomerEmail,
    getCustomerName,
    getCompanyName,
    getCustomFields,
    getCompanyNameShipping,
    getUnitStore,
    getUnitStoreFromBusinessUnit,
  };
};
