import { createRef } from 'react';
import { getDeliveryCutoff } from 'composable/components/product-card-v2/helpers';
import { EditOrderCutoffTimeModalElement } from './EditOrderCutoffTimeModal';
import { EditOrderValidationModalElement } from './EditOrderValidationModal';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { DeliveryDatesProps } from 'composable/helpers/hooks';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { isBefore } from 'date-fns';

export const refEditOrderCutoffTimeModal = createRef<EditOrderCutoffTimeModalElement>();
export const refEditOrderValidationModal = createRef<EditOrderValidationModalElement>();

export function isCutoffDateBeforeToday(cutoffDate: Date): boolean {
  const today = new Date();
  return isBefore(cutoffDate, today);
}

export const isPastCutoffTime = (
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  deliveryDates: DeliveryDatesProps,
  extendedData: ExtraProductData,
) => {
  if (deliveryDates?.dates?.length) {
    const delivery = getDeliveryCutoff(extendedData, selectedBusinessUnit, deliveryDates.dates[0]);
    if (delivery && delivery?.cutoffDate) {
      return isCutoffDateBeforeToday(delivery.cutoffDate);
    }
  }
  return false;
};

export const sleep = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));
