import { Container, ContainerProps } from '@chakra-ui/react';
import { LAYOUT_VALUES } from 'composable/components/pdp/constants';

interface ContentContainerProps {
  children: React.ReactElement | React.ReactElement[];
  containerProps?: ContainerProps;
}

export const ContentContainer = ({ containerProps, children }: ContentContainerProps) => {
  const { PADDING_LEFT, PADDING_RIGHT } = LAYOUT_VALUES;

  return (
    <Container
      maxW="container.full"
      pr={PADDING_RIGHT}
      pl={PADDING_LEFT}
      m={0}
      justifyContent="flex-start"
      {...containerProps}
    >
      {children}
    </Container>
  );
};
