import { BusinessUnitAddresses } from '@Types/business-unit/BusinessUnit';
import { StringHelpers } from 'helpers/stringHelpers';

export const formatStreetLine = (address: BusinessUnitAddresses): string => {
  const number = address?.streetNumber ?? '';
  const street = address?.streetName ? StringHelpers.capitaliseEveryWord(address?.streetName) : '';
  const apartment = address?.apartment ? StringHelpers.capitaliseEveryWord(address?.apartment) : '';
  const building = address?.building ? StringHelpers.capitaliseEveryWord(address?.building) : '';
  return `${number} ${street} ${apartment} ${building}`.replace(/\s\s+/g, ' ').trim();
};

export const formatCityStateLine = (address: BusinessUnitAddresses): string => {
  const city = address?.city ? StringHelpers.capitaliseEveryWord(address?.city) : '';
  let state = address?.state ?? '';
  state = state.length === 2 ? state.toUpperCase() : StringHelpers.capitaliseEveryWord(state);
  const postalCode = address?.postalCode ?? '';

  return `${city}, ${state} ${postalCode}`.replace(/\s\s+/g, ' ').trim();
};

export const formatFirstLastName = (address: BusinessUnitAddresses): string => {
  const first = address?.firstName ? StringHelpers.capitaliseEveryWord(address?.firstName) : '';
  const last = address?.lastName ? StringHelpers.capitaliseEveryWord(address?.lastName) : '';
  return `${first} ${last}`.replace(/\s\s+/g, ' ').trim();
};
