import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { formatCityStateLine, formatStreetLine } from 'helpers/utils/addressFormatter';

export const sortBusinessUnitsAndInsertCurrentFirst = (
  businessUnits: AsAssociateBusinessUnitResult[],
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
) => {
  const sortedBusinessUnits = [...businessUnits]
    .sort((a, b) => {
      // Convert both names to lowercase for case-insensitive sorting
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      // Use localeCompare for alphanumeric sorting
      return nameA.localeCompare(nameB);
    })
    .filter((unit) => unit.key !== selectedBusinessUnit.key);

  const findUnit = businessUnits.find((unit) => unit.key === selectedBusinessUnit.key);

  if (findUnit) {
    sortedBusinessUnits.unshift(findUnit);
  }

  return sortedBusinessUnits;
};

export const findUnitAddress = (addresses: AsAssociateBusinessUnitResult['addresses'], shippingId: string) => {
  const address = addresses.find((address) => address.id === shippingId);

  if (address) {
    const addressInfo = {
      streetName: formatStreetLine(address),
      city: formatCityStateLine(address),
    };
    return `${addressInfo.streetName}, ${addressInfo.city}`;
  }
  return '';
};

export const filterBusinessUnits = (
  searchValue: string,
  businessUnits: AsAssociateBusinessUnitResult[],
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
) => {
  if (!searchValue) {
    return businessUnits;
  }
  const filteredUnits = businessUnits.filter((unit) => {
    return (
      unit.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      unit.key.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  const sortedBusinessUnits = sortBusinessUnitsAndInsertCurrentFirst(filteredUnits, selectedBusinessUnit);
  return sortedBusinessUnits;
};

export const capitalizeText = (text) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const DRAFT_CART_ID = 'draftCart';

export enum DraftCartValues {
  CART_ID = 'draft',
  CART_VERSION = 'draft',
}
