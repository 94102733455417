import { FunctionComponent } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Address } from '@Types/account/Address';
import { Order } from '@Types/cart/Order';
import * as ProductCardV2 from 'composable/components/product-card-v2';
import { getProductPrice } from 'composable/components/product-card-v2/helpers';
import { ProductAttributeKeys } from 'composable/components/types';
import { useGetCommonOrderInformation } from 'composable/helpers/hooks/useGetCommonOrderInformation';
import { getAttribute } from 'composable/helpers/utils/attribute-utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocaleCode } from 'helpers/hooks/useLocaleCode';
import { useProductExtraData } from 'helpers/hooks/useProductExtraData';
import { DeliveryTo } from '../parts/delivery-to';
import { formatOrderTotal } from '../parts/order-summary';
import { TextBlock } from '../parts/text-block';
import { formatDeliveryDate } from '../success/checkout-success-page';

type SplitOrderSummaryProps = {
  order: Order;
  label: string;
  timeWindow?: string;
  warehouseAddress?: Partial<Address>;
};

export const SplitOrderSummary: FunctionComponent<SplitOrderSummaryProps> = ({
  order,
  label,
  timeWindow,
  warehouseAddress,
}) => {
  const commonFlexProps = {
    borderColor: 'neutral.700',
    color: 'white',
    padding: 0,
    paddingY: 2,
  };
  const locale = useLocaleCode();
  const { formatMessage } = useFormat({ name: 'common' });
  const { itemsTotal, casesTotal, total, purchaseOrderNumber, shippingMethodName, isPickup } =
    useGetCommonOrderInformation(order);

  const cartLineItems = order?.lineItems ?? [];
  const { extraProductData } = useProductExtraData([...cartLineItems?.map((item) => item?.variant?.sku)]);

  return (
    <Box>
      <Flex flexDir={{ base: 'column', md: 'row' }} gap={5}>
        {/* Order Summary */}
        <Box width="100%">
          <Accordion allowToggle defaultIndex={1}>
            <AccordionItem bg="white" border={0} borderRadius="lg">
              <VStack
                align="unset"
                bg="neutral.900"
                border="1px"
                borderRadius="lg"
                spacing={0}
                borderColor="neutral.700"
                boxShadow="0px 4px 10px 0px rgba(0, 0, 0, 0.20)"
              >
                <HStack
                  align="flex-end"
                  bg="neutral.800"
                  borderTopRadius="lg"
                  borderBottomWidth={1}
                  borderBottomColor="neutral.700"
                  justify="space-between"
                  p={{ base: 3, md: 4 }}
                >
                  <Text color="white" fontSize={{ base: 'sm', md: 'lg' }} fontWeight="bold" lineHeight="120%">
                    {formatMessage({
                      id: 'checkout.success.order',
                      values: { orderNumber: label === 'principalOrder' ? '1' : '2' },
                    })}
                  </Text>
                  <AccordionButton
                    p="unset"
                    w="unset"
                    color="white"
                    _hover={{ color: 'primary.500' }}
                    _focus={{
                      outline: '-webkit-focus-ring-color auto 1px',
                    }}
                  />
                </HStack>
                <VStack align="unset" p={{ base: 6, md: 8 }} spacing={4}>
                  {/* Order Details */}
                  <Flex flexDirection={{ base: 'column', md: 'row' }} gap={{ base: 0, md: 10 }} align="flex-start">
                    <Box flexGrow="1" width="full">
                      <HStack spacing={2} mb={2}>
                        {[itemsTotal, '|', casesTotal].map((item) => (
                          <Text color="white" fontSize="sm" key={item} lineHeight="150%">
                            {item}
                          </Text>
                        ))}
                      </HStack>
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.poNumber' })}
                        value={purchaseOrderNumber || '-'}
                        flexProps={{ ...commonFlexProps }}
                      />
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.type' })}
                        value={shippingMethodName}
                        flexProps={{ ...commonFlexProps }}
                      />
                      <TextBlock
                        label={formatMessage({ id: 'orderSucess.textBlock.submitted' })}
                        value={formatDeliveryDate(order?.createdAt)}
                        flexProps={{ ...commonFlexProps }}
                      />
                      <Box>
                        <TextBlock
                          label={formatMessage({ id: 'orderSucess.textBlock.estimated' })}
                          value={`${formatDeliveryDate(order?.custom?.fields?.requestDate)}${timeWindow ? ',' : ''}`}
                          flexProps={{ ...commonFlexProps }}
                        />
                        {timeWindow && (
                          <Text color="white" align="right" fontSize="sm" mt={-2} mb={2}>
                            {timeWindow}
                          </Text>
                        )}
                      </Box>

                      <Divider mb={2} borderColor="neutral.700" display={{ base: 'block', md: 'none' }} />
                    </Box>
                    {/* Deliver Information */}
                    <Box color="white" flexGrow="1" width="full">
                      <Box fontSize="sm">
                        <Text fontWeight="bold" lineHeight="150%">
                          {formatMessage({ id: 'orderSuccess.textBlock.instructions' })}
                        </Text>
                        <Text lineHeight="150%">{order?.custom?.fields?.specialHandlingInstructions || '-'}</Text>
                      </Box>
                      <Divider my={2} borderColor="neutral.700" />
                      <Box color="inherit !important">
                        <Text mb={2} fontWeight="bold" fontSize="sm">
                          {formatMessage({ id: !isPickup ? 'orderDetails.deliverTo' : 'orderDetails.pickUpAt' })}
                        </Text>
                        <DeliveryTo
                          stackProps={{ color: 'white', fontSize: 'sm', fontWeight: 'normal' }}
                          lineHeightFirstText="150%"
                          warehouseAddress={warehouseAddress}
                        />
                      </Box>
                    </Box>
                  </Flex>
                  <Divider borderColor="neutral.700" />
                  <HStack align="center" justify="space-between">
                    <Text color="white" fontSize="base" fontWeight="bold" lineHeight="120%">
                      {formatMessage({ id: 'cart.summary.estimatedTotal' })}
                    </Text>
                    <Text color="white" fontSize="base" fontWeight="bold" lineHeight="120%">
                      {total ? formatOrderTotal(total) : '$0.00'}
                    </Text>
                  </HStack>
                </VStack>
              </VStack>

              <AccordionPanel border={0} p={4}>
                {/* Mapping for Checkout Page */}
                <VStack align="unset" spacing={3}>
                  {cartLineItems.map((item, idx) => {
                    const brand = getAttribute(
                      item?.variant?.attributes['shamrock-standard_brand_long_description'],
                      locale,
                    );
                    const { currentPrice, discountedPrice, productPrice } = getProductPrice({
                      attributes: item?.variant?.attributes,
                      extraProductData: extraProductData?.find(
                        (product) => product?.productNumber === item.variant.sku,
                      ),
                    });
                    return (
                      <ProductCardV2.CheckoutVariant
                        //This is only to see Cases instead Quantity in the checkout success page
                        index={idx}
                        key={item.lineItemId + label || idx}
                        brand={brand}
                        id={item?.variant?.sku || ''}
                        name={item?.name || ''}
                        packSize={
                          item?.variant?.attributes ? item?.variant?.attributes[ProductAttributeKeys.PackSize] : ''
                        }
                        sku={item?.variant?.sku || ''}
                        quantityFromOrder={item ? item.count : 0}
                        currentPrice={currentPrice}
                        discountedPrice={discountedPrice}
                        productPrice={productPrice}
                        extraProductData={extraProductData?.find(
                          (product) => product?.productNumber === item.variant.sku,
                        )}
                      />
                    );
                  })}

                  <AccordionButton
                    alignSelf="flex-end"
                    p="unset"
                    w="unset"
                    color="neutral.900"
                    _hover={{ color: 'primary.600' }}
                  ></AccordionButton>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Flex>
    </Box>
  );
};
