import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useFormat } from 'helpers/hooks';
import { CreditRequest, postCreditRequest } from 'helpers/services/shamrock/postCreditRequest';
import { fileToBase64, splitFileString } from 'helpers/utils/files';
import { getAccountInfoByCustomerNumber } from 'helpers/components/accountsOverviewHelper';

export interface ExtraCardData {
  name: string;
  brand: string;
  weightMeasurement: string;
}

export interface LineItemWithMappedFields {
  // Add more fields here if needed
  name: string;
  brand: string;
  quantity: number;
  weight: number;
  sku: string;
  weightMeasurement: string;
  id: number;
}

export const ADDITIONAL_PHOTOS_KEY = 'additional-photos';

export interface CreditRequestAttachments {
  [key: number]: { [key in number | typeof ADDITIONAL_PHOTOS_KEY]: File };
}
interface CreditRequestType {
  lineItems: LineItemWithMappedFields[];
  selectedLineItem: {
    item: LineItemWithMappedFields | null;
    id: number | null;
  };
  casesToReturn: number | null;
  whatIsWrong: string | null;
  issueWithProduct?: string | null;
  notes: string | null;
  attachments: CreditRequestAttachments;
}

interface StepperActions {
  goToNext: () => void;
  goToPrevious: () => void;
  onClose: () => void;
}
export interface CreditRequestContextType {
  creditRequest: CreditRequestType;
  stepperFunctions: StepperActions;
  setStepperFunctions: React.Dispatch<React.SetStateAction<StepperActions>>;
  resetValues: () => void;
  updateCreditRequest: (valuesToUpdate: Partial<CreditRequestType>) => void;
  submitRequest: () => Promise<boolean>;
}

const CreditRequestContext = createContext<CreditRequestContextType | undefined>(undefined);

type CreditRequestProviderProps = PropsWithChildren<{
  customerNumber: string;
  orderNumber: string;
}>;

export function CreditRequestProvider({ children, customerNumber, orderNumber }: CreditRequestProviderProps) {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const [creditRequest, setCreditRequest] = useState({
    lineItems: [],
    selectedLineItem: {
      item: null,
      id: null,
    },
    casesToReturn: null,
    whatIsWrong: null,
    issueWithProduct: null,
    notes: null,
    attachments: null,
  });

  const [stepperFunctions, setStepperFunctions] = useState(undefined);

  const resetValues = () => {
    setCreditRequest((prev) => ({
      ...prev,
      selectedLineItem: { item: null, id: null },
      casesToReturn: null,
      whatIsWrong: null,
      issueWithProduct: null,
      notes: null,
      attachments: null,
    }));
  };

  const updateCreditRequest = (valuesToUpdate: Partial<CreditRequestType>) => {
    setCreditRequest((prev) => ({
      ...prev,
      ...valuesToUpdate,
    }));
  };

  const { accessToken, accountList } = useGlobal().useUserGlobal.state;

  const submitRequest = async () => {
    try {
      const attachments: CreditRequest.Attachment[] = [];

      for (let i = 1; i <= creditRequest.casesToReturn; i++) {
        const files = creditRequest.attachments[i];

        for (let c = 0; c < files.length; c++) {
          const file = files[c];

          if (file) {
            const [fileName, extension] = file.name.split('.');

            const fileTextBase64 = await fileToBase64(file);

            attachments.push({
              fileTextBase64: splitFileString(fileTextBase64),
              extension,
              fileName,
            });
          }
        }
      }

      for (let file of creditRequest.attachments[ADDITIONAL_PHOTOS_KEY]) {
        if (file) {
          const [fileName, extension] = file.name.split('.');

          const fileTextBase64 = await fileToBase64(file);

          attachments.push({
            fileTextBase64: splitFileString(fileTextBase64),
            extension,
            fileName,
          });
        }
      }

      const payload: CreditRequest.Payload = {
        productNumber: creditRequest.selectedLineItem.item.sku,
        quantity: creditRequest.casesToReturn,
        creditReason: creditRequest.whatIsWrong,
        issueWithProductReason: creditRequest.issueWithProduct,
        comments: creditRequest.notes,
        attachments,
      };

      const { warehouseNumber, businessSegmentName, businessUnitName } = getAccountInfoByCustomerNumber(
        accountList,
        customerNumber,
      );

      const isSuccess = await postCreditRequest({
        accessToken,
        customerNumber,
        orderNumber,
        warehouseNumber,
        businessUnitName,
        businessSegmentName,
        payload,
        toast,
        toastMessage: formatMessage({ id: 'app.generic.error' }),
      });

      return isSuccess;
    } catch (e) {
      console.error('Error API call: submitCreditRequest', e);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          icon: TOAST_ICON.error,
          id: GENERIC_TOAST_ERROR_ID,
        });
      }

      return false;
    }
  };

  return (
    <CreditRequestContext.Provider
      value={{
        creditRequest,
        stepperFunctions,
        setStepperFunctions,
        resetValues,
        updateCreditRequest,
        submitRequest,
      }}
    >
      {children}
    </CreditRequestContext.Provider>
  );
}

export function useCreditRequestContext(): CreditRequestContextType {
  const context = useContext(CreditRequestContext);
  if (!context) {
    throw new Error('useCreditRequestContext must be used within a CreditRequestContextProvider');
  }
  return context;
}
