import { VStack, StackProps, Text, TextProps, HStack } from '@chakra-ui/react';
import { isSubmitted } from 'composable/helpers/utils/order-status';
import { PageVariant } from './types';

interface PriceProps {
  priceProps?: TextProps;
  regularPrice: string;
  rootProps?: StackProps;
  specialPrice?: string;
  specialPriceProps?: TextProps;
  pageVariant: PageVariant;
  orderStatus?: string;
}

export const Price = (props: PriceProps) => {
  const { priceProps, regularPrice, rootProps, specialPrice, specialPriceProps, pageVariant, orderStatus } = props;
  if (!regularPrice) return null;

  switch (pageVariant) {
    case PageVariant.TrendingItems:
      if (specialPrice != regularPrice) {
        return (
          <>
            <Text aria-label={specialPrice} {...priceProps}>
              {specialPrice}
            </Text>
            <Text aria-label={regularPrice} {...specialPriceProps}>
              {regularPrice}
            </Text>
          </>
        );
      } else {
        return (
          <Text aria-label={specialPrice} {...priceProps}>
            {specialPrice}
          </Text>
        );
      }
    case PageVariant.PDP:
      if (specialPrice != regularPrice) {
        return (
          <HStack spacing="1" {...rootProps}>
            <Text aria-label={specialPrice} {...priceProps}>
              {specialPrice}
            </Text>
            <Text aria-label={regularPrice} {...specialPriceProps}>
              {regularPrice}
            </Text>
          </HStack>
        );
      } else {
        return (
          <HStack spacing="1" {...rootProps}>
            <Text {...priceProps} aria-label={specialPrice} color={'white'}>
              {specialPrice}
            </Text>
          </HStack>
        );
      }
    case PageVariant.OrderDetails:
      if (isSubmitted(orderStatus || '') && specialPrice != regularPrice) {
        return (
          <VStack spacing="1" {...rootProps} alignItems="left">
            <Text aria-label={specialPrice} {...priceProps}>
              {specialPrice}
            </Text>
            <Text aria-label={regularPrice} {...specialPriceProps}>
              {regularPrice}
            </Text>
          </VStack>
        );
      } else {
        return (
          <VStack spacing="1" {...rootProps} alignItems="left">
            <Text {...priceProps} aria-label={specialPrice} color={'neutral.900'}>
              {specialPrice}
            </Text>
          </VStack>
        );
      }
    case PageVariant.Checkout:
      return (
        <VStack spacing="1" {...rootProps} alignItems="left">
          <Text {...priceProps} aria-label={specialPrice} color={'neutral.900'}>
            {specialPrice}
          </Text>
        </VStack>
      );
    case PageVariant.OrderEdit:
    case PageVariant.Cart:
    case PageVariant.OrderGuide:
    case PageVariant.PLP:
    case PageVariant.OrderGuideEdit:
      if (specialPrice != regularPrice) {
        return (
          <VStack spacing="1" alignItems="left" {...rootProps}>
            <Text aria-label={specialPrice} {...priceProps}>
              {specialPrice}
            </Text>
            <Text aria-label={regularPrice} {...specialPriceProps}>
              {regularPrice}
            </Text>
          </VStack>
        );
      } else {
        return (
          <VStack spacing="1" alignItems="left" {...rootProps}>
            <Text {...priceProps} aria-label={specialPrice} color={'neutral.900'}>
              {specialPrice}
            </Text>
          </VStack>
        );
      }
    default:
      return null;
  }
};
