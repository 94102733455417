import { useLayoutEffect, useState } from 'react';
import { useIsBrowser } from './useIsBrowser';

export type ElementSize = {
  width: number;
  height: number;
};

export const useGetElementSize = (elementId: string, skipLayoutEffect: boolean = false) => {
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });
  const isBrowser = useIsBrowser();

  useLayoutEffect(() => {
    if (!isBrowser || skipLayoutEffect) {
      return;
    }

    const checkElementSize = () => {
      const element = document.getElementById(elementId);
      if (element && element.offsetHeight > 0 && element.offsetWidth > 0) {
        setSize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });

        const resizeObserver = new ResizeObserver(() => {
          setSize({
            width: element.offsetWidth,
            height: element.offsetHeight,
          });
        });
        resizeObserver.observe(element);

        clearInterval(intervalId);
        return () => resizeObserver.disconnect();
      }
    };

    const intervalId = setInterval(checkElementSize, 100);

    return () => clearInterval(intervalId);
  }, [isBrowser, skipLayoutEffect]);

  return size;
};
