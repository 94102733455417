import { FunctionComponent, PropsWithChildren } from 'react';
import { Skeleton, SkeletonProps } from '@chakra-ui/react';

type CustomSkeletonProps = SkeletonProps;

export const CustomSkeleton: FunctionComponent<PropsWithChildren<CustomSkeletonProps>> = ({
  children,
  isLoaded,
  ...rest
}) => {
  return (
    <Skeleton isLoaded={isLoaded} startColor="neutral.700" endColor="neutral.600" borderRadius="2px" {...rest}>
      {children}
    </Skeleton>
  );
};
