import { useMemo } from 'react';
import { AsAssociateBusinessUnitResult, DeliverySchedule, DeliveryPlan } from '@Types/business-unit/BusinessUnit';
import { useGlobal } from 'components/globalProvider';
import { FEATURE_FLAGS } from 'composable/components/general';
import { addHours, differenceInDays, format, isSameDay, subDays } from 'date-fns';
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { FormatMessageParams } from 'helpers/hooks';
import { SoftCutoffsResponse } from 'helpers/services/shamrock';
import { DELIVERY_DATES_CONFIG } from '../constants/deliveryDates';

export interface RemainingDays {
  days: number;
  hours: number;
  minutes: number;
  orderByDate: Date;
}

export type DeliveryDatesProps = {
  dates: Date[];
  orderBy: RemainingDays;
};

export interface UseGetDeliveryDatesReturnType {
  deliveryDates: DeliveryDatesProps;
  renderRemainingDays: (
    remainingDays: RemainingDays,
    formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
  ) => string;
  loading: boolean;
}

let current = new Date();

const { limitDays, extraWeekLimit } = DELIVERY_DATES_CONFIG;

// use map to get the name of the day as softcutoffs api brings day in weekname
const weekdaysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDateWithCutoff = (date: Date, leadDays: number, cutoffTime?: string): Date | number => {
  if (!cutoffTime || !date) {
    return 0;
  }
  // Get date portion to combine with cutoff
  const stringDate = date.toISOString().split('T')[0];
  // Combine date portion with cutoff
  const result = new Date(`${stringDate}T${cutoffTime}.000Z`);
  // Get the days diff between the date and the cutoff
  const daysDiff = differenceInCalendarDays(date, result);
  // Apply the leadDays minus the existing diff to avoid miscalculating when the UTC date moves to another day
  return subDays(result, Math.abs(leadDays) - daysDiff);
};

export const calculateUpcomingDeliveryDates = (
  plan: DeliveryPlan,
  isoString: string,
  count: number = 3,
  productsLeadDays: number = 0,
  extendedCutoffISOString?: string,
  softCutoffs?: SoftCutoffsResponse,
  lastModifiedPlan?: string,
  isSplitOrder?: boolean,
): DeliveryDatesProps => {
  let currentDate = new Date(isoString);
  if (currentDate) {
    currentDate.setDate(currentDate.getUTCDate());
  }
  const upcomingDeliveryDates: Date[] = [];

  // Reset time for currentDate
  current.setHours(0, 0, 0);

  const hasGeocodeDeliveryDate = FEATURE_FLAGS.GEOCODE_CUTOFF && !!extendedCutoffISOString;
  const geocodeDeliveryDate = hasGeocodeDeliveryDate ? new Date(extendedCutoffISOString) : null;

  if (!!geocodeDeliveryDate) {
    // avoid date switching from UTC to local time
    geocodeDeliveryDate.setDate(geocodeDeliveryDate.getUTCDate());
  }

  plan?.DeliverySchedules.forEach((schedule) => {
    // Ensure currentDate is not before the StartDateTime
    // If geocodeDeliveryDate is provided, use that as the startDateTime
    const startDateTime = hasGeocodeDeliveryDate ? geocodeDeliveryDate : new Date(schedule.StartDateTime);

    const fallbackAnchor = lastModifiedPlan ? new Date(lastModifiedPlan) : null;
    const anchorDate = !!schedule?.AnchorDateTime ? new Date(schedule?.AnchorDateTime) : fallbackAnchor;

    if (currentDate < startDateTime) {
      currentDate.setTime(startDateTime.getTime());
      currentDate.setHours(0, 0, 0);
    }

    const endDateTime = !!schedule.EndDateTime ? new Date(schedule.EndDateTime) : null;

    let loopCount = 0;
    const limitRun = 60;

    while (loopCount < limitRun) {
      loopCount++;
      if (endDateTime && isAfter(currentDate, endDateTime)) {
        break;
      }

      if (!schedule?.CutoffTime) {
        break;
      }

      // Using modulo 7 to ensure it falls within the range of 0-6, where 0 represents Sunday.
      const localDay = +format(currentDate, 'i') % 7;
      if (schedule.DeliveryDays.includes(localDay)) {
        let leadDays = hasGeocodeDeliveryDate ? 0 : schedule.LeadDays;
        let scheduleCutoff = schedule.CutoffTime;

        if (FEATURE_FLAGS.SOFT_CUTOFF && !!softCutoffs) {
          const softCutoffInfo = softCutoffs?.dailyCutoffDetails?.find(
            (cutoff) => cutoff.dayOfWeek === weekdaysName[currentDate.getUTCDay()],
          );

          const validSoftCutoffTime = !!softCutoffInfo?.softCutoffTime
            ? softCutoffInfo?.softCutoffTime
            : softCutoffs?.primaryCutoffTime;

          if (!!validSoftCutoffTime) {
            // check earliest cutoff

            const splitCutoffTime = validSoftCutoffTime.split(':');
            const splitScheduleTime = scheduleCutoff.split(':');

            const softCutoffTime = new Date();
            softCutoffTime.setHours(
              parseInt(splitCutoffTime[0]),
              parseInt(splitCutoffTime[1]),
              parseInt(splitCutoffTime[2]),
            );

            const scheduledTime = new Date();
            scheduledTime.setHours(
              parseInt(splitScheduleTime[0]),
              parseInt(splitScheduleTime[1]),
              parseInt(splitScheduleTime[2]),
            );

            // soft cutoff is before the regular cutoff, we use the soft cutoff

            if (isBefore(softCutoffTime, scheduledTime)) {
              leadDays = softCutoffInfo.leadDays;
            }
          }
        }

        // if it is the geocodeCutoffDate day, we ignore leadDays
        if (hasGeocodeDeliveryDate && currentDate.getUTCDate() === geocodeDeliveryDate.getUTCDate()) {
          leadDays = 0;
        } else if (productsLeadDays > leadDays) {
          leadDays = productsLeadDays;
        }

        let dateWCutoff = getDateWithCutoff(currentDate, leadDays, scheduleCutoff) as Date;

        if (dateWCutoff && anchorDate && FEATURE_FLAGS.DST_ADJUSTMENTS) {
          dateWCutoff = handleDST(anchorDate, dateWCutoff);
        }

        const difference = differenceInDays(currentDate, new Date());
        const shouldConsiderDifference = !isSplitOrder && difference > limitDays;
        const differenceAgainstFirstDate =
          upcomingDeliveryDates.length > 0 ? differenceInDays(currentDate, upcomingDeliveryDates[0]) : 0;
        const shouldReturnEarly =
          FEATURE_FLAGS.EXTRA_DELIVERY_DATES &&
          (shouldConsiderDifference || differenceAgainstFirstDate > extraWeekLimit);
        if (shouldReturnEarly) {
          break;
        }

        if (upcomingDeliveryDates.length >= count) {
          break;
        }

        const currentWTime = new Date();

        const isCurrentBeforeCutoff = isBefore(currentWTime, dateWCutoff);

        if (isCurrentBeforeCutoff) {
          const stringDate = format(currentDate, 'yyyy-MM-dd');
          // using 12 so it is always in the expected day in CT
          const currentDateWithTime = new Date(`${stringDate}T12:00:00.000Z`);
          upcomingDeliveryDates.push(currentDateWithTime); // Push the raw Date object
        }
      }

      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
  });

  // Sort the upcoming delivery dates in ascending order
  upcomingDeliveryDates.sort((a, b) => a.getTime() - b.getTime());

  const orderBy = getOrderByValues(upcomingDeliveryDates[0], plan, geocodeDeliveryDate, softCutoffs, lastModifiedPlan);

  return {
    dates: upcomingDeliveryDates,
    orderBy,
  };
};

const getOrderByValues = (
  date: Date,
  plan: DeliveryPlan,
  geocodeDeliveryDate?: Date,
  softCutoffs?: SoftCutoffsResponse,
  lastModifiedPlan?: string,
): RemainingDays => {
  if (!date) {
    return {} as RemainingDays;
  }
  const dayOfWeekNumber = date.getUTCDay();

  const hasGeocodeDeliveryDate = FEATURE_FLAGS.GEOCODE_CUTOFF && !!geocodeDeliveryDate;
  let matchingSchedule: DeliverySchedule = null;

  // find the schedule that matches the first date
  plan?.DeliverySchedules.forEach((schedule) => {
    if (schedule.DeliveryDays.includes(dayOfWeekNumber)) {
      matchingSchedule = schedule;
      return;
    }
  });

  if (matchingSchedule && !!matchingSchedule?.CutoffTime) {
    let hasSoftCutoff = false;
    let softCutoffInfo = null;
    if (!!softCutoffs) {
      softCutoffInfo = softCutoffs?.dailyCutoffDetails?.find(
        (cutoff) => cutoff.dayOfWeek === weekdaysName[dayOfWeekNumber],
      );
      hasSoftCutoff = !!softCutoffInfo || !!softCutoffs?.primaryCutoffTime;
    }

    let leadDays = matchingSchedule.LeadDays || 0;
    const currentWTime = new Date();

    // remove lead days to allow same day delivery with geocodeCutoof
    const shouldRemoveLeadDays =
      hasGeocodeDeliveryDate &&
      geocodeDeliveryDate.getUTCDate() <= date.getUTCDate() &&
      date.getUTCDate() == currentWTime.getUTCDate();

    if (shouldRemoveLeadDays) {
      leadDays = 0;
    }

    const cutoffTime = plan?.DeliverySchedules[0]?.CutoffTime;

    let updatedDate = getDateWithCutoff(
      date,
      leadDays,
      matchingSchedule?.CutoffTime ? matchingSchedule.CutoffTime : cutoffTime,
    ) as Date;

    // if we have a softcutoff and it is before the actual cutoff we display it
    // if the softcutoff is after the current time and before the regular cutoff, we don't display it
    if (FEATURE_FLAGS.SOFT_CUTOFF && hasSoftCutoff) {
      const validSoftCutoffTime = !!softCutoffInfo?.softCutoffTime
        ? softCutoffInfo?.softCutoffTime
        : softCutoffs?.primaryCutoffTime;
      if (!!validSoftCutoffTime) {
        let softCutoffDate = getDateWithCutoff(date, softCutoffInfo.leadDays, validSoftCutoffTime) as Date;

        // if current time is between soft cutoff and regular cutoff, we don't display an orderBy
        if (isAfter(currentWTime, softCutoffDate) && isBefore(currentWTime, updatedDate)) {
          return {} as RemainingDays;
        }

        // soft cutoff is always applied if leadDays is 2 or more
        // soft cutoff is before current time and before regular cutoff, we display it
        if (leadDays >= 2 || (isAfter(softCutoffDate, currentWTime) && isBefore(softCutoffDate, updatedDate))) {
          updatedDate = softCutoffDate;
        }
      }
    }

    // handle DST change in updated date
    const fallbackAnchor = lastModifiedPlan ? new Date(lastModifiedPlan) : null;
    const anchorDate = !!matchingSchedule?.AnchorDateTime ? new Date(matchingSchedule?.AnchorDateTime) : fallbackAnchor;

    if (updatedDate && anchorDate && FEATURE_FLAGS.DST_ADJUSTMENTS) {
      updatedDate = handleDST(anchorDate, updatedDate);
    }

    const isGeocodeDeliveryDate = hasGeocodeDeliveryDate && isSameDay(geocodeDeliveryDate, updatedDate);

    // if this is a geocode delivery date, we need to calculate the cutoff time
    // based on the geocode delivery date
    if (isGeocodeDeliveryDate && isAfter(currentWTime, updatedDate)) {
      return getGeocodeOrderByValues(currentWTime, updatedDate, geocodeDeliveryDate);
    }

    // Calculate the time difference
    const timeDifference = differenceInMinutes(updatedDate, currentWTime);

    const orderByValues = calculateTimeRemaining(timeDifference);

    // only display orderBy if date is greater than current date
    // if geocodeCutoff is before current date, we don't display an orderBy
    const isGreaterThanCurrent = isAfter(updatedDate, currentWTime);

    return {
      ...orderByValues,
      orderByDate: isGreaterThanCurrent ? (updatedDate as Date) : null,
    };
  } else {
    return {} as RemainingDays;
  }
};

function getGeocodeOrderByValues(currentWTime: Date, updatedDate: Date, geocodeDeliveryDate: Date): RemainingDays {
  // current date is greater than regular cutoff time, we just don't show an order by
  if (isAfter(currentWTime, updatedDate)) {
    return {} as RemainingDays;
  }

  const geocodeCutoffTime = geocodeDeliveryDate.toISOString().split('T')[1].split('.')[0];
  const geocodeUpdatedDate = getDateWithCutoff(geocodeDeliveryDate, 0, geocodeCutoffTime) as Date;

  let earliestDate = isAfter(geocodeUpdatedDate, updatedDate) ? updatedDate : geocodeUpdatedDate;

  // Calculate the time difference
  const geocodeTimeDifference = differenceInMinutes(earliestDate, currentWTime);

  const geocodeOrderByValues = calculateTimeRemaining(geocodeTimeDifference);

  return {
    ...geocodeOrderByValues,
    orderByDate: isAfter(earliestDate, currentWTime) ? (earliestDate as Date) : null,
  };
}

export function calculateTimeRemaining(minutes: number) {
  if (!minutes || minutes < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
    };
  }

  // Calculate the number of days, hours, and minutes
  const days = Math.floor(minutes / (24 * 60)); // Calculate the number of days
  const remainingMinutes = minutes % (24 * 60); // Calculate the remaining minutes after extracting days
  const hours = Math.floor(remainingMinutes / 60); // Calculate the number of hours
  const finalMinutes = remainingMinutes % 60; // Calculate the final minutes

  // Return the result as an object
  return {
    days,
    hours,
    minutes: finalMinutes,
  };
}

export const getPlanByStoreKey = (selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  const deliveryPlans = selectedBusinessUnit?.custom?.fields?.sfc_business_unit_delivery_plans;

  const plans: DeliveryPlan[] = deliveryPlans ? JSON.parse(deliveryPlans) : null;

  const storeKey = selectedBusinessUnit?.stores ? selectedBusinessUnit.stores[0]?.key : null;

  if (plans && storeKey) {
    const filteredPlans = plans.filter((plan) => plan.StoreKey === storeKey);
    return filteredPlans;
  }
};

export const renderRemainingDays = (
  remainingDays: RemainingDays,
  formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
) => {
  const { days, hours, minutes } = remainingDays;

  if (days) {
    return formatMessage({
      id: hours > 0 ? 'orderBy.text.days' : 'orderBy.text.days.noHours',
      values: {
        days,
        hours: hours > 0 ? hours : undefined,
      },
    });
  } else if (hours) {
    return formatMessage({
      id: minutes > 0 ? 'orderBy.text.hours' : 'orderBy.text.hours.noMinutes',
      values: {
        hours,
        minutes: minutes > 0 ? minutes : undefined,
      },
    });
  } else if (minutes) {
    const min = minutes === 0 ? 1 : minutes;
    return formatMessage({
      id: 'orderBy.text.minutes',
      values: {
        minutes: min,
      },
    });
  }
};

const initialDeliveryDates = {
  dates: [],
  orderBy: {},
} as DeliveryDatesProps;

const { count } = DELIVERY_DATES_CONFIG;

// Get Delivery Dates and orderBy text from business unit
export const getActiveAccountDeliveryDates = ({
  activeAccount,
  leadDays = 0,
  geocodeDeliveryDate = null,
  softCutoffs = null,
}: {
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  leadDays?: number;
  geocodeDeliveryDate?: string;
  softCutoffs?: SoftCutoffsResponse;
}) => {
  const utcString = current.toISOString();

  const plans = getPlanByStoreKey(activeAccount);
  const lastModifiedPlan = activeAccount?.lastModifiedAt;

  return plans?.[0]?.DeliverySchedules?.[0].DeliveryDays.length > 0
    ? calculateUpcomingDeliveryDates(
        plans[0],
        utcString,
        count,
        leadDays,
        geocodeDeliveryDate,
        softCutoffs,
        lastModifiedPlan,
      )
    : initialDeliveryDates;
};

export const useGetDeliveryDates = (leadDays = 0): UseGetDeliveryDatesReturnType => {
  const { activeAccount } = useGlobal().useUserGlobal.state;
  const { softCutoffs, loading: isLoadingCutoffs } = useGlobal().useCutoffsGlobal;
  const { extendedCutoff: geocodeDeliveryDate } = useGlobal().useCutoffsGlobal;

  const deliveryDates = useMemo(() => {
    if (isLoadingCutoffs || !activeAccount?.key) {
      return null;
    }
    return getActiveAccountDeliveryDates({ activeAccount, leadDays, geocodeDeliveryDate, softCutoffs }) || null;
  }, [activeAccount, activeAccount?.key, isLoadingCutoffs, leadDays, geocodeDeliveryDate, softCutoffs]);

  return { deliveryDates, renderRemainingDays, loading: isLoadingCutoffs };
};

// get first sunday of current month
export const getFirstSunday = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const day = firstDay.getDay();

  if (day === 0) {
    return firstDay;
  }

  const diff = 7 - day;
  const sundayDay = firstDay.getDate() + diff;
  return new Date(firstDay.setDate(sundayDay));
};

// get second sunday of current month
export const getSecondSunday = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const day = firstDay.getDay();

  const diff = day === 0 ? 7 - day : 0;
  const secondSundayDay = firstDay.getDate() + diff + 7;
  return new Date(firstDay.setDate(secondSundayDay));
};

export const getPlanDeliveryDaysByAccount = (activeAccount: Partial<AsAssociateBusinessUnitResult>) => {
  const plans = getPlanByStoreKey(activeAccount);
  return plans?.[0]?.DeliverySchedules?.[0]?.DeliveryDays;
};

/**
 * Reference https://oriumhq.jira.com/browse/SHAM-2528
 */
function handleDST(anchorDate: Date, cutoffTime: Date) {
  // A. Convert the Anchor date to the user timezone
  const anchorLocal = new Date(anchorDate);
  const cutoffLocal = new Date(cutoffTime);

  // B. Get the difference of the cutoff time and the local time
  const offsetHours = (cutoffLocal.getTimezoneOffset() - anchorLocal.getTimezoneOffset()) / 60;

  // C. Apply the offset from point B to the cutoff time
  const cutoffTimeLocal = addHours(cutoffLocal, offsetHours);
  return cutoffTimeLocal;
}
