import { Text, TextProps } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';
import { FunctionComponent, PropsWithChildren } from 'react';

type IdProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
  textProps?: TextProps;
};

export const Id: FunctionComponent<PropsWithChildren<IdProps>> = ({
  breakpointTokenToDesktop = 'md',
  children,
  textProps,
}) => {
  return (
    <Text
      fontSize={{ base: 'xs', [breakpointTokenToDesktop]: 'sm' }}
      color="neutral.600"
      lineHeight={{ base: '150%', [breakpointTokenToDesktop]: '120%' }}
      {...textProps}
    >
      {children}
    </Text>
  );
};
