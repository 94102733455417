import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { NUM_OF_CART_EXCEPTIONS, TOTAL_EXCEPTIONS } from '../constants';

export const getCartSummaryAmounts = (cart: Partial<Cart> | undefined) => {
  const subtotal = calculateCartSubtotal(cart);
  const discounts = calculateCartDiscounts(cart);
  const taxes = calculateCartTaxes(cart);

  return {
    subtotal,
    discounts,
    taxes,
    shipping: cart?.shippingInfo?.price?.centAmount,
    total: Number(cart?.sum?.centAmount ?? 0) + taxes,
  };
};

export const calculateCartSubtotal = (cart: Partial<Cart> | undefined) => {
  return cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.price.centAmount * b.count;
    }
  }, 0);
};

export const calculateCartDiscounts = (cart: Partial<Cart> | undefined) => {
  return cart?.lineItems?.reduce((a, b) => {
    return (
      a +
      b.count *
        b.discounts?.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0)
    );
  }, 0);
};

export const calculateCartTaxes = (cart: Partial<Cart> | undefined) => {
  return cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0) ?? 0;
};

export function formatAddress(address: Address) {
  return {
    name: `${address?.firstName} ${address?.lastName}`,
    addressLine1: `${address?.streetNumber} ${address?.streetName}`,
    addressLine2: `${address?.city}, ${address?.state}, ${address?.postalCode}`,
  };
}

export const getCartProductBySlug = ({ productSlug, cart }: { productSlug: string; cart?: Cart }) => {
  return cart?.lineItems?.find((lineItem) => lineItem.productSlug === productSlug);
};

export const addCartItemException = (sku: string) => {
  const latestValue = JSON.parse(window.localStorage.getItem(NUM_OF_CART_EXCEPTIONS)) || { total: 0 };

  if (!latestValue[sku]) {
    latestValue[sku] = 1;
    latestValue['total'] = latestValue['total'] + 1;

    window.localStorage.setItem(NUM_OF_CART_EXCEPTIONS, JSON.stringify(latestValue));
    window.localStorage.setItem(TOTAL_EXCEPTIONS, latestValue['total']);
  }
};

export const removeCartItemException = (sku: string) => {
  const latestValue = JSON.parse(window.localStorage.getItem(NUM_OF_CART_EXCEPTIONS)) || { total: 0 };

  if (latestValue[sku]) {
    latestValue[sku] = 0;
    latestValue['total'] = latestValue['total'] - 1;

    window.localStorage.setItem(NUM_OF_CART_EXCEPTIONS, JSON.stringify(latestValue));
    window.localStorage.setItem(TOTAL_EXCEPTIONS, latestValue['total']);
  }
};
