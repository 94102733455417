import { Flex, Text, VStack } from '@chakra-ui/react';

interface OrderAddressProps {
  showPhone?: boolean;
  address: {
    company?: string;
    streetName?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    phone?: string;
  };
}

const OrderAddress = (props: OrderAddressProps) => {
  const address = props?.address;
  return (
    <VStack align="unset" spacing={4}>
      <Text color="neutral.900" fontWeight="bold" lineHeight="120%">
        {address?.company}
      </Text>

      <Flex color="neutral.600" fontSize="sm" lineHeight="150%" flexDirection="column" gap={1}>
        <Text as="p" textTransform="capitalize">
          {address?.streetName?.toLowerCase()}
        </Text>
        <Text as="p" textTransform="capitalize">
          {address?.city?.toLowerCase()}, {address?.state} {address?.postalCode}
        </Text>
        {address.phone && props.showPhone && <Text as="p">{address?.phone}</Text>}
      </Flex>
    </VStack>
  );
};
export default OrderAddress;
