import { FunctionComponent } from 'react';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Icon, Text } from '@chakra-ui/react';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { palette } from 'composable/chakra/theme/foundations/colors';
import { useGetCtProducts } from 'composable/helpers/hooks/useGetCtProducts';
import { useFormat } from 'helpers/hooks/useFormat';
import { CaretDown, CaretUp } from 'phosphor-react';
import { ModificationHeader } from './modification-header';
import { ModificationLineItem } from './modification-line-item';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';

type ModificationsProps = {
  modifications?: OrderDetail['modifications'];
};

export const Modifications: FunctionComponent<ModificationsProps> = ({ modifications }) => {
  const skus = modifications?.lineItemModifications?.map((item) => item.productNumber) ?? [];
  const { isLoading, products } = useGetCtProducts(skus);
  const { formatMessage } = useFormat({ name: 'common' });

  // group modification my days
  const modificationDays = {};

  modifications?.lineItemModifications?.sort((a, b) => {
    const aDate = new Date(a?.modifiedDateTime);
    const bDate = new Date(b?.modifiedDateTime);

    return bDate.getTime() - aDate.getTime();
  });
  modifications?.headerModifications?.sort((a, b) => {
    const aDate = new Date(a?.modifiedDateTime);
    const bDate = new Date(b?.modifiedDateTime);

    return bDate.getTime() - aDate.getTime();
  });

  modifications?.lineItemModifications?.forEach((lineItem) => {
    const date = lineItem?.modifiedDateTime ? new Date(lineItem.modifiedDateTime) : null;
    const day = date && isValid(date) ? format(date, 'MM/dd/yyyy') : '';

    if (lineItem.oldQuantity !== lineItem.newQuantity) {
      if (modificationDays[day]) {
        modificationDays[day].push(lineItem);
      } else {
        modificationDays[day] = [lineItem];
      }
    }
  });

  modifications?.headerModifications?.forEach((header) => {
    const date = header?.modifiedDateTime ? new Date(header.modifiedDateTime) : null;
    const day = date && isValid(date) ? format(date, 'MM/dd/yyyy') : '';

    if (modificationDays[day]) {
      modificationDays[day].push(header);
    } else {
      modificationDays[day] = [header];
    }
  });

  if (!modifications) {
    return;
  }

  return (
    !isLoading && (
      <Accordion allowToggle w="full">
        <AccordionItem w="full" border={0}>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                p={4}
                width="full"
                backgroundColor="neutral.50"
                borderTopRadius="lg"
                borderBottomRadius={!isExpanded ? 'lg' : 'none'}
                textAlign="left"
                _hover={{ backgroundColor: 'neutral.50' }}
                display="flex"
                justifyContent="space-between"
              >
                <Text color="neutral.900" fontSize="lg" fontWeight="bold" lineHeight="120%" w="full">
                  {formatMessage({ id: 'orderDetails.modifications' })}
                </Text>
                <Icon
                  as={isExpanded ? CaretUp : CaretDown}
                  color="neutral.900"
                  w={6}
                  h={6}
                  _hover={{ color: 'primary.500' }}
                  tabIndex={0}
                  _focusVisible={{
                    outline: `2px solid ${palette.violet['500']}`,
                    borderRadius: '4px',
                    outlineOffset: '2px',
                  }}
                />
              </AccordionButton>
              <AccordionPanel
                borderBottomRadius="lg"
                background="white"
                color="neutral.900"
                width="full"
                pb={8}
                pl={0}
                pr={0}
                pt={0}
              >
                {Object.keys(modificationDays).map((day, i) => (
                  <Box w="100%" key={`order_modification_day_${i}`}>
                    <Box w="100%" pl={8} pr={8} pb={2} pt={7}>
                      <Text color="neutral.800" fontSize="1rem" fontWeight="bold" lineHeight="120%">
                        {day}
                      </Text>
                    </Box>
                    <Box width="full" borderBottom="1px solid" borderColor="neutral.100" pl={8} pr={8} pb={6}>
                      {modificationDays[day].map((modification, j) => {
                        const isLineItem = modification.hasOwnProperty('productNumber');

                        return isLineItem ? (
                          <ModificationLineItem
                            key={`order_modification_${j}`}
                            lineItem={modification}
                            products={products}
                          />
                        ) : (
                          <ModificationHeader header={modification} />
                        );
                      })}
                    </Box>
                  </Box>
                ))}
                <Box w="100%" pl={8} pr={8} pt={8}>
                  <Text fontSize="sm" color="neutral.600">
                    {formatMessage({ id: 'orderDetails.modifications.warning' })}
                  </Text>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    )
  );
};
