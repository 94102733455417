import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { CheckoutGlobalType } from './types';
import routes from 'helpers/constants/routes';

export const CHECKBOX_ID = 'checkbox-component';
const SCROLL_OFFSET = -500;

export const INITIAL_STATE: CheckoutGlobalType = {
  eligibility: { data: null, isLoading: false },
  windows: { isLoading: { [KEY_A_LA_CARTE]: false, [KEY_PICK_UP]: false }, data: null },
  checkoutData: {
    isPlacingOrder: false,
    shoppingCategoriesOpen: null,
    orderPlaced: false,
    isValidDeliveryDate: false,
    isAcknowledged: false,
    isAcknowledgedEditMode: false,
    isCheckboxError: false,
    isCheckboxErrorEditMode: false,
    marketingMessage: null,
    marketingMessageEdit: null,
    deliveryMinimums: [],
  },
};

export const scrollToElementById = () => {
  const element = document.getElementById(CHECKBOX_ID);

  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + SCROLL_OFFSET;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export const shouldCallEligibilityEndpoint = (path: string): boolean => {
  return [routes.CHECKOUT, routes.ACCOUNTS].some((route) => path.includes(route));
};
