export const SUPER_USER_ACCOUNT_CONTAINER_ID = 'super-user-account-container';
export const SUPER_USER_ACCOUNT_USERNAME_CONTAINER_ID = 'super-user-account-username-container';

export const MINIMUM_SEARCH_LENGTH = 3;

export const PAGE_SIZE_ACCOUNTS = 100;

export const INITIAL_ACCOUNT_INFORMATION = {
  accountNumber: '',
  accountName: '',
  address: '',
};

export const SEPARATOR_ACCOUNT_NAME = '@';

export const IMPERSONATOR_USER_HEADER = 'impersonated-user';

export const SUPER_USER_BANNER_ID = 'super-user-banner';

export const DELAY_ACCOUNT_SEARCH_MS = 500;
