import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  Flex,
  Box,
  useToken,
  Image as ChakraImage,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useFormat } from 'helpers/hooks/useFormat';
import { cancelCustomerOrder } from 'frontastic/actions/shamrockApi';
import { CancelOrderApiParams } from 'frontastic/actions/shamrockApi/types';
import { useOrderListPage } from 'components/orderListProvider';
interface CancelOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  setCancelOrderStatus: Dispatch<SetStateAction<string>>;
  order: OrderDetail;
  toggleRefetchOrder: () => void;
}

export const CANCEL_ORDER_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  TIMEOUT: 'TIMEOUT',
};

const LOADING_STATE_DURATION = 3000;

export const CancelOrderModal = ({
  isOpen,
  onClose,
  setCancelOrderStatus,
  order,
  toggleRefetchOrder,
}: CancelOrderModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const { mutateOrders } = useOrderListPage();

  const [inputError, setInputError] = useState(false);
  const errorColor = useToken('colors', 'red.500');
  const { accessToken } = useGlobal().useUserGlobal.state;

  const cancelOrderPayload: CancelOrderApiParams = {
    accessToken,
    salesOrderNumber: order.orderNumber,
    customerNumber: order.customerNumber,
    warehouseNumber: order.warehouseNumber,
    businessUnitName: order.businessUnitName,
    businessSegmentName: order.businessSegmentName,
  };

  const handleCancelOrder = async () => {
    if (inputValue === 'YES') {
      try {
        const res = await cancelCustomerOrder(
          {
            ...cancelOrderPayload,
          },
          toast,
          formatMessage({ id: 'app.generic.error' }),
        );

        if (res === true) {
          handleCompleteCancelOrder(CANCEL_ORDER_STATUS.SUCCESS);
        }
        if (res === false) {
          handleCompleteCancelOrder(CANCEL_ORDER_STATUS.FAILURE);
        }
        if (res === 'ERROR') {
          handleCompleteCancelOrder(CANCEL_ORDER_STATUS.TIMEOUT);
        }
      } catch (error) {
        console.error('Error function call: handleCancelOrder', error);

        if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: formatMessage({ id: 'app.generic.error' }),
            id: GENERIC_TOAST_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
        handleCompleteCancelOrder(CANCEL_ORDER_STATUS.TIMEOUT);
      }
    } else {
      setInputError(true);
    }
  };

  const handleCloseModal = () => {
    onClose();
    resetValues();
  };

  const resetValues = () => {
    setInputValue('');
    setInputError(false);
  };

  const handleCompleteCancelOrder = (status: string) => {
    setIsLoading(true);

    setTimeout(() => {
      // Reset values, close modal, and set cancelOrderStatus
      setIsLoading(false);
      onClose();
      resetValues();
      setCancelOrderStatus(status);
      if (status === CANCEL_ORDER_STATUS.SUCCESS) {
        mutateOrders();
        toggleRefetchOrder();
      }
    }, LOADING_STATE_DURATION);
  };

  useEffect(() => {
    if (inputError && inputValue === 'YES') {
      setInputError(false);
    }
  }, [inputError, inputValue]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent marginInline="10px" padding="32px">
        {/* Title */}
        <ModalHeader p={0} fontSize={{ base: '20px', sm: '28px' }} fontWeight="bold" lineHeight="120%" width={'90%'}>
          {formatMessage({ id: 'cancelOrder.title' })}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody p={0}>
          <Box mt="20px" mb="40px">
            {/* Loading Text & Spinner */}
            {isLoading ? (
              <Flex alignItems={{ base: 'start', sm: 'center' }}>
                <Spinner color="primary.500" size="sm" mr={1} />
                <Text as="p" lineHeight="150%">
                  {formatMessage({ id: 'cancelOrder.loadingText' })}
                </Text>
              </Flex>
            ) : (
              <>
                {/* Text & Input */}
                <Text as="p" fontSize={{ base: '14px', sm: '16px' }}>
                  {formatMessage({ id: 'cancelOrder.confirmText' })}
                </Text>
                <Input
                  w={{ base: '100%', md: '340px' }}
                  marginBlock="12px"
                  borderColor={inputError ? errorColor : 'black'}
                  color={inputError ? 'red.500' : 'black'}
                  boxShadow={inputError ? '0 0 0 1px red' : 'none'}
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  _focus={{ borderColor: 'black', color: 'black' }}
                />
              </>
            )}

            {/* Input Error */}
            {inputError && !isLoading && (
              <Flex>
                <ChakraImage mr={1} width="20px" height="20px" src="/icons/warning-triangle.svg" />
                <Text as="p" color="red.800" fontSize="14px">
                  {formatMessage({ id: 'cancelOrder.invalidEntry' })}
                </Text>
              </Flex>
            )}
          </Box>
        </ModalBody>

        {/* Buttons */}
        {!isLoading && (
          <ModalFooter gap={3} display="flex" p={0}>
            <Button flex={1} bgColor="#fff" onClick={handleCloseModal} size="ds-xl" variant="ds-ghost-on-light">
              {formatMessage({ id: 'cancelOrder.btn.no' })}
            </Button>
            <Button flex={1} isDisabled={inputError} onClick={handleCancelOrder} size="ds-xl" variant="ds-filled">
              {formatMessage({ id: 'cancelOrder.btn.cancelOrder' })}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
