import { useRouter } from 'next/router';
import { OG_LOCAL_STORAGE } from 'composable/helpers/constants/localStorageKeys';
import { useLocalStorage } from 'composable/helpers/hooks/useLocalStorage';
import { OG_LOCAL_STORAGE_INITIAL_STATE, SHOP_PURCHASE_HISTORY, TEN_KEY } from './constants';
import { OrderGuideLocalStorageValues } from './types';

// TODO: remove /uat with merge is done
const ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS = ['/order-guide', '/order-guide/management'];

export const useLocalStorageOrderGuide = () => {
  const { asPath } = useRouter();
  const [ogLocalStorageValues, setOGLocalStorageValues] = useLocalStorage<OrderGuideLocalStorageValues>(
    OG_LOCAL_STORAGE,
    OG_LOCAL_STORAGE_INITIAL_STATE,
  );

  const setValue = (key: keyof OrderGuideLocalStorageValues, value: any) => {
    if (ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS.find((route) => asPath.includes(route))) {
      if (key === 'selectedOrderGuide' && [SHOP_PURCHASE_HISTORY, TEN_KEY].includes(value)) {
        return;
      }
      const valueFromLocalStorage = JSON.parse(window.localStorage.getItem(OG_LOCAL_STORAGE));
      setOGLocalStorageValues({
        ...(valueFromLocalStorage ? valueFromLocalStorage : OG_LOCAL_STORAGE_INITIAL_STATE),
        [key]: value,
      });
    }
  };

  const setAllValues = (values: OrderGuideLocalStorageValues) => {
    setOGLocalStorageValues({ ...values });
  };

  return {
    ogLocalStorageValues,
    setOGLocalStorageValues,
    setValue,
    setAllValues,
  };
};
