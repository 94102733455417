import { ExtraProductData } from '@Types/shamrockApi/Product';

export const visibleProductStatus = ['active', 'obsolete'];

export const productIsActive = (extraProductData: ExtraProductData) => {
  return extraProductData && visibleProductStatus.includes(extraProductData?.companyInfo?.data?.status?.toLowerCase());
};

export const productIsSpecialOrder = (extraProductData: ExtraProductData) => {
  return extraProductData && extraProductData?.companyInfo?.data?.isSpecialOrder;
};

export const isValidProduct = (extraProductData: ExtraProductData) => {
  return productIsActive(extraProductData) && !productIsSpecialOrder(extraProductData);
};

export const productIsNotOrderable = (extraProductData: ExtraProductData) => {
  return extraProductData && extraProductData?.status.toLowerCase() === 'notorderable';
};

export const isVisibleInOG = (extraProductData: ExtraProductData) =>
  productIsActive(extraProductData) && !productIsSpecialOrder(extraProductData);

export const isVisibleInPLP = (extraProductData: ExtraProductData) => {
  return (
    extraProductData &&
    productIsActive(extraProductData) &&
    !productIsNotOrderable(extraProductData) &&
    !productIsSpecialOrder(extraProductData)
  );
};
